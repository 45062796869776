const styles = (theme) => ({
  popupIndicator: {
    color: '#000'
  },
  clearIndicator: {
    color: '#000'
  },
  inputLabel: {
    fontSize: 16,
    minHeight: 18,
    paddingRight: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    }
  },
  disabled: {
    opacity: 0.7,
    '&:before': {
      borderBottomStyle: 'solid!important'
    }
  },
  option: {
    fontSize: 16,
    lineHeight: '24px',
    padding: 8,
    '&:hover': {
      backgroundColor: 'rgb(245, 245, 245)'
    }
  },
  chipRoot: {
    height: 'unset',
    minHeight: 32,
    borderRadius: 32,
    '& span': {
      whiteSpace: 'break-spaces'
    },
    '&:focus': {
      backgroundColor: '#E0E0E0'
    },
    '&:hover': {
      backgroundColor: '#E0E0E0'
    }
  }
});

export default styles;
