import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import qs from 'qs';
import { Tab, Tabs, Typography, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TreeList from 'components/TreeList';
import { requestRegisters, requestRegisterKeys } from 'application/actions/registry';
import {
  load,
  onFilterChange,
  updateRecordValues,
  storeRecord,
  onRowsDelete,
  createRecord
} from 'services/dataTable/actions';
import ModulePage from 'components/ModulePage';
import recordEndPoint from 'application/endPoints/registryRecord';
import RegistryKeyTable from 'modules/registry/pages/Registry/components/RegistryKeyTable';
import RegistryHistoryTable from 'modules/registry/pages/Registry/components/RegistryHistoryTable';
import TopMenuLayout from 'layouts/TopMenuLayout';
import { Content } from 'layouts/LeftSidebar';
import { deepObjectFindAll } from 'helpers/deepObjectFind';
import StringElement from 'components/JsonSchema/elements/StringElement';
import SearchIcon from 'assets/img/icn-serach.svg';

const styles = (theme) => ({
  contentWrapper: {
    maxWidth: 827
  },
  selectedName: {
    fontSize: '28px',
    lineHeight: '32px',
    marginLeft: 20
  },
  selectedNameWrapper: {
    marginBottom: 20,
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  root: {
    margin: 0,
    marginBottom: 20
  },
  rootTab: {
    margin: 0,
    padding: 0,
    marginRight: 20
  },
  actionsWrapper: {
    borderTop: '2px solid #000',
    paddingTop: 15,
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center'
    }
  },
  perPageWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  perPageitem: {
    color: '#000',
    fontSize: 13,
    lineHeight: '16px',
    height: 40,
    width: 40,
    marginRight: 10
  },
  perPageitemActive: {
    border: '2px solid #000'
  },
  paginationState: {
    fontSize: 13,
    lineHeight: '16px'
  },
  paginationItems: {
    fontSize: 13,
    display: 'flex',
    marginRight: 27,
    cursor: 'pointer',
    alignItems: 'center',
    '&:last-child': {
      marginRight: 0
    },
    '& > svg': {
      width: 15,
      marginLeft: 5,
      marginRight: 5
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 10,
      marginTop: 10,
      width: '100%',
      justifyContent: 'center'
    }
  },
  hideOnXs: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  disabled: {
    opacity: 0.5,
    cursor: 'initial',
    pointerEvents: 'none'
  },
  rotateItem: {
    transform: 'rotate(180deg)'
  },
  initialCursor: {
    cursor: 'initial'
  },
  borderBottom: {
    display: 'inline-block',
    minWidth: 25,
    textAlign: 'center',
    marginRight: 5,
    borderBottom: '2px solid #000'
  },
  lastPageValueWrapper: {
    paddingLeft: 5
  },
  exportToExelIcon: {
    transform: 'rotate(90deg)',
    color: '#000'
  },
  createButton: {
    marginLeft: 50
  },
  toolbar: {
    borderBottom: 'none',
    padding: 0,
    marginBottom: 30
  },
  createNewRecordButton: {
    marginLeft: 40,
    marginRight: 0
  },
  restoreButton: {
    marginLeft: 40,
    marginRight: 0
  },
  restoreButtonPadding: {
    padding: '17px 45px'
  },
  restoreButtonTitle: {
    fontSize: 28,
    lineHeight: '36px',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  requiredFieldError: {
    color: '#f44336'
  },
  restoreButtonWrapper: {
    padding: 25
  },
  restoreButtonDialog: {
    maxWidth: 590
  },
  picker: {
    width: 220,
    marginBottom: 40
  },
  restoreButtonActions: {
    justifyContent: 'center'
  },
  exportToExelWrapper: {
    color: '#000',
    marginLeft: 20
  },
  progressBar: {
    marginTop: 15
  },
  tableCell: {
    paddingLeft: '0!important'
  },
  tableHeaderRow: {
    '& > th': {
      paddingLeft: '0!important'
    }
  },
  searchIcon: {
    marginBottom: 5
  },
  searchInput: {
    '& label': {
      transform: 'translate(30px, 21px) scale(1)'
    }
  },
  pageInput: {
    marginRight: 5,
    '& input': {
      textAlign: 'center',
      paddingTop: 0
    }
  },
  status: {
    color: '#000000',
    padding: 3
  },
  Rollbacked: {
    backgroundColor: '#19BE6F'
  },
  Failed: {
    backgroundColor: '#FA594F'
  },
  Rollbacking: {
    backgroundColor: '#FFD600'
  }
});

class RegistryPage extends ModulePage {
  state = { search: '', selected: null, newRecord: null, activeTab: 0 };

  componentGetTitle = () => {
    const { t } = this.props;
    return t('title');
  };

  handleSelectKey = (selected) => {
    const { keys, history } = this.props;
    const selectedKey = selected && keys.find(({ id }) => id === selected.id);

    this.setState({ activeTab: 0 });

    if (!selected) {
      this.setState({ selected });
      return;
    }

    this.setState({
      selected: {
        ...selectedKey,
        name: selectedKey && selectedKey.name
      }
    });

    this.scrollToTop();

    history.push(this.getFiltersFromUrl(selected));
  };

  getFiltersFromUrl = (selected) => {
    const { history } = this.props;
    const { search } = history.location;
    const { rowsPerPage, page, recordId, redirectUrl } = qs.parse(search.replace('?', ''));
    const searchString = `/registry?keyId=${selected.id}${page ? `&page=${page}` : ''}${
      rowsPerPage ? `&rowsPerPage=${rowsPerPage}` : ''
    }${recordId ? `&recordId=${recordId}` : ''}${redirectUrl ? `&redirectUrl=${redirectUrl}` : ''}`;
    return searchString;
  };

  deleteSelected = () => {
    const { history } = this.props;
    this.setState({ selected: null });
    history.push('/registry');
  };

  handleSearch = (search) => this.setState({ search });

  relevancySort =
    (search = '') =>
    (a, b) => {
      let aIndexOf = (a.name || '').toLowerCase().indexOf(search.toLowerCase());
      let bIndexOf = (b.name || '').toLowerCase().indexOf(search.toLowerCase());

      if (aIndexOf < 0 && bIndexOf < 0) {
        return (a.name || '').localeCompare(b.name || '');
      }

      if (aIndexOf < 0) {
        aIndexOf = 100;
      }

      if (bIndexOf < 0) {
        bIndexOf = 100;
      }

      return aIndexOf > bIndexOf ? 1 : -1;
    };

  filterItems = () => {
    const { registers, keys } = this.props;
    const { search } = this.state;

    let items = null;

    if (registers && keys) {
      items = (registers || [])
        .map(({ id, description }) => ({
          id,
          name: description,
          items: (keys || [])
            .filter((key) => key.registerId === id)
            .map((key) => ({
              id: key.id,
              name: key.description || key.name
            }))
        }))
        .map((parent) => (parent.items.length === 1 ? parent.items.shift() : parent));
    }

    if (!search || !search.length) return items;

    const compared = (name) => (name || '').toLowerCase().indexOf(search.toLowerCase()) !== -1;

    const filteredList = deepObjectFindAll(
      items || {},
      (item) => item && !item.items && item.name && compared(item.name)
    )
      .sort(this.relevancySort(search))
      .slice(0, 100);

    return filteredList;
  };

  scrollToTop = () => {
    const topPagePart = document.querySelector('header');
    topPagePart && topPagePart.scrollIntoView();
  };

  setSavedFilters = () => {
    const { history } = this.props;
    const { selected } = this.state;

    const { search } = history.location;
    const displayList = this.filterItems();

    if (!displayList || !search.length || selected) return;

    const { keyId } = qs.parse(search.replace('?', ''));

    let selectedItem = null;

    displayList.forEach((firstlevel) => {
      const { id, items } = firstlevel;
      if (items) {
        items.forEach((item) => {
          const { id: itemId } = item;
          if (Number(itemId) === Number(keyId)) selectedItem = item;
        });
      }
      if (Number(id) === Number(keyId)) selectedItem = firstlevel;
    });

    if (!selectedItem) return;

    this.handleSelectKey(selectedItem);
  };

  componentDidUpdate = () => this.setSavedFilters();

  componentDidMount = () => {
    super.componentDidMount();

    const { registers, keys, actions } = this.props;

    if (!registers) actions.requestRegisters();

    if (!keys) actions.requestRegisterKeys();
  };

  render = () => {
    const { t, location, title, loading, classes, history } = this.props;
    const { selected, activeTab, search } = this.state;

    const displayList = this.filterItems();

    return (
      <TopMenuLayout disableScrolls={true} location={location} title={title} loading={loading}>
        <Content>
          {!selected ? (
            <div className={classes.contentWrapper}>
              {displayList ? (
                <StringElement
                  description={t('SearchRegister')}
                  value={search}
                  required={true}
                  margin="dense"
                  onChange={this.handleSearch}
                  className={!(search && search.length) ? classes.searchInput : null}
                  startAdornment={
                    <>
                      <img src={SearchIcon} alt={'search icon'} className={classes.searchIcon} />
                    </>
                  }
                />
              ) : null}
              <TreeList
                items={displayList}
                onChange={this.handleSelectKey}
                listWithAddIcon={true}
              />
            </div>
          ) : (
            <>
              <div className={classes.selectedNameWrapper}>
                <IconButton onClick={this.deleteSelected} size="large">
                  <ArrowBackIcon />
                </IconButton>
                <Typography className={classes.selectedName}>
                  {selected.name || selected.description}
                </Typography>
              </div>

              {selected.access && selected.access.allowHistory ? (
                <Tabs
                  value={activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(event, tab) => this.setState({ activeTab: tab })}
                  classes={{ root: classes.root }}
                >
                  <Tab label={t('Records')} classes={{ root: classes.rootTab }} />
                  <Tab label={t('History')} classes={{ root: classes.rootTab }} />
                </Tabs>
              ) : null}

              {activeTab === 0 ? (
                <RegistryKeyTable
                  disableElevation={true}
                  selectedKey={selected}
                  history={history}
                  classes={classes}
                />
              ) : null}

              {activeTab === 1 ? (
                <RegistryHistoryTable
                  disableElevation={true}
                  selectedKey={selected}
                  classes={classes}
                />
              ) : null}
            </>
          )}
        </Content>
      </TopMenuLayout>
    );
  };
}

const mapStateToProps = ({
  registry: { registers, keys },
  registryRecordList: { loading, data: records }
}) => ({
  registers,
  keys,
  loading,
  records
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    load: bindActionCreators(load(recordEndPoint), dispatch),
    onFilterChange: bindActionCreators(onFilterChange(recordEndPoint), dispatch),
    requestRegisters: bindActionCreators(requestRegisters, dispatch),
    requestRegisterKeys: bindActionCreators(requestRegisterKeys, dispatch),
    storeRecord: bindActionCreators(storeRecord(recordEndPoint), dispatch),
    updateRecordValues: bindActionCreators(updateRecordValues(recordEndPoint), dispatch),
    onRowsDelete: bindActionCreators(onRowsDelete(recordEndPoint), dispatch),
    createRecord: bindActionCreators(createRecord(recordEndPoint), dispatch)
  }
});

const translated = translate('RegistryPage')(RegistryPage);
const styled = withStyles(styles)(translated);
export default connect(mapStateToProps, mapDispatchToProps)(styled);
