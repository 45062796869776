export default () => {
  const links = [
    {
      title: 'Про нас',
      url: '/',
      children: [
        {
          items: [
            {
              title: 'Керівництво',
              url: '/persons'
            },
            {
              title: 'Про нас',
              url: '/pro-ministerstvo'
            },
          ]
        }
      ]
    },
    
    {
      title: 'Ветеранам',
      url: '/veteranam'
    },
    {
      title: 'Громадськості',
      url: '/gromadskosti'
    },
    {
      title: 'Пресцентр',
      url: '/prescenter',
      children: [
        {
          items: [
            {
              title: 'Новини',
              url: '/category/86-novini'
            },
            {
              title: 'Медіагалерея',
              url: '/mediagalereya'
            }
          ]
        }
      ]
    },
    {
      title: 'ЄДРВВ',
      url: '/edrvw',
      children: [
        {
          items: [
            {
              title: 'ЄДРВВ',
              url: '/edrvw'
            },
            {
              title: 'е-Ветеран',
              url: 'https://eveteran.gov.ua/',
              external: true
            }
          ]
        }
      ]
    },
    {
      title: 'Підпорядковані установи',
      url: '/pidporyadkovani-ustanovi',
      children: [
        {
          items: [
            {
              title: 'Український ветеранський фонд',
              url: '/category/141-veteranskiy-fond'
            },
            {
              title: 'Центри соціально-психологічної реабілітації',
              url: '/category/140-tsentry-sotsialno-psykholohichnoi-reabilitatsii'
            },
            {
              title: 'Державна установа "Національне військове меморіальне кладовище"',
              url: '/category/227-derzhavna-ustanova-natsionalne-viyskove-memorialne-kladovische'
            }
          ]
        }
      ]
    },
    {
      title: 'Контакти',
      url: '/contacts',
      children: [
        {
          items: [
            {
              title: 'Контакти',
              url: '/kontakti'
            },
            {
              title: 'Форма електронного звернення громадян',
              url: '/forma-elektronnogo-zvernennya-gromadyan-1'
            },
            {
              title: 'Запит на публічну інформацію',
              url: '/zapit-na-publichnu-informatsiu'
            }
          ]
        }
      ]
    }
  ];

  return links;
};
