/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import QRCode from 'qrcode.react';
import MobileDetect from 'mobile-detect';
import { useTranslate } from 'react-translate';
import classNames from 'classnames';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  Card,
  CardContent,
  Button,
  Typography,
  Toolbar,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
import DiiSignFilesLimit from 'components/EDSForm/components/DiiSignFilesLimit';
import renderHTML from 'helpers/renderHTML';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = !!md.mobile();

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  icon: {
    fontSize: 120,
    color: 'green'
  },
  error: {
    fontSize: 120,
    color: 'red'
  },
  container: {
    border: '#aaa 1px dashed',
    borderRadius: 5,
    height: 160,
    width: 160,
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    marginTop: 20,
    marginBottom: 5
  },
  retry: {
    marginTop: 10
  },
  progress: {
    margin: 'auto'
  },
  processing: {
    marginRight: 4
  },
  processingText: {
    marginTop: 10
  },
  dialogContentWrappers: {
    textAlign: 'center',
    padding: '22px 80px'
  },
  closeDialogButton: {
    margin: 'auto'
  },
  isMobileRoot: {
    flexDirection: 'column-reverse'
  },
  isMobileContainer: {
    padding: 0,
    width: '100%',
    height: 'unset',
    border: 'none',
    display: 'block'
  },
  infoWarning: {
    backgroundColor: '#fff7e3',
    padding: 5,
    marginBottom: 10,
    marginTop: 10
  },
  inActiveCode: {
    opacity: 0.3,
    pointerEvents: 'none'
  }
}));

const DiiaSignFormLayout = ({
  timer,
  reinitTimer,
  error,
  retry,
  loading,
  onClose,
  deepLink,
  finished,
  preparing,
  processing,
  signingError,
  showErrorDialog,
  setShowErroDialog,
  updateData,
  task,
  template
}) => {
  const t = useTranslate('DiiaSignForm');
  const classes = useStyles();

  const timerValue = (() => {
    const formatted = moment.utc((reinitTimer - timer) * 1000).format('mm:ss');
    return formatted;
  })();

  const timerReached = timerValue === '00:00';

  return (
    <>
      <DiiSignFilesLimit task={task} template={template} />

      <Card
        className={classNames({
          [classes.root]: true,
          [classes.isMobileRoot]: isMobile
        })}
        elevation={0}
      >
        <CardContent
          className={classNames({
            [classes.container]: true,
            [classes.isMobileContainer]: isMobile
          })}
        >
          {finished ? (
            <CheckRoundedIcon className={classes.icon} />
          ) : (
            <>
              {error ? (
                <ErrorOutlineIcon className={classes.error} />
              ) : (
                <>
                  {loading || preparing ? <CircularProgress className={classes.progress} /> : null}
                  {deepLink && !preparing ? (
                    <>
                      {isMobile ? (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setTimeout(() => {
                              window.location.href = deepLink;
                            }, 300);
                          }}
                          className={classNames({
                            [classes.inActiveCode]: timerReached
                          })}
                        >
                          {t('OpenQrButton')}
                        </Button>
                      ) : (
                        <a
                          href={deepLink}
                          aria-label="QR"
                          className={classNames({
                            [classes.inActiveCode]: timerReached
                          })}
                        >
                          <QRCode value={deepLink} />
                        </a>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </>
          )}
        </CardContent>

        <CardContent>
          <Typography gutterBottom={true} variant="h6" component="h6">
            {t('DiiaSignFormTitle')}
          </Typography>

          {timerReached ? null : (
            <Typography variant="body2" color="textSecondary" component="p">
              {preparing
                ? t('DiiaSignFormLoading')
                : finished
                ? t('DiiaSignFormSuccess')
                : error
                ? t('DiiaSignFormError')
                : loading
                ? t('PreparingDeeplink')
                : isMobile
                ? t('DiiaSignFormDescriptionMobile')
                : t('DiiaSignFormDescription')}
            </Typography>
          )}

          {error ? (
            <Button onClick={retry} autoFocus={true} variant="outlined" className={classes.retry}>
              {t('Retry')}
            </Button>
          ) : null}

          {processing ? (
            <Typography className={classes.processingText}>
              <CircularProgress size={12} className={classes.processing} />
              {t('Processing')}
            </Typography>
          ) : null}

          {deepLink && !processing && !loading && !error && !preparing ? (
            <>
              {timerReached ? (
                <>
                  <Typography className={classes.infoWarning}>{t('QrTimerRiched')}</Typography>
                  <Button size="small" onClick={updateData} startIcon={<AutorenewIcon />}>
                    {t('RenewQR')}
                  </Button>
                </>
              ) : !isMobile ? (
                <Typography>{t('QrTimer', { timerValue })}</Typography>
              ) : null}
            </>
          ) : null}
        </CardContent>
      </Card>

      <Divider className={classes.divider} />

      <Toolbar disableGutters={true} alig={true}>
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onClose} autoFocus={true}>
          {t('CloseDialog')}
        </Button>
      </Toolbar>

      <Dialog
        open={showErrorDialog}
        onClose={() => setShowErroDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogTitle className={classes.dialogContentWrappers}>{t('SigningDataError')}</DialogTitle>
        <DialogContent className={classes.dialogContentWrappers}>
          <DialogContentText color="primary">{renderHTML(signingError)}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogContentWrappers}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowErroDialog(false)}
            className={classes.closeDialogButton}
            autoFocus={true}
          >
            {t('CloseDialog2')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DiiaSignFormLayout;
