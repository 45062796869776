import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-premium';
import { history } from 'store';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ReactComponent as RefreshIcon } from './assets/refresh.svg';
import { ReactComponent as ColumnsIcon } from './assets/columns.svg';
import { ReactComponent as RowsIcon } from './assets/rows.svg';
import { ReactComponent as ExportIcon } from './assets/export.svg';

const Toolbar = ({
  data,
  classes,
  t,
  search,
  handleSearch,
  actions,
  controls,
  CustomToolbar,
  filters,
  rowsSelected,
  filterHandlers
}) => {
  const handleClearSearch = React.useCallback(() => {
    return handleSearch({ target: { value: '' } });
  }, [handleSearch]);

  const {
    toolbar = true,
    refresh = true,
    customizeColumns = true,
    search: searchControl = true,
    export: exportControl = true
  } = React.useMemo(() => controls, [controls]);

  const CustomToolbarMemo = React.useCallback(() => {
    return (
      <CustomToolbar
        filters={filters}
        actions={actions}
        rowsSelected={rowsSelected}
        data={data}
        filterHandlers={filterHandlers}
        location={history?.location?.pathname}
      />
    );
  }, [filters, actions, rowsSelected, data, filterHandlers]);

  if (!toolbar) return null;

  return (
    <GridToolbarContainer className={classes.toolbar}>
      <div className={classes.toolbarActions}>
        {refresh ? (
          <Button onClick={actions.load} startIcon={<RefreshIcon />} className={classes.buttonSm}>
            {t('Refresh')}
          </Button>
        ) : null}
        {customizeColumns ? (
          <>
            <GridToolbarColumnsButton startIcon={<ColumnsIcon />} className={classes.buttonSm} />
            <GridToolbarDensitySelector startIcon={<RowsIcon />} className={classes.buttonSm} />
          </>
        ) : null}
        {exportControl ? (
          <GridToolbarExport
            startIcon={<ExportIcon />}
            printOptions={{ disableToolbarButton: true }}
            className={classes.buttonSm}
          />
        ) : null}
        {CustomToolbar ? <CustomToolbarMemo /> : null}
      </div>
      {searchControl ? (
        <GridToolbarQuickFilter
          hidden={true}
          autoFocus={!!search.length}
          className={classes.toolbarQuickFilter}
          variant="outlined"
          placeholder={t('Search')}
          aria-label={t('Search')}
          value={search}
          onChange={handleSearch}
          InputProps={{
            endAdornment: search.length ? (
              <IconButton onClick={handleClearSearch} size="small">
                <ClearOutlinedIcon />
              </IconButton>
            ) : null
          }}
        />
      ) : null}
    </GridToolbarContainer>
  );
};

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  search: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  controls: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  CustomToolbar: PropTypes.func,
  filters: PropTypes.array.isRequired,
  rowsSelected: PropTypes.array
};

Toolbar.defaultProps = {
  rowsSelected: [],
  CustomToolbar: null,
  search: ''
};

export default Toolbar;
