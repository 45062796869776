/* eslint-disable react/prop-types */
import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { logout } from 'actions/auth';
import { Typography, Popover, MenuList, MenuItem, Paper, ClickAwayListener } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

const styles = (theme) => ({
  root: {
    padding: 4,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      marginTop: 20
    }
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  item: {
    color: 'inherit',
    display: 'inline',
    fontSize: 13,
    lineHeight: '16px'
  },
  searchButton: {
    color: 'inherit'
  },
  menuLink: {
    textDecoration: 'none'
  },
  dropDownIcon: {
    display: 'inline-block',
    position: 'relative',
    top: 2,
    '& > svg': {
      fontSize: 13
    }
  },
  menuListRoot: {
    padding: 0
  },
  menuItemGutters: {
    fontSize: 13,
    paddingTop: 5,
    paddingBottom: 5
  }
});

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  handleMenuOpen = ({ currentTarget }) => this.setState({ anchorEl: currentTarget });

  handleMenuClose = () => this.setState({ anchorEl: null });

  handleLogout = () => {
    const { actions } = this.props;
    this.handleMenuClose();
    actions.logout(true);
  };

  render() {
    const { anchorEl } = this.state;
    const { t, classes, firstName, lastName, companyName, userData } = this.props;

    return (
      <div className={classes.root}>
        <Link to="#" className={classes.link} onClick={this.handleMenuOpen}>
          <Typography variant="body1" className={classes.item}>
            {userData ? (
              <>
                {userData?.givenName} {(userData?.surname || '').slice(0, 1) + '.'}
              </>
            ) : (
              [
                capitalizeFirstLetter(firstName) || companyName,
                lastName && (lastName || '').slice(0, 1) + '.'
              ]
                .filter(Boolean)
                .join(' ')
            )}
          </Typography>
          <div className={classes.dropDownIcon}>
            {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
        </Link>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <ClickAwayListener onClickAway={this.handleMenuClose}>
            <Paper>
              <MenuList classes={{ root: classes.menuListRoot }}>
                <Link to="/profile" className={classes.menuLink}>
                  <MenuItem
                    onClick={this.handleMenuClose}
                    classes={{ gutters: classes.menuItemGutters }}
                    id="profile-btn"
                  >
                    {t('MyProfileTitle')}
                  </MenuItem>
                </Link>
                <MenuItem
                  onClick={this.handleLogout}
                  classes={{ gutters: classes.menuItemGutters }}
                  id="logout-btn"
                >
                  {t('Logout')}
                </MenuItem>
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: {
    info: { firstName, lastName, companyName }
  }
}) => ({
  firstName,
  lastName,
  companyName
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    logout: bindActionCreators(logout, dispatch)
  }
});

const styled = withStyles(styles)(UserMenu);
const translated = translate('Layout')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
