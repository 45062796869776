/* eslint-disable react/prop-types */
import React from 'react';
import { translate } from 'react-translate';
import { history } from 'store';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ChipTabs from 'components/ChipTabs';
import checkAccess from 'helpers/checkAccess';

import config from 'config';
import { connect } from 'react-redux';

const links = (t) => [
  {
    title: t('MyWorkflow'),
    uiFilter: 'workflows.not-draft',
    link: '/workflow',
    access: {
      isUnitedUser: false,
      unitHasAccessTo: 'navigation.workflow.MyWorkflow'
    }
  },
  {
    title: t('Drafts'),
    uiFilter: 'workflows.draft',
    link: '/workflow/drafts',
    access: {
      isUnitedUser: false,
      unitHasAccessTo: 'navigation.workflow.Drafts'
    }
  },
  {
    title: t('Inbox'),
    link: '/workflow/inbox'
  }
];

const styles = (theme) => ({
  chipsWrapper: {
    marginTop: 34,
    marginBottom: 52,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginBottom: 10
    }
  }
});

class WorkflowNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkAccess = ({ access }) => {
    const { userUnits, userInfo } = this.props;
    return !access || checkAccess(access, userInfo, userUnits);
  };

  uiItemFilter = ({ uiFilter }) => {
    if (!config.useUIFilters || !uiFilter) {
      return true;
    }

    const { uiFilters } = this.props;
    return uiFilters.find(({ filter }) => filter === uiFilter);
  };

  uiItemFilterMap = (menuItem) => {
    if (!config.useUIFilters || !menuItem.uiFilter) {
      return menuItem;
    }

    const { uiFilters } = this.props;

    return {
      ...menuItem,
      title: uiFilters.find(({ filter }) => filter === menuItem.uiFilter).name
    };
  };

  getActiveIndex = () => {
    const {
      t,
      location: { pathname }
    } = this.props;
    return links(t)
      .filter(this.checkAccess)
      .findIndex(({ link }) => pathname === link);
  };

  handleChange = (event, itemIndex) => {
    const { t } = this.props;
    const item = links(t).filter(this.checkAccess)[itemIndex];
    history.push(item && item.link);
  };

  render() {
    const { t, classes } = this.props;
    const activeIndex = this.getActiveIndex();

    return (
      <div className={classes.chipsWrapper}>
        <ChipTabs
          activeIndex={activeIndex}
          onChange={this.handleChange}
          tabs={links(t)
            .filter(this.checkAccess)
            .filter(this.uiItemFilter)
            .map(this.uiItemFilterMap)}
        />
      </div>
    );
  }
}

WorkflowNavigation.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

const mapState = ({ auth: { userUnits, info }, app: { uiFilters } }) => ({
  userUnits,
  userInfo: info,
  uiFilters
});

const styled = withStyles(styles)(WorkflowNavigation);

const translated = translate('Navigator')(styled);

export default connect(mapState)(translated);
