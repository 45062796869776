/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { makeStyles } from '@mui/styles';
import ProgressLine from 'components/Preloader/ProgressLine';
import theme from 'theme';
import MobileDetect from 'mobile-detect';

const useStyles = makeStyles(() => ({
  refWrapper: {
    overflow: 'hidden'
  }
}));

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = !!md.mobile();

const LISTBOX_PADDING = 6;
const FULL_WIDTH = 640;
const LINE_HEIGHT = isMobile ? 13 : 20;
const UPPERCASE_INFLUENCE = 16;
const CRITIC_SIZE = 3000;

const renderRow = (props) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  });
};

const useResetCache = (data) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const OuterElementContext = React.createContext({});

const getTextWidth = (text, font) => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  context.letterSpacing = '0.5px';
  const metrics = context.measureText(text);
  return metrics.width;
};

const getFont = () => {
  try {
    return theme?.typography?.fontFamily;
  } catch {
    return 'Roboto';
  }
};

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, hasNextPage, decrementPage, isLoading, containerWidth, ...other } = props;

  const classes = useStyles();

  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;

  /**
   * style: lineHeight: '24px', padding: 8px;
   * 24+8*2 = 40
   */

  const itemSize = 40;

  const getChildSize = (child) => {
    const itemWidth = (containerWidth || FULL_WIDTH) - UPPERCASE_INFLUENCE * 2 - 10;
    const textWidth = getTextWidth(child.props.children, `400 16px ${getFont()}`);
    const checkTextWidth = textWidth > CRITIC_SIZE ? textWidth + itemWidth * 2 : textWidth;
    const rowHeight = Number(Math.ceil(Number(checkTextWidth) / itemWidth));
    const ROW_COUNT_INFLUENCE = rowHeight >= 5 ? LINE_HEIGHT + 3 : LINE_HEIGHT
    return Number(itemSize) + Number(rowHeight >= 2 ? rowHeight * ROW_COUNT_INFLUENCE : rowHeight);
  };

  const getHeight = () => {
    if (itemCount > 8) return 8 * itemSize;
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  const loadMoreItems = () => {};

  const isItemLoaded = (index) => !hasNextPage || index < itemCount;

  return (
    <div ref={ref} className={classes.refWrapper}>
      <OuterElementContext.Provider value={other}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <VariableSizeList
              onItemsRendered={onItemsRendered}
              itemData={itemData}
              height={getHeight() + 2 * LISTBOX_PADDING}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType="div"
              itemSize={(i) => getChildSize(itemData[i])}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          )}
        </InfiniteLoader>
        <ProgressLine loading={isLoading} style={{ position: 'relative', top: -2 }} />
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default ListboxComponent;

export { getTextWidth, getFont };
