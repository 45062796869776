import React from 'react';
import AssessmentIcon from '@mui/icons-material/Assessment';

import ReportListPage from 'application/modules/reports/pages/ReportList';

import config from 'config';

const { reports: { enabled } = {} } = config;

export default enabled
  ? {
      routes: [
        {
          path: '/reports',
          title: 'Reports',
          component: ReportListPage
        }
      ],
      navigation: [
        {
          id: 'Reports',
          icon: <AssessmentIcon />,
          path: '/reports'
        }
      ]
    }
  : {};
