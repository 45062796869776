import React from 'react';
import { translate } from 'react-translate';

import DataList from 'components/DataList';
import EmptyPage from 'components/EmptyPage';

import endPoint from 'application/endPoints/message';

import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import ListTemplate from './ListTemplate';

const MessageTable = (props) => {
  const { t, data, filters, loading } = props;

  if (!loading && data && data.length === 0 && !filters.filtered && !filters.name) {
    return (
      <EmptyPage
        title={t('EmptyListTitle')}
        description={t('EmptyListDescription')}
        id="msg-empty-page"
        externalReader={true}
      />
    );
  }

  return (
    <DataList
      {...dataTableAdapter(props)}
      ItemTemplate={ListTemplate}
      controls={{
        pagination: true
      }}
    />
  );
};

const translated = translate('MessageListPage')(MessageTable);
export default dataTableConnect(endPoint)(translated);
