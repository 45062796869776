import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText, Collapse } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import TreeList from 'components/TreeList';

export const styles = (theme) => ({
  nested: {
    paddingLeft: 32,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  registerNested: {
    marginLeft: 26,
    borderLeft: '2px solid #C4C4C4',
    '&>nav': {
      paddingBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16
    }
  },
  item: {
    border: '#efefef 1px solid',
    borderRadius: 5,
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      marginBottom: 10
    }
  },
  mobileIcon: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  navlink: {
    textDecoration: 'none',
    color: '#000'
  },
  error: {
    border: '#f44336 1px solid'
  },
  registerItem: {
    border: 'none',
    paddingLeft: 24,
    paddingRight: 24,
    '&:focus': {
      backgroundColor: '#fff'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  itemlabel: {
    padding: '0!important',
    [theme.breakpoints.down('sm')]: {
      margin: '0!important',
      '&>span': {
        fontSize: 13
      }
    }
  },
  listButton: {
    backgroundColor: 'red'
  },
  withPlusItem: {
    borderTop: '2px solid #000',
    padding: 15,
    paddingLeft: 0,
    paddingRight: 0,
    transition: '.2s ease-in-out',
    '&:hover': {
      paddingLeft: 20,
      paddingRight: 20
    },
    '&:last-child': {
      borderBottom: '2px solid #000'
    }
  },
  withPlusItemRoot: {
    padding: 0
  },
  nestedWithAddIcon: {
    '& nav': {
      paddingBottom: 0,
      paddingTop: 0,
      '& > div': {
        paddingLeft: 56,
        '&:hover': {
          paddingLeft: 76,
          paddingRight: 76
        },
        '&:last-child': {
          borderBottom: 'none'
        }
      }
    }
  }
});

class TreeListItem extends React.Component {
  state = { open: false };

  handleClick = () => {
    const { item, onClick, onMenuOpen } = this.props;
    const { open } = this.state;

    if (item.items) {
      return this.setState({ open: !open }, onMenuOpen);
    }

    return onClick && onClick(item);
  };

  renderItem() {
    const { classes, item, link, id, listWithAddIcon } = this.props;
    const { open } = this.state;

    const { description, name, items, stringified } = item;

    if (link) {
      return (
        <NavLink id={id + '-item'} to={link} className={classes.navlink}>
          <ListItem className={listWithAddIcon ? classes.withPlusItem : classes.item} button={true}>
            <ListItemText inset={true} primary={description || name || stringified} />
          </ListItem>
        </NavLink>
      );
    }

    const openIcon = listWithAddIcon ? <AddIcon /> : <ExpandMore />;
    const closeicon = listWithAddIcon ? <RemoveIcon /> : <ExpandLess />;

    return (
      <ListItem
        id={id + '-item'}
        className={listWithAddIcon ? classes.withPlusItem : classes.item}
        button={true}
        onClick={this.handleClick}
      >
        <ListItemText
          inset={true}
          primary={description || name || stringified}
          classes={{
            root: listWithAddIcon ? classes.withPlusItemRoot : null
          }}
        />
        {items ? (open ? closeicon : openIcon) : null}
      </ListItem>
    );
  }

  renderRegisterItem = () => {
    const { classes, item, link, id } = this.props;

    const { description, name, hiddenOption, stringified } = item;

    if (link) {
      return (
        <NavLink id={id + '-item'} to={link} className={classes.navlink}>
          <ListItem
            className={classes.registerItem}
            button={true}
            classes={{ button: classes.listButton }}
          >
            <ListItemText
              classes={{ root: classes.itemlabel }}
              inset={true}
              primary={description || name || stringified}
            />
          </ListItem>
        </NavLink>
      );
    }

    if (hiddenOption) return null;

    return (
      <ListItem
        id={id + '-item'}
        className={classes.registerItem}
        button={true}
        onClick={this.handleClick}
      >
        <ListItemText
          classes={{
            root: classes.itemlabel
          }}
          inset={true}
          primary={description || name || stringified}
        />
      </ListItem>
    );
  };

  render = () => {
    const { classes, item, onClick, onMenuOpen, createLink, id, registerSelect, listWithAddIcon } =
      this.props;
    const { open } = this.state;

    const { items } = item;

    const wrapperClassName = registerSelect
      ? classes.registerNested
      : listWithAddIcon
      ? classes.nestedWithAddIcon
      : classes.nested;

    return (
      <>
        {!registerSelect ? this.renderItem() : this.renderRegisterItem()}
        {items ? (
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <div className={wrapperClassName}>
              <TreeList
                id={id}
                items={items}
                onChange={onClick}
                onMenuOpen={onMenuOpen}
                createLink={createLink}
                registerSelect={registerSelect}
                listWithAddIcon={listWithAddIcon}
              />
            </div>
          </Collapse>
        ) : null}
      </>
    );
  };
}

export default withStyles(styles)(TreeListItem);
