import * as api from 'services/api';
import * as Sentry from '@sentry/browser';

const GET_USER_MODULES = 'GET_USER_MODULES';

const getUserModules = () => (dispatch) =>
  api.get('modules', GET_USER_MODULES, dispatch).catch((error) => {
    Sentry.captureException(error);
  });

export default getUserModules;
