const GET_USER_MODULES_SUCCESS = 'GET_USER_MODULES_SUCCESS';

const initialState = {
  externalReader: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_MODULES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { externalReader } = action.payload;
      return { ...state, externalReader };
    default:
      return state;
  }
};
