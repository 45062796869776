import React from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = {
  dawer: {
    height: '100%'
  },
  drawerPaper: {
    width: 400,
    backgroundColor: '#E7EEF3',
    position: 'inherit',
    padding: '250px 40px 0',
    borderRight: '0'
  },
  block: {
    marginTop: 5,
    marginBottom: 5
  },
  subTitle: {
    marginTop: 5,
    marginBottom: 50
  },
  button: {
    '& > span:first-child': {
      padding: '16px 52px',
      background: '#E7EEF3',
      borderRadius: 40
    }
  }
};

const RightSidebar = ({ classes, details }) => (
  <Drawer
    variant="permanent"
    className={classes.dawer}
    classes={{
      paper: classes.drawerPaper
    }}
    anchor="left"
  >
    {details}
  </Drawer>
);

RightSidebar.propTypes = {
  details: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

RightSidebar.defaultProps = {
  details: null
};

export default withStyles(styles)(RightSidebar);
