import React from 'react';
import PropTypes from 'prop-types';

import RightSidebarLayout from 'layouts/RightSidebarLayout';
import InboxFileDetails from 'modules/inbox/pages/InboxFiles/components/InboxFileDetails';

const InboxFileLayout = ({ location, title, loading, inboxFile }) => (
  <RightSidebarLayout location={location} title={title} loading={loading} backLink="/inbox">
    <InboxFileDetails {...inboxFile} />
  </RightSidebarLayout>
);

InboxFileLayout.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  inboxFile: PropTypes.object
};

InboxFileLayout.defaultProps = {
  loading: false,
  inboxFile: null
};

export default InboxFileLayout;
