import userProfileModule from 'modules/userProfile';
import modules from 'application/modules';

const excludeRoutes = [];

const moduleFilter = ({ routes, ...module }) => ({
  ...module,
  routes: routes && routes.filter(({ path }) => !excludeRoutes.includes(path))
});

export default [
  userProfileModule,
  ...modules.map(moduleFilter)
];
