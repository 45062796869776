/* eslint-disable react/prop-types */
import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandedMenuItem from './ExpandedMenuItem';
import links from './variables/topMenu';

const styles = (theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  item: {
    color: 'inherit',
    display: 'inline',
    margin: '0 16px',
    fontSize: 13,
    lineHeight: '16px',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      marginBottom: 20,
      display: 'block'
    }
  }
});

const renderItem =
  ({ classes, openServiceMenu, setOpenServiceMenu, closeButton }) =>
  ({ title, url, children, items }, index) => {
    const octoberItems = items && items.length ? [].concat(items) : null;

    if (children || (octoberItems && octoberItems.length)) {
      return (
        <ExpandedMenuItem
          key={index}
          title={title}
          open={openServiceMenu}
          setOpen={setOpenServiceMenu}
          closeButton={closeButton}
          children={children || octoberItems}
        />
      );
    }

    return (
      <a key={index} href={url} className={classes.link} rel="noopener noreferrer">
        <Typography variant="body1" className={classes.item}>
          {title}
        </Typography>
      </a>
    );
  };

const TopMenu = ({
  t,
  classes,
  openServiceMenu,
  setOpenServiceMenu,
  closeButton,
  items,
  isResidend
}) => {
  const octoberHeader = Array.isArray(items) && items.filter(({ isHidden }) => !isHidden);

  return (octoberHeader || links(t, isResidend)).map(
    renderItem({ classes, openServiceMenu, setOpenServiceMenu, closeButton })
  );
};

const mapStateToProps = ({ octoberHeader: { items } }) => ({ items });

const styled = withStyles(styles)(TopMenu);
const translated = translate('Layout')(styled);

export default connect(mapStateToProps)(translated);
