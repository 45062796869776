import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { bindActionCreators } from 'redux';
import {
  Accordion,
  AccordionDetails,
  Toolbar,
  Tooltip,
  Typography,
  IconButton
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { humanDateTimeFormat } from 'helpers/humanDateFormat';
import { getPDFDocument, downloadDocumentAttach } from 'application/actions/task';
import { downloadFile } from 'application/actions/files';
import FileNameColumn from 'components/FileDataTable/components/FileNameColumn';
import FileViewerDialog from 'components/FileViewerDialog';
import SaveAltIcon from 'assets/img/ic_download.svg';
import EyeIcon from 'assets/img/eye.svg';
import ShowPreview from 'core/components/FileDataTable/components/AttachesActions/ShowPreview';
import DownloadFile from 'core/components/FileDataTable/components/AttachesActions/DownloadFile';
import DownloadP7SFile from 'core/components/FileDataTable/components/AttachesActions/DownloadP7SFile';

const styles = (theme) => ({
  maxWidth: {
    maxWidth: 800,
    marginBottom: 16
  },
  details: {
    padding: 24,
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  },
  toolbar: {
    padding: 0,
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  time: {
    fontSize: 14,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      lineHeight: '14px'
    }
  },
  imgIcon: {
    width: 24
  },
  generatedTitle: {
    marginTop: 15,
    fontSize: 14
  },
  generatedAttachmentsWrapper: {
    marginTop: 30,
    padding: 0,
    width: '100%'
  }
});

const ListTemplate = (props) => {
  const {
    t,
    classes,
    name,
    updatedAt,
    createdAt,
    fileType: fileTypeFromServer,
    actions,
    documentId,
    generatedAttachments,
    fileStorage,
    hasP7sSignature
  } = props;

  const [file, setFile] = React.useState(null);
  const [fileType, setFileType] = React.useState(null);

  const supportedTypes = ['pdf', 'xlsx', 'json', 'bpmn', 'png', 'jpeg', 'jpg', 'gif', 'bmp'];

  const onClose = () => setFile(null);

  const handlePreviewFile = async () => {
    const fileBlob = await actions.getPDFDocument({ documentId });

    if (!fileBlob || fileBlob instanceof Error) return;

    const reader = new FileReader();

    reader.readAsDataURL(fileBlob);

    const type = fileBlob.type || [];

    const fileTypeLoaded = type.split('/')[type.split('/').length - 1];

    reader.onloadend = () => {
      setFile(reader.result);
      setFileType(fileTypeLoaded);
    };
  };

  const handleDownloadFile = async () => {
    const fileLoaded = await actions.getPDFDocument({ documentId });

    if (!fileLoaded || fileLoaded instanceof Error) return;

    const type = fileLoaded.type || [];

    const typeInName = name.split('.').pop();

    const typeInFile = type.split('/')[type.split('/').length - 1];

    let newName = name;

    if (type.indexOf(typeInName) === -1) {
      newName = newName + '.' + typeInFile;
    }

    saveAs(fileLoaded, newName);
  };

  const getExtension = (str) => {
    if (!str) return '';
    const getExtFromStr = str.split('/')[1];
    if (getExtFromStr) return getExtFromStr.toLowerCase();
    return '';
  };

  const extension = getExtension(fileTypeFromServer);
  const isSupported = supportedTypes.includes(extension);

  return (
    <div className={classes.maxWidth}>
      <Accordion expanded={true}>
        <AccordionDetails className={classes.details}>
          <Toolbar className={classes.toolbar}>
            <FileNameColumn name={name} />
            <div className={classes.grow} />
            {isSupported ? (
              <Tooltip title={t('Preview')}>
                <IconButton onClick={handlePreviewFile} size="large">
                  <img src={EyeIcon} className={classes.imgIcon} alt="preview" />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title={t('Download')}>
              <IconButton onClick={handleDownloadFile} size="large">
                <img src={SaveAltIcon} className={classes.imgIcon} alt="download" />
              </IconButton>
            </Tooltip>
            {hasP7sSignature ? (
              <DownloadP7SFile
                item={props}
                fileStorage={fileStorage}
                handleDownloadFile={actions.handleDownloadFile}
              />
            ) : null}
          </Toolbar>
          <Typography variant="body2" className={classes.time}>
            {humanDateTimeFormat(updatedAt || createdAt)}
          </Typography>

          {generatedAttachments.length ? (
            <div className={classes.generatedAttachmentsWrapper}>
              {generatedAttachments.map((item) => (
                <div key={item?.id}>
                  <Toolbar className={classes.toolbar}>
                    <FileNameColumn name={item?.name} />

                    <ShowPreview
                      item={item}
                      itemId={item?.id}
                      fileStorage={fileStorage}
                      handleDownloadFile={actions.downloadDocumentAttach}
                      previewIcon={<img src={EyeIcon} className={classes.imgIcon} alt="preview" />}
                    />

                    <DownloadFile
                      item={item}
                      handleDownloadFile={actions.downloadDocumentAttach}
                      downloadIcon={
                        <img src={SaveAltIcon} className={classes.imgIcon} alt="download" />
                      }
                    />
                  </Toolbar>
                  <Typography variant="body2" className={classes.time}>
                    {humanDateTimeFormat(item?.updatedAt || item?.createdAt)}
                  </Typography>
                </div>
              ))}
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>

      <FileViewerDialog
        file={file}
        fileName={name}
        fileType={fileType}
        open={!!file}
        extension={fileType}
        onClose={onClose}
      />
    </div>
  );
};

ListTemplate.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,
  actions: PropTypes.object,
  documentId: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  generatedAttachments: PropTypes.array,
  fileStorage: PropTypes.object.isRequired
};

ListTemplate.defaultProps = {
  name: '',
  updatedAt: '',
  createdAt: '',
  actions: {},
  generatedAttachments: []
};

const mapStateToProps = ({ files: { list: fileStorage } }) => ({
  fileStorage
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPDFDocument: bindActionCreators(getPDFDocument, dispatch),
    downloadDocumentAttach: bindActionCreators(downloadDocumentAttach, dispatch),
    handleDownloadFile: bindActionCreators(downloadFile, dispatch)
  }
});

const styled = withStyles(styles)(ListTemplate);
const translated = translate('InboxFilesListPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
