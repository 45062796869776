const WorkflowProcesses = {
  WorkflowProcesses: 'Documents',
  TaskTemplateId: 'Template number',
  DocumentId: 'Document number',
  TaskTemplateName: 'Template',
  ID: 'Identifier',
  TaskTemplate: 'Template',
  UserName: 'By whom created',
  Performer: 'Performer',
  CreatedAt: 'Date created',
  FileName: 'File',
  FromShort: 'From',
  ToShort: 'To',
  UserIds: 'User IDs',
  Users: 'Users',
  ShowAllPerformerUsers: 'Show',
  PerformerUsers: 'Have access',
  Close: 'Close',
  Name: 'Status'
};

export default {
  locale: 'en',
  App: {
    NeedOnboardingFirst: 'Your account has not been created',
    'NeedOnboardingFirst subtitle':
      'To fill in the information, go to your account and follow the instructions or',
    ApplicationTypeNotDefined: 'The service configuration contains errors',
    'ApplicationTypeNotDefined subtitle': 'Contact the administration to debug the service.',
    NoPermission: 'You do not have permission to view this page',
    'NoPermission subtitle': 'Please contact the administrator',
    NoPermissionIp: 'You are not authorized to view this page',
    'NoPermissionIp subtitle': 'Your IP address is not on the allowed list',
    NoPermissionTitle: 'No access',
    'User without needed role.': 'The account does not have access rights to this section',
    SwitchUser: 'Use another account',
    ConnectionFailed: 'No connection to the server',
    'ConnectionFailed subtitle':
      'Connection to the service is broken. Please check your internet connection',
    'Failed to fetch': 'The service is unavailable',
    'Failed to fetch subtitle': '',
    'User without needed role. subtitle': 'Contact administrator, or',
    PageNotFound: 'Page not found',
    'Access denied.': 'Account does not have access rights',
    'Access denied. subtitle': 'Contact your administrator, or',
    'Declined by user access rules.': 'The account does not have access rights',
    'Declined by user access rules. subtitle': 'Contact an administrator, or',
    NoUnitFound: 'Warning',
    'NoUnitFound subtitle':
      'Dear user! To open access to the cabinet menu, please contact your manager.'
  },

  Navigator: {
    Processes: 'Documents',
    UserLoginJournal: 'Logins',
    UserOperations: 'Operations with users',
    dashboard: 'Home',
    Tasks: 'Tasks',
    Documents: 'Documents',
    AddNewTask: 'Order a service',
    AddNewProject: 'Submit a project',
    Inbox: 'Received documents',
    InboxTasks: 'My tasks',
    UnitInboxTasks: 'Tasks of the department',
    All: 'All',
    ClosedTasks: 'My tasks archive',
    UnitClosedTasks: 'Archive of department tasks',
    Registry: 'Registers',
    Deleted: 'Deleted',
    Trash: 'Trash',

    Workflow: 'My services',
    MyWorkflow: 'Ordered services',
    WorkflowList: 'List of processes',
    MyProjects: 'My projects',
    SubmittedProjects: 'Submitted projects',
    Drafts: 'Drafts',
    Logout: 'Logout',
    MyProfile: 'My profile',
    Messages: 'Messages',
    WorkflowCategories: 'Categories',
    MessagesInbox: 'Inbox',
    AdminWorkflowList: 'Processes',
    MadeBy: 'Developed by',
    UnitList: 'Units',
    DebugMode: 'Developer mode',
    ProcessList: 'Logs',
    NumberTemplates: 'Number templates',
    Users: 'Users',
    List: 'List',

    BpmnList: 'BPMN and forms',
    HealthCheckList: 'Components',
    HealthCheck: 'Healthiness',
    ToCabinet: 'To the cabinet',
    ToAdminPanel: 'To the admin panel',
    UserProcessList: 'Actions in the system',
    UserAccessJournal: 'Role changes',
    JournalList: 'Process logs',
    UIFilters: 'Interface settings',
    Kibana: 'Kibana',
    Reports: 'Reports',
    ReportTemplates: 'Report templates',
    EnabledMockPage: 'Mocks',
    CustomInterfaces: 'Interfaces',
    AdminPanel: 'Administrative panel',
    MessageTemplatesList: 'Message templates',
    MetricsPageTitle: 'Metrics',
    MetricsIntegrationTitle: 'Integrations',
    MetricsProcessesTitle: 'Processes',
    WorkflowJournal: 'Logs',
    UsersList: 'List',
    KibanaTitle: 'Dashboards',
    HealthCheckListTitle: 'Components',
    FavoritesListTitle: 'Favorites'
  },
  TaskListPage: {
    Header: 'Tasks',
    StatusOpened: 'Open',
    StatusClosed: 'Closed',
    StatusAll: 'All',
    TaskName: 'Task',
    TaskCreatingTime: 'Date of creation',
    CreatedBy: 'By whom created',
    UpdatedBy: 'By whom modified',
    createdAt: 'Date of receipt',
    deadline: 'End date of completion',
    RefreshPage: 'Refresh',
    EmptyListTitle: 'You have not received any tasks',
    EmptyListDescription: 'This will display the tasks you have received',
    InboxTasksTitle: 'Current tasks',
    UnitInboxTasksTitle: 'Current tasks of my department',
    ClosedTasksTitle: 'Closed tasks',
    UnitClosedTasksTitle: 'Closed tasks of my department',
    TrashTitle: 'Trash',

    DeleteTask: 'Delete',
    DeleteTaskConfirmation: 'Really delete the applications?',
    DeleteTaskConfirmationText:
      'The applications will be moved to the trash. They can be restored at any time',
    DeleteTaskPermanent: 'Delete permanently',
    DeleteTaskPermanentConfirmation: 'Really delete the applications?',
    DeleteTaskPermanentConfirmationText:
      'The statements will be deleted permanently, it will not be possible to recover them',
    RecoverTask: 'Recover the application',
    ActiveStatus: 'Active',
    FinalStatus: 'Closed',
    DeletedStatus: 'Deleted',
    Performer: 'Performer',
    PerformerUnits: 'Unit',
    Deadline: 'Deadline',
    DocumentNumber: 'Application number',
    Applicant: 'Applicant',
    ApplicantType: 'Applicant Type',
    Individual: 'Individual',
    LegalEntity: 'Legal Entity',
    Status: 'Status',
    DueDate: 'Due date',
    UserName: 'Applicant {{userName}}'
  },
  TaskPage: {
    TriggerError:
      'An error occurred while processing data. Please try again later, or contact support.',
    PreviousPage: 'Previous page',
    NextPage: 'Next page',
    You: 'You',
    TaskAssign: 'Performers',
    Nobody: 'Nobody assigned',
    Search: 'Search',
    Signers: 'The document will be sent after signing:',
    PrevStepBtn: 'Back',
    NextStepBtn: 'Continue',
    CreatePDFBtn: 'Generate document',
    Sign: 'Signing',
    SignBtn: 'Sign and send',
    RejectSignBtn: 'Refuse to sign',
    FinishBtn: 'Finish',
    BackToEdit: 'Return to editing',
    SuccessCommitMessageHeader: 'Congratulations!',
    SuccessCommitMessageText: 'Your application was successfully submitted',
    SigningDialogTitle: 'Please sign the document',
    PDFDocumentDialogTitle: 'Preview the document',
    Logout: 'Logout',

    CloseDialog: 'Close',
    Loading: 'Loading...',
    SaveBtn: 'Save',
    PrintBtn: 'Print',
    DeleteTaskConfirmation: 'Really delete the application?',
    DeleteTaskConfirmationText:
      'The application will be deleted permanently, it will not be possible to restore it',
    RemoveDraft: 'Delete the draft',
    "Sign certificate PEM does not match user's PEM.":
      'You cannot sign the document with this key. Use the key with which you logged in to your account',
    "P7S sign certificate PEM not match user's PEM.":
      'You cannot sign the document with this key. Use the key with which you logged in to your account',
    GoToNextTask: 'Go to the next task',
    ErrorMessageHeader: 'Error',
    ErrorLoadingTasks: 'Unable to load the task',
    "User doesn't have any access to task.": 'No access to view this task',
    ErrorLoadingTemplate: 'Unable to load the task template',
    ErrorCommitDocument: 'An error occurred while sending the document',
    DueDate: 'Due date of the task',
    Cancel: 'Cancel',
    ErrorDialogTitle: 'What a mess!',

    BackToList: 'Return to the list',
    'Document has already committed.': 'Document has already committed',
    '404 File not found': 'Error while working with the document',
    MinDateMessage: 'Expired',
    InvalidDateMessage: 'Invalid date format',
    Preview: 'Preview',
    'Validation error.': 'Document failed validation',
    'Workflow template not found': 'Workflow template not found.',
    'Task template not found.': 'Document template not found',
    'Signed content not match needed': 'Signed content not match needed',
    'Invalid entryTaskTemplateId.': 'The service is not available to you',
    InvalidTemplate: 'Invalid template',
    UnsupportedFormat: 'The format is not supported',
    "Ipn from sign certificate does not match user's ipn":
      "Ipn from sign certificate does not match user's ipn",
    '413 Payload Too Large': 'You are trying to sign a file that is too large',
    RejectSigningDialogTitle: 'Signing failure',
    RejectSignReason: 'Reason for rejection',
    RejectSign: 'Send',
    Start: 'Start',

    ImportData: 'Import from XLSX file',
    'Signed name does not match required.':
      "The name specified in the electronic key does not match the user's name",
    RequiredField: 'Required field',
    "User doesn't have any access to task": "User doesn't have any access to task",
    'User names are not equal.':
      'The name specified in your electronic key does not match the name in the list of signatories. Please contact the initiator of signing to correct the error',
    "Can't update - document contains signatures.":
      'An error occurred when updating data, which cannot be corrected because the document has already been signed',
    DeleteSignatures: 'Revoke',
    MaxSizeError:
      'You are trying to sign a file that is too large. The maximum file size for signing is 5MB.',
    CheckInputValues: 'Check the entered data',
    ErrorValidatingDocument:
      'An error occurred while validating the document. Please refresh the page to correct the entered data',
    'Signer can not sign task before performer.':
      "It will be possible to sign the application after the applicant's signature",
    'Sign not available.': 'Signing is not available, you are not authorized to sign',
    'Commit not available': 'Commit not available',
    'User is not in signerUsers list': 'User is not in signerUsers list',
    StepNotConfigured: 'The service is not configured',
    Attachments: 'Attachments',

    '404 not found': 'Page not found',
    SignProcessSignFile:
      'Signing files {{progress}} with {{total}}. Please do not close the window.',
    SignProcessSignTaskDocument:
      'Sending signatures to the server. Please do not close the window.',
    SignProcessSignP7S: 'Creating a P7S signature. Please do not close the window.',
    SignProcessSignP7SAttach:
      'Creating P7S file signature {{progress}} from {{total}}. Please do not close the window.',
    SignProcessSignAdditionalData: 'Signing additional data. Please do not close the window.',
    SignProcessSignTaskDocumentSignData: 'Signing document data. Please do not close the window.',
    noOptionsText: 'Not found',
    openText: 'Open list',
    clearText: 'Clear',
    closeText: 'Close',
    CommitDocument: 'Send',

    LongPDFFileGenetares: 'Please wait, document generation is in progress...',
    PrintVersion: 'Print Document',
    Attachment: 'Attached document',
    Additional: 'Appendix',
    InformSigners: 'Invite Signers',
    "Signer doesn't have access to task before performer sign it.":
      'Wait, the task has not yet been signed by the initiator.',
    PdfPreviewPlus: 'enlarge pdf preview',
    PdfPreviewMinus: 'reduce pdf preview',
    HandleTask: 'Take to work',
    CancelHandling: 'Cancel',
    HandlingDescription: 'Employed {{time}}, {{user}}',
    LostPageConfirmation: 'Leave site?',
    LostPageConfirmationText: 'You have not sent the document, but you can do so later'
  },
  CreateTaskDialog: {
    SelectTemplate: 'Select a service',
    SearchTemplate: 'Service Search',
    SearchTemplateHelpText: 'Start typing service name',
    SelectProject: 'Select a project',
    SearchProject: 'Search Project',
    SearchProjectHelpText: 'Start typing project name',
    Close: 'Close'
  },
  Errors: {
    '"This key has been locked."': 'This key has been locked for editing',
    Copied: 'Copied',
    Error: 'Error',
    FailedToLoadExternalRegister:
      'Error loading entries from the registry. Try after a while, refresh the page',
    'Can not delete.': 'An error occurred while deleting',
    FailUploadingAttachment: 'Error uploading file',
    FailStoreWorkflow: 'An error occurred while saving the process',
    FailDeletingRows: 'An error occurred while deleting records',
    FailFetchingRows: 'An error occurred while fetching records',
    FailFetchingTask: 'An error occurred while loading the document',
    FailUpdatingDocument: 'An error occurred while saving the document',
    FailCommitDocument: 'An error occurred while submitting the document',
    FailCreatingTask: 'An error occurred while creating the statement',
    FailRecoveringRows: 'An error occurred while recovering the order',
    FailLoadingRegisters: 'An error occurred while loading registers',

    FailStoringRecord: 'An error occurred while saving the registry',
    FailCreatingRecord: 'An error occurred while creating the registry',
    FailDeletingRecord: 'An error occurred while deleting the registry',
    FailLoadingDocumentWorkflowFiles: 'An error occurred loading documents',
    FailLoadingFile: 'An error occurred while loading the file',
    FailDeletingFile: 'An error occurred while deleting the file',
    FailDeletingTask: 'An error occurred while deleting the task',
    FailGeneratingDocument: 'Error generating document',
    FailLoadingWorkflowTemplates: 'Failed to load service templates',
    FailGettingDocument: 'An error occurred while downloading the file',
    FailSigningDocument: 'An error occurred while signing the document',
    FailGettingAttachments: 'An error occurred while downloading all documents',
    FailSettingsTaskSigners: 'An error occurred assigning signers',

    FailGettingUnreadMessageCount: 'An error occurred while retrieving unread messages',
    FailGettingUnreadInboxCount: 'An error occurred while loading unread received documents',
    FailLoadingWorkflowStatuses: 'An error occurred while loading service statuses',
    FailLoadingRegisterKeyRecords: 'An error occurred while loading registry keys',
    FailLoadingDocumentAsicContainer: 'An error occurred while loading the ASIC container',
    FailLoadingDocumentAttachPreview: 'An error occurred while loading the preview',
    FailLoadingDocumentTemplate: 'An error occurred while loading the template',
    FailGettingUnreadTaskCount: 'An error occurred while loading unread tasks',
    FailGettingUnreadUnitTaskCount: 'An error occurred while loading unread unit tasks',
    FailMarkTaskRead: 'An error occurred while marking the task as read',
    FailExportingWorkflow: 'This process cannot be exported because it contains an error',
    FailImportingWorkflow: 'An error occurred while importing the service',
    FailSavingUnit: 'An error occurred while saving the unit',
    FailCalculating: 'A calculation error occurred',

    FailFetchingUnit: 'An error occurred while fetching unit',
    FailCreatingUnit: 'An error occurred while creating the unit',
    ImportSuccess: 'Service import completed successfully',
    ImportUnitsSuccess: 'Import units succeeded',
    FailExportingUnits: 'An error occurred while exporting units',
    FailImportingUnits: 'An error occurred while importing units',
    FailCreatingWorkflow: 'An error occurred while creating the process',
    FailSavingGateway: 'An error occurred while saving the gateway',
    FailSavingEvent: 'An error occurred while saving the event',
    FailSavingTask: 'An error occurred while saving the task',
    FailLoadingWorkflow: 'An error occurred while loading the process',
    InvalidFile: 'The file you are trying to import is invalid',
    FixAllErrors: 'Fix all errors first',

    FailDeletingWorkflowCategory: 'An error occurred while deleting the category',
    ImportRegistersSuccess: 'Registers imported successfully',
    FailImportingRegisters: 'An error occurred while importing registers',
    FailExportingRegisters: 'An error occurred while exporting registers',
    FailExportingTemplates: 'An error occurred while exporting templates',
    FailDeletingWorkflow:
      'This process cannot be deleted because it is being used by other processes',
    FailRequestExternalData: 'An error occurred while loading data',
    RegistersAccessNotAllowed: 'You do not have access to view registry entries',
    RegistersSignaruteError: 'Register data not verified',
    FailGettingPaymentInfo: 'An error occurred while getting payment information',
    FailGettingPaymentStatus: 'An error occurred while getting payment status',
    SuccessPaymentStatus: 'Payment was successful',
    ErrorPaymentStatus: 'Payment failed',

    PendingPaymentStatus:
      'The transaction is still being processed. Please try to check the payment status a little later by clicking "Update Status"',
    FailConfirmingSmsCode: 'Failed to confirm code',
    PaymentCheckCode: 'The payment did not go through. Check the SMS code',
    FailGettingRegistersKeys: 'An error occurred while getting the registry keys',
    ErrorSavingWorkflow: 'An error occurred while saving the process',
    FailToUpdateWorkflowProcessTask: 'An error occurred while saving the task',
    WorkflowOldVersionError: 'Your workflow version is out of date. Refresh the page.',
    FailSearchingUsers: 'An error occurred while searching for users',
    FailGettingWorkflowVersions: 'An error occurred while getting process versions',
    FailCreatingWorkflowVersion: 'An error occurred while creating the process version',
    CreatingVersionSuccess: 'New version of process successfully created',
    FailRestoringWorkflowVersion: 'An error occurred while restoring the process version',
    RestoringVersionSuccess: 'Process version successfully restored',

    NumberTemplateAlreadyExists: 'This template already exists',
    FailSettingRegistersAccess: 'An error occurred while creating access rights',
    FailGettingRegistersAccess: 'An error occurred while getting access rights',
    FailPutingRegistersAccess: 'An error occurred while updating access rights',
    FailRestartingWorkflowProcess: 'An error occurred while restarting the process',
    FailRestartingWorkflowProcessFromPoint:
      'An error occurred while restarting the process from this point',
    FailCheckingExternalReader: 'Error checking data in registry',
    FailReadOnlySavingWorkflow: 'Process tuning is disabled on this environment',
    FaildedGettingPaymentToken: 'Failed to generate card token',

    'update or delete on table "units" violates foreign key constraint "unit_access_unit_id_fkey" on table "unit_access"':
      'Deletion is not possible while the unit is granted access to registers',
    "Task has paid payment! Can not delete, let's commit it!":
      'You cannot delete the draft because it contains payment information',
    "Error: Can't find recipient banking details.": 'Cannot find recipient banking details',
    "Error: Can't define pay type for this phone number.":
      'Cannot define pay type for this phone number',
    "Error: User phone is not defined. Can't get payment data.":
      'User phone is not defined. Unable to get payment details',
    FailToCheckAsNotErrorWorkflowProcess:
      'An error occurred while marking the process as "No Error"',
    'Transaction is declined. Please check if the card details are correct.':
      'The transaction was rejected. Please check that the card details are correct.',
    'Invalid card data.': 'Invalid card data',
    'Transaction is declined. Bank did not approve the transaction. Please, contact the bank.':
      'The transaction was rejected. The bank did not approve the transaction. Please contact the bank.',
    'The limit for the amount or number of customer payments has been exceeded. Amount or transaction limit has been exceeded.':
      'The limit of the amount or number of client payments has been exceeded. Amount or transaction limit exceeded.',

    FailSavingUnitExclusiveUnits: 'User with id {{userId}} already has a role in another unit',
    AddingUserSuccess: 'User successfully added',
    DeletingUserSuccess: 'User deleted successfully',
    AddingHeadSuccess: 'Head successfully added',
    DeletingHeadSuccess: 'Head deleted successfully',
    FailSavingDebugData: 'Error updating schema',
    FailValidatingAppleSession: 'Failed to validate Apple session',
    DeletingUserError: 'An error occurred while deleting a user',
    DeletingHeadError: 'An error occurred while deleting the head',
    AddingUserError: 'An error occurred while adding a user',
    AddingHeadError: 'An error occurred while adding head',

    'Authorization error. Contact issuer bank.': 'Authorization error. Contact the issuing bank',
    'Failed to verify the card for participation in 3DSecure':
      'Failed to verify the card for participation in 3DSecure',
    'Authorization error. Contact issuer bank': 'Authorization error. Contact the issuing bank',
    'Failed to verify the card for participation in 3DSecure.':
      'Failed to verify the card for participation in 3DSecure',

    ReindexSuccess: 'Key "{{name}}" was successfully reindexed',
    AfterhandlersReindexSuccess: 'Successfully reindexed into elastic key "{{name}}"',
    ReindexFailed: 'Error reindexing key "{{name}}"',
    AfterhandlersReindexFailed: 'Error reindexing into elastic key "{{name}}"',
    PaymentInitError: 'An error occurred initializing the payment widget. Please refresh the page.',
    TokenExpiring:
      "Session will expire in less than {{diff}} minutes. Don't forget to save your changes.",
    FailDeletingSignatures: 'The document has been sent. The signature cannot be revoked.',
    FailInformingSigners: 'An error occurred while inviting signers',
    ErrorGettingMocks: 'Error getting mocks',
    UserCertificateExpiring: 'Your private key is expiring {{days}}.',
    UserCertificateExpiringDay: 'Your private key expires in less than a day',
    ErrorGettingMessagesTemplates: 'Error loading list',
    SavingSuccess: 'Saved successfully',
    '404 not found': 'Not found',
    ErrorGettingStatistics: 'Error getting statistics list',
    FailGettingVerifiedUserInfo: 'Error getting verified user information'
  },
  Elements: {
    Done: 'Done',
    CheckData: 'Correct Data',
    Import: 'Import',
    ErrorImportingFromFile: 'An error occurred while importing',
    Undo: 'Undo edit',
    Redo: 'Redo edit',
    Street: 'Street',
    Code: 'Code',
    Editor: 'Editor',
    UploadAnotherFile: 'Upload another file',
    DeleteRow: 'Delete Row',
    Delete: 'Delete',
    Add: 'Add',
    CopyCell: 'Copy',
    CopyRow: 'Copy Row',

    PasteFromClipboard: 'Paste',
    ClearFiles: 'Clear',
    ClearData: 'Clear Data',
    ClearDataPrompt: 'Are you sure you want to clear all table data?',
    ExportDataToPdf: 'Export Data',
    Error: 'Error',
    SHOW_SAMPLE_DIALOG: 'How to fill',
    SAMPLE_EXPAND: 'How to fill',
    CLOSE: 'Close',
    Close: 'Close',
    Cancel: 'Cancel',
    CustomValue: 'Custom variant',
    Accept: 'Yes',
    Yes: 'Yes',
    No: 'No',

    DropFiles: 'Drag files here or {{link}}',
    SelectFiles: 'download them from your media',
    MaxFileSizeLimit: 'Maximum size of one file: {{size}}',
    MinFileSizeLimit: 'Minimum size of one file: {{size}}',
    FileSizeLimitReached: 'Maximum file size or incorrect file format',
    FileTypeLimit: 'Allowed formats: {{types}}',
    FileName: 'Document',
    FileDate: 'Date',
    IsGenerated: 'Document Generated',
    AddArrayItem: 'Add',
    DownloadAllAttachments: 'Download all documents',
    NotRequired: 'not required',
    AddItem: 'Add Entry',
    DeleteItem: 'Delete entry',

    NoOptions: 'Not found',
    EditMode: 'Edit {{mode}}',
    ValidationErrors: 'Fix all errors first',
    Apply: 'Understood',
    Search: 'Search',
    AddUsers: 'Adding users',
    FinishEditing: 'Finish Editing',
    EmptySelectedUsers: 'The list of users is empty',
    EmptySearchResults: 'No users found',
    SearchResults: 'Search results:',
    SearchRegisteredUser: 'Search among registered users',
    SearchByName: 'by last name',
    SearchByIPN: 'by ITN',
    SearchByID: 'by ID',
    Clear: 'Clear',

    SelectRecord: 'Select from list',
    EmptyData: 'No data',
    FROM: 'from',
    MakePayment: 'Make Payment',
    Paid: 'Paid',
    SYMBOLS_COUNT: 'Entered characters: {{num}} {{max}}',
    Currency: 'UAH',
    PhoneConfirnmation:
      'We have sent you an SMS message with a code to the specified phone <b>{{contact}}</b>. Enter it to confirm that this is your number',
    PhoneConfirnmationSuccess: 'You have successfully confirmed your phone',
    EmailConfirnmation:
      'We have sent you an email with a code to the email address <b>{{contact}}</b>. Enter it to confirm that this is your email address',
    EmailConfirnmationSuccess: 'You have successfully confirmed your email address',
    FailToValidateCode: 'Invalid validation code',
    SendAnotherCode: 'Send again',

    SendAnotherCodeCounDown: 'You can send again via {{counter}}',
    PhoneIsAlreadyExists: 'This phone number ({{contact}}) is already in use in the system.',
    EmailIsAlreadyExists: 'This email address ({{contact}}) is already in use in the system.',
    FailToSendVerifyCode: 'An error occurred while sending. Try later.',
    Edit: 'Edit',
    Phone: 'Phone',
    SmsCode: 'Code from SMS',
    EmailCode: 'Code from email',
    Resend: 'Send again',
    GetCode: 'Get Code',
    SendCode: 'Send Code',
    RefreshPaymentStatus: 'Refresh Status',
    Loading: 'Loading...',
    Open: 'Open',

    PhoneValidationMessage: 'Enter phone number in the form 380XXXXXXXXX',
    CodeValidationMessage: 'Enter code from SMS',
    QRError: 'The payment service is temporarily unavailable, please try again later',
    Save: 'Save',
    CodeEditorAlertTitle: 'Are you sure you want to close this window?',
    CodeEditorAlertText: 'All changes will be saved',
    UploadFiles: 'Upload',
    OpenEditor: 'Open editor',
    OpenTableEditor: 'Open Table',
    TableError: 'There are errors in the table.',
    ResolveErrors: 'Attention',
    ResolveErrorsPrompt: 'Before exiting the editor, fix errors in the schema.',
    DeletePrompt: 'Attention',
    DeletePropmtDescription: 'Closing this window will delete the entered data',
    AddNewRow: 'Add new row',
    Widget: 'Bank card',

    ChangeValue: 'Change',
    Unselected: 'Not selected',
    AndMore: 'And {{count}} more...',
    RowAndColumn: 'row {{rowId}}, column "{{columnId}}"',
    ToggleFullscreen: 'Full screen mode',
    PaymentWidgetFinalText: 'Service successfully paid',
    taskTitle: 'Task Title',
    finalScreenTitle: 'Final Screen Title',
    finalScreenSubtitle: 'Final Screen Text',
    UnsavedErrors: 'Attention',
    ResolveUnsavedPrompt: 'There is unsaved data. Save?',
    Exit: 'Exit',

    OtherCountryCode: 'You are entering an incorrect country code',
    EditorBtnLabelTypeOfText: 'select text type',
    EditorBtnLabelBold: 'font-weight 700',
    EditorBtnLabelItalic: 'italic font style',
    EditorBtnLabelUnderline: 'underline selected text',
    EditorBtnLabelStrike: 'striking out selected text',
    EditorBtnLabelAlignTextLeft: 'align text to the left',
    EditorBtnLabelAlignTextRight: 'align text to the right',
    EditorBtnLabelAlignTextCenter: 'align text to center',
    EditorBtnLabelOlList: 'numbered list',
    EditorBtnLabelUlList: 'unordered list',
    EditorBtnLabelAddIndent: 'add text indent',
    EditorBtnLabelRemoveIndent: 'remove text indent',
    EditorBtnLabelColor: 'change text color',

    EditorBtnLabelBackground: 'change background color in text',
    EditorBtnLabelClean: 'reset text styles',
    FreeSlots: 'Free time for the next 7 days',
    NoFreeSlots: 'Unfortunately, there are no free slots on this day',
    NoFreeSlotsAll:
      'There is no free time on this day and during the next 7 days. Try another date',
    PhoneNumberTitle: 'Enter Phone Number',
    ToggleSwitchTitle: 'Switch',
    ErrorValidatingDocument: 'Error validating document',
    tryReload: 'Try to refresh the page and fix the error',
    Attachments: 'Attachments:'
  },
  SignForm: {
    sign: 'Signature',
    stamp: 'Stamp',
    Timeout: 'The function timed out. try later',
    ACSKServer: 'Qualified Electronic Trust Service Provider',
    ACSKServerHint: 'Select the certificate authority that issued you an electronic signature',
    SelectServer: 'Select the certificate authority that issued you an electronic signature',
    Key: 'Key File',
    KeyHint: 'Choose a private key file on your computer',
    SelectedKey: 'Key',

    SelectedKeyHint: 'Select a key, seal or signature',
    SelectKey: 'Select a private key file on your computer',
    Password: 'Password',
    PasswordHint: 'Enter private key password',
    FillPassword: 'Enter private key password',
    Sign: 'Sign',
    SigningDataError: 'Error',
    Cancel: 'Cancel',
    CloseDialog: 'Close',
    CloseDialog2: 'Understood',
    FileKeySignMethod: 'File Key',
    HardwareKeySignMethod: 'Hardware Key',

    HardwareKeySignMethodNotSupportedMobile:
      'The hardware key is not supported in the mobile version',
    HardwareKeySignMethodNotSupportedBrowser: 'The hardware key is not supported by your browser',
    SelectKmType: 'Device Type',
    SelectKmDevice: 'Device',
    TypeHint: 'Select a device type',
    DeviceHint: 'Select a device',
    SelectType: 'Select device type',
    SelectDevice: 'Select a device',
    ProxySettings: 'Proxy',
    ProxySettingsTitle: 'Proxy Server Settings',
    ProxySettingsEnable: 'Use proxy server',
    ProxySettingsAddress: 'Proxy Address',
    ProxySettingsPort: 'Port',

    ProxySettingsAnonymous: 'Anonymous',
    ProxySettingsUser: 'User',
    ProxySettingsPassword: 'Password',
    CantDetectACSK: 'Unfortunately, we were unable to automatically detect the CA of your key',
    ACSKAutoDetect: 'Detect automatically',
    DropFiles: 'Drop your key file or {{link}} here',
    UploadFiles: 'choose it from your media',
    DropFilesLimits: 'Supported formats: .jks, .pfx, .pk8, .zs2, .dat',
    DropedFile: 'Key File:',
    DropNewFile: 'Upload another file',
    Refresh: 'Refresh',

    PROXY_SETTINGS_ENABLE: 'Use proxy service',
    PROXY_SETTING: 'Proxy service settings',
    PROXY_SETTINGS_ADDRESS: 'Host',
    PROXY_SETTINGS_PORT: 'Port',
    PROXY_SETTINGS_ANONYMOUS: 'Anonymous',
    PROXY_SETTINGS_USER: 'User',
    PROXY_SETTINGS_PASSWORD: 'Password',
    FileSizeLimit: 'Exceeded file size or incorrect file format',
    NoItems: 'Key not found',
    InvalidFileFormat: 'Invalid file format',
    "Ipn from sign certificate not match user's ipn.":
      "IPn from sign certificate not match user's ipn.",
    '413 Payload Too Large': 'You are trying to sign a file that is too large',
    DiiaSignMethod: 'e-Signature',
    Continue: 'Continue',
    ExpiringTitle: 'Attention!',
    ExpiringDescription: 'Your private key is expiring {{days}}.'
  },
  DataTable: {
    DISPLAYED_ROWS: 'from {{from}} to {{to}} from {{total}}',
    ROWS_PER_PAGE: 'Show per page',
    SelectedRows: 'Rows selected',
    Delete: 'Move to Trash',
    RecoverItem: 'Recover Deleted',
    RemovePermanent: 'Remove from Cart',
    Reload: 'Refresh',
    Search: 'Search',
    Select: 'Choose',
    SwitchView: 'Switch Display',
    Show: 'Show:',
    EmptyData: 'No data',
    AddItem: 'Add Entry',
    CustomizeColumns: 'Columns',
    WithoutGroup: 'Without Category',
    Close: 'Close',
    ToggleGrouping: 'Grouping',
    SaveAsPreset: 'Save Filters',
    ClearFilters: 'Clear Filters',
    AddFilterPreset: 'Add filter presets',
    Cancel: 'Reject',
    AddPreset: 'Add',
    FilterPresetName: 'Preset Name',
    ToggleFullscreen: 'Full screen mode',
    NextPage: 'Next Page',
    PrevPage: 'Previous Page',
    CheckboxAllButton: 'Select all files',
    CheckboxButton: 'Select File',
    FirstPage: 'First',
    LastPage: 'Last',
    Backward: 'Backward',
    Forward: 'Forward',
    From: 'from',
    PerPageText: 'Per Page',
    toFirstPage: 'To the first page',
    toPrevPage: 'To previous page',
    toNextPage: 'To next page',
    toLastPage: 'To the last page',
    name: 'NAME',
    id: 'Identifier',
    ipn: 'ITN',
    actions: 'Actions'
  },
  DataList: {
    Back: 'Previous page',
    Forward: 'Next page'
  },
  RegistryPage: {
    SearchRegister: 'Search by register name',
    HasUnsavedData: 'Attention!',
    HasUnsavedDataPrompt:
      'The entry contains unsaved data, are you sure you want to close the window?',
    title: 'Registry Editor',
    SelectKey: 'Select Registry...',
    SelectRegistryKeyLabel: 'Registry Key',
    SearchFieldLabel: 'Search',
    RowsPerPage: 'Records Per Page',
    Name: 'Name',
    CreatedBy: 'Created By',
    CreatedAt: 'Created Date',
    UpdatedBy: 'Updated by',
    UpdatedAt: 'Update Date',
    Delete: 'Delete',
    Close: 'Close',
    Save: 'Save',
    DeleteRecordConfirmation: 'Are you sure you want to delete the record?',
    DeleteRecordConfirmationText:
      'The record will be permanently deleted, it will no longer be possible to restore it.',
    AddNewRow: 'New Row',
    NoData: 'No records',
    Actions: 'Actions',
    Edit: 'Edit',
    ExportToExel: 'Export to Excel',
    Pagination: '{from} - {to}',
    ChooseColumns: 'Choose Columns',
    Records: 'Records',
    History: 'History of changes',
    Operation: 'Operation',
    create: 'Creation',
    update: 'Change',
    delete: 'Delete',
    Versions: 'View Versions',
    "User doesn't have any access to register record.":
      'Your account does not have any access to this section',
    FirstPage: 'First',
    LastPage: 'Last',
    Backward: 'Backward',
    Forward: 'Forward',
    From: 'from',
    ColumnChooser: 'Choose Columns',
    Processing: 'Please wait...'
  },
  MimeType: {
    OR: ' or ',
    'image/*': 'image',
    'audio/': 'audio',
    'video/': 'video',
    'audio/*': 'audio',
    'video/*': 'video',
    'image/gif': 'Image GIF',
    'image/jpg': 'JPG image',
    'image/jpeg': 'JPG image',
    'image/png': 'PNG image',
    'image/heic': 'HEIC image',
    'image/heif': 'HEIF image',
    '.jpg': 'JPG image',
    '.jpeg': 'JPG image',
    '.png': 'PNG image',
    '.gif': 'GIF image',
    'application/pdf': 'PDF document',
    'application/msword': 'Word document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word document',
    'application/vnd.oasis.opendocument.text': 'Word document',
    'application/rtf': 'Word document',
    'application/vnd.ms-excel': 'Excel document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel document',
    '.geojson': '.geojson'
  },
  EJV: {
    CheckValid: 'Check field',
    SchemaNotFound: 'The schema "{{ref}" was not found',
    ShouldIncludeLessThan: [
      'Must have at most one element',
      'Must have no more than {{n}} elements'
    ],
    ShouldIncludeMoreThan: ['Must have at least one element', 'Must have at least {{n}} elements'],
    ShouldNotInclude: 'Should not have additional fields',
    NotEquilsTo: 'Does not match specified parameters',
    HaveNoDependencies: 'No dependencies',
    MustBe: 'Must be {{cond}}',
    InvalidSchema: 'invalid schema',
    MustHaveFormat: 'Correct your input',
    FormatExclusiveMaximum: 'formatExclusiveMaximum must be boolean',
    FormatExclusiveMinimum: 'formatExclusiveMinimum must be boolean',
    MustEquilsToSchema: 'Must match the schema "{{keyword}}"',
    ShouldBeLessThan: [
      'Must be no longer than one character',
      'Must be no longer than {{n}} characters'
    ],
    ShouldBeMoreThan: ['There must be at least one character', 'Must be at least {{n}} characters'],
    ShouldHaveLessPropertiesThan: [
      'Must have at least one field',
      'Must have at least {{n}} fields'
    ],
    ShouldHaveMorePropertiesThan: [
      'Must have no more than one field',
      'Must have no more than {{n}} fields'
    ],
    MustBeMultipleOf: 'must be a multiple of {{multipleOf}}',
    ShouldNotBe: 'Should not match the scheme in "not"',
    ShouldBeOneOf: 'Should match exactly one scheme in "oneOf"',
    ShouldPatternRequired: 'Should have a field, according to the example "{{missingPattern}}"',
    InvalidPropertyName: 'The field name "{{propertyName}}" does not match the schema',
    ReqiredField: 'Required field',
    ShouldHaveUniqueElements: 'should not have duplicate elements ({{j}} and {{i}} are the same)',
    AllFieldsRequired: 'All fields are required',
    ContactConfirmationRequired: 'A valid confirmation code is required'
  },
  WorkflowListPage: {
    InboxTitle: 'Ordered services',
    ProjectTitle: 'Submitted Projects',
    Drafts: 'Drafts',
    WorkflowNumber: 'Request Number',
    WorkflowName: 'Service',
    ProjectName: 'Project',
    LastStepLabel: 'Status',
    CreatedAt: 'Created Date',
    OrderedAt: 'Order Date',
    UpdatedAt: 'Update Date',
    SubmittedAt: 'Submission Date',
    DeleteWorkflow: 'Delete',
    EmptyListTitle: 'You have not ordered any services',
    EmptyListDescription: 'This will display the services you have ordered and their progress',
    EmptyProjectListTitle: 'You have not submitted any project',
    EmptyProjectListDescription:
      'The projects you have submitted and their progress will be displayed here',
    EmptyTrashListTitle: 'Trash is empty',
    EmptyTrashListDescription: 'Deleted services will be displayed here',
    EmptyDraftsListTitle: 'Drafts List is empty',
    EmptyDraftsListDescription: 'Drafts will be displayed here',
    DeleteWorkflowConfirmation: 'Are you sure you want to delete the service?',
    DeleteWorkflowConfirmationMultiple: 'Are you sure to delete services?',
    DeleteWorkflowConfirmationText:
      'The service will be moved to the cart. They can be restored at any time.',
    DeleteProjectConfirmation: 'Are you sure you want to delete projects?',
    DeleteProjectConfirmationText:
      'Projects will be moved to the trash. They can be restored at any time.',
    DraftStatus: 'Draft',
    NoStatus: 'Status not set',
    Doing: 'At work',
    Done: 'Given',
    Rejected: 'Rejected',
    All: 'All',
    TrashPage: 'Trash'
  },
  TrashListPage: {
    TrashPage: 'Trash',
    RestoreTrash: 'Restore',
    DeleteTrash: 'Delete permanently',
    DeleteTrashConfirmation: 'Are you sure you want to delete services?',
    DeleteTrashConfirmationText:
      'Services will be deleted permanently, it will no longer be possible to restore them.',
    DeleteProjectTrashConfirmation: 'Are you sure you want to delete projects?',
    DeleteProjectTrashConfirmationText:
      'The projects will be permanently deleted, it will no longer be possible to restore them.',
    RestoreTrashConfirmation: 'Are you sure to restore services?',
    RestoreTrashConfirmationText: 'Service will be restored. They can be removed again.',
    RestoreProjectTrashConfirmation: 'Really restore projects?',
    RestoreProjectTrashConfirmationText: 'The project will be restored. They can be removed again.',
    EmptyTrashListTitle: 'Trash is empty',
    EmptyTrashListDescription: 'Deleted services will be displayed here',
    WorkflowName: 'Service'
  },
  WorkflowPage: {
    Loading: 'Loading...',
    CreatedAt: 'Ordered {{time}}',
    SubmittedAt: 'Submitted at {{time}}',
    FileName: 'Document',
    FileDate: 'Date',
    IsGenerated: 'Document Generated',
    PDFDocumentDialogTitle: 'Document Preview',
    CloseDialog: 'Close',
    ShowPreview: 'Preview',
    DownloadFile: 'Download',
    DeleteFile: 'Delete',
    DeleteRecordConfirmation: 'Are you sure you want to delete the file?',
    DeleteRecordConfirmationText:
      'The files will be permanently deleted, it will no longer be possible to restore them.',
    DownloadAllAttachments: 'Download all documents',
    TaskCreated: 'Task #{{id}} created',
    IncomingMessage: 'Incoming message:',
    Delete: 'Delete',
    PageNotFound: 'Page not found',
    DownloadFileP7S: 'Download P7S'
  },
  MessageListPage: {
    From: 'From date',
    To: 'By date',
    FromShort: 'From',
    ToShort: 'To',
    UnreadOnly: 'Only unread',
    Unread: 'Unread',
    Read: 'Read',
    InboxTitle: 'Inbox',
    MessageTitle: 'Message',
    createdAt: 'Received',
    Delete: 'Delete',
    DeletePermanent: 'Delete',
    Recover: 'Recover Deleted',
    EmptyListTitle: 'You have not received any messages',
    EmptyListDescription: '',
    DeletePermanentConfirmation: 'Are you sure you want to delete the message?',
    DeletePermanentConfirmationText:
      'Messages will be permanently deleted, it will no longer be possible to restore them.',
    MarkAllAsRead: 'Mark as read'
  },
  MessagePage: {
    CreatedAt: 'Sent {{time}}',
    Loading: 'Loading...',
    DecryptMessage: 'Decrypt',
    DecryptionDialogTitle: 'Decrypt message'
  },
  UserProfile: {
    EditUserHeader: 'User Profile',
    EditUserHeaderHint: 'Edit Profile',
    SaveButton: 'Save',
    LastNameInputLabel: 'Last Name',
    FirstNameInputLabel: 'FirstName',
    MiddleNameInputLabel: 'Patronymic',
    EmailInputLabel: 'Email',
    PhoneInputLabel: 'Phone',
    BirthdayInputLabel: 'Date of Birth',
    RegistrationInputLabel: 'State registration date',
    BirthdayInputError: 'Enter a date in the format 01.01.2000',
    IpnInputLabel: 'RNOKPP (formerly TIN) or passport series and number',
    ProfileSaved: 'Profile saved',
    Saved: 'Changes saved',
    TwoFactorAuthEnable: 'Enable two-factor authentication at login',
    TwoFactorAuthDisable: 'Disable two-factor authentication at login',
    TwoFactorAuthTitle: 'Enable two-factor authentication',
    TwoFactorAuthText:
      'If you enable two-step verification, you will additionally receive an SMS with a unique code to your phone every time you log in to the system.',
    ConfirmText: 'To activate, you will be sent an SMS with a code to the number:',
    EmailError: 'Invalid email address',
    NotChangeEmailError: 'You entered the same address',
    DuplicateEmailError: 'This email address is already registered to another account',
    EmailDialogTitle: 'Change Email Address',
    PhoneDialogTitle: 'Change phone number',
    EmailDialogText: 'Enter a new address. An email will be sent to you with a confirmation code:',
    PhoneDialogText: 'Enter a new number. An SMS will be sent to it with a confirmation code:',
    PhoneAlreadyExists: 'This phone number is already in use',
    TextWaitForCode: 'Please wait for the email and enter the code in the field below:',
    TextWaitForSMSCode: 'Please wait for the SMS and enter the code in the field below:',
    SendMail: 'Send Mail',
    ValidationFalse: 'Code failed validation',
    CodeInputLabel: 'Confirmation Code',
    VerifyCode: 'Verify',
    ResendCode: 'Send email again',
    ResendSMS: 'Send SMS again',
    SendCode: 'Send mail',
    SendSMS: 'Send SMS',
    CompanyNameInputLabel: 'Legal Entity',
    EDRPOUInputLabel: 'EDRPOU code',
    Upload: 'Upload scanned document',
    IndividualEntrepreneur: 'Individual entrepreneur',
    PhoneValidationNeeded: 'You have not validated your phone. {{actions}}',
    ValidatePhone: 'Validate',
    ValidatePhoneTitle: 'Validate phone number.',
    ValidatePhoneMessage:
      "A code has been sent to your {{phone}} phone. To confirm, enter it in the field below and click the 'Validate' button",
    Cancel: 'Cancel',
    Validate: 'Confirm',
    ValidatePhoneSuccess: 'Number validation successful.',
    Close: 'Close',
    Address: 'Address',
    LegalAddress: 'Physical Location Address',
    RequiredFields: '* - mandatory',
    AddressHelper:
      'Enter the region, district, city (village, village), street (boulevard, avenue, Uzviz, etc.), building, district, postal code.',
    PassportSeries: 'Series',
    PassportNumber: 'Number',
    PassportIssueDate: 'Issue Date',
    PassportIssuedBy: 'Issued by'
  },
  DatePicker: {
    Label: 'Date',
    LabelData: 'Selected date {{date}}',
    ChooseLabelData: 'Choose a date',
    ClearInputBtn: 'Clear selected date',
    FormatError: 'Enter a date in the format 01.01.2000',
    MaxDateError: 'Date must be no later than {{date}}',
    MinDateError: 'Date must not be earlier than {{date}}',
    FromDate: 'From Date',
    ToDate: 'By date',
    CorrectDate: 'For Date',
    Cancel: 'Close',
    BtnPrevMonth: 'Previous Month',
    BtnNextMonth: 'Next Month',
    January: 'January',
    February: 'Fury',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    S: 'Sun',
    M: 'Mon',
    T: 'Tue',
    W: 'Wed',
    Th: 'Thurs',
    F: 'Fri',
    St: 'Sat',
    defaultPlaceholder: 'Select...',
    from: 'from',
    to: 'to'
  },
  InboxFilesListPage: {
    InboxFilesTitle: 'Received Documents',
    EmptyListTitle: 'You have not received any document',
    EmptyListDescription:
      'Documents received in the process of using the service will be displayed here',
    InboxFilesNumber: 'Number',
    InboxFilesName: 'Document',
    CreatedAt: 'Received Date',
    MarkAllAsRead: 'Mark as read'
  },
  Labels: {
    DueDate: 'Due to {{date}}',
    Expired: 'Expired on {{date}}'
  },
  InboxFilesPage: {
    Loading: 'Loading...',
    FileName: 'Document',
    FileDate: 'Date',
    DownloadAsic: 'Download signed documents',
    SaveBtn: 'Save',
    PrintBtn: 'Print',
    FileLoadingError: 'Loaded file not found'
  },
  FileDataTable: {
    FileName: 'Document',
    FileDate: 'Date',
    Download: 'Download Favorites',
    ArchiveName: 'Documents.zip',
    Preview: 'Preview',
    Signature: 'Signature',
    DownloadFile: 'Download',
    Originals: 'Original files',
    Asics: 'ASiC-S containers',
    DeleteFile: 'Delete File',
    DeleteFilePrompt: 'Are you sure you want to delete the file?',
    P7SSigns: 'P7S signature',
    Print: 'Print',
    DownloadState: 'Downloading {{state}} ...'
  },
  SignerListComponent: {
    EmptySignerList: 'List of signers is empty'
  },
  SmartMessage: {
    CloneWorkflow: 'Create New Task'
  },
  Settings: {
    SettingsTitle: 'Import/Export Services',
    ImportFromFile: 'Import from file',
    Upload: 'Upload',
    Import: 'Import',
    Export: 'Export'
  },
  PageTitles: {
    InboxTitle: 'Ordered services',
    Users: 'Users',
    UIFilters: 'Cabinet Sections',
    KibanaTitle: 'Kibana',
    Reports: 'Reports',
    ReportTemplates: 'Report Templates',
    CustomInterfaces: 'Interfaces',
    FavoritesListTitle: 'Favorites'
  },
  MessagesNotify: {
    AllMessages: 'All Messages'
  },
  TreeListSelect: {
    Select: 'Not selected',
    Loading: 'Loading...',
    Search: 'Search...',
    Close: 'Close'
  },
  WorkflowListAdminPage: {
    AdminWorkflowList: 'BPMN and Forms',
    Journal: 'View Journal',
    WorkflowName: 'Process Name',
    WorkflowId: 'Number',
    WorkflowNumber: 'Number (optional)',
    ImportWorkflow: 'Import',
    ExportWorkflow: 'Export Process',
    CreateWorkflow: 'Create',
    FillNewId: 'Enter new process ID',
    OverwriteWorkflowConfirmation: 'Processes with this ID already exist',
    OverwriteWorkflowConfirmationText: 'Are you sure you want to overwrite existing processes?',
    NewWorkflow: 'Create Process',
    Continue: 'Continue',
    Customer: 'Customer',
    CreatedAt: 'Created Date',
    UpdatedAt: 'Update Date',
    WorkflowCategory: 'Category',
    Actions: 'Actions',
    WorkflowErrorTitle: 'An error occurred',
    'Workflow already exists.': 'A process with this ID already exists',
    '403 forbidden': 'Process creation is disabled on this environment',
    '403 forbidden_deleting': 'Process deletion is disabled on this environment',
    DeleteWorkflow: 'Delete Process',
    DeleteWorkflowDialogTitle: 'Are you sure you want to delete the process?',
    DeleteWorkflowDialogDescription:
      'The deletion will be final, it is possible to restore the process only from devices exported to the hard disk.',
    ErrorWhileDeletingWorkflow: 'Error deleting process',
    WorkflowDeletingErrorMessage:
      'This process cannot be deleted because it is used by other processes',
    'Some process has been started already based on this workflow template._deleting':
      'This process cannot be deleted because it is used by other processes',
    CloseErrorDialog: 'Close',
    UpdatedBy: 'Updated by',
    RequiredField: 'Required field',
    EditName: 'Edit Name'
  },
  DebugTools: {
    AuthTools: 'Authorization',
    TaskDataTools: 'Statement Data',
    CheckValidFunction: 'CheckValid function',
    CheckHiddenFunction: 'Check function isHidden',
    Token: 'Token',
    SelectPage: 'Select a step',
    SelectElement: 'Select an element',
    CheckFunction: 'Check Function',
    Function: 'Function',
    Result: 'Result',
    WorkflowLogs: 'Logs',
    CreatedAt: 'Date',
    EDSFormTest: 'Signature Form Test',
    EDSSignVerify: 'Signature Verification',
    PopupDebugTools: 'Popup data',
    PopupCheckValidTools: 'CheckValid on Popup',
    Curator: 'Sign in as...',
    CustomInterfaceCheckHidden: 'Custom Interfaces',
    CustomInterfaceCheck: 'Interfaces'
  },
  UnitsListPage: {
    Journal: 'Role Changes',
    UnitList: 'Units',
    UnitId: 'Unit ID',
    UnitName: 'Unit Name',
    HeadCount: 'Heads',
    Actions: 'Actions',
    CreateNewUnit: 'Create',
    CreaingNewUnit: 'Creating unit',
    ImportUnits: 'Import',
    ExportUnits: 'Export',
    OverwriteUnitsConfirmation: 'Units with this ID already exist',
    OverwriteUnitsConfirmationText: 'Are you sure you want to overwrite existing units?',
    CreatedAt: 'Created Date',
    UpdatedAt: 'Update Date',
    DeleteUnitsDialogTitle: 'Are you sure you want to delete the selected units?',
    DeleteUnitsDialogDescription:
      'The deletion will be final, it is possible to restore units only from those exported to the hard disk of the device.',
    DeleteUnits: 'Delete Units',
    UnitDescription: 'Unit Description',
    Continue: 'Save',
    Close: 'Close',
    RequiredField: 'Required field',
    'Error: Unit already exists.': 'A unit with this name already exists',
    'Error: Unit id already exists.': 'Unit with this ID already exists',
    Id: 'Number',
    BasedOn: 'Base Unit',
    name: 'Unit name'
  },
  UnitPage: {
    Unit: 'Unit',
    Save: 'Save',
    Loading: 'Loading...',
    NewUnit: 'Create new unit',
    UnitErrorTitle: 'An error occurred',
    'Unit already exists.': 'A unit with this name already exists',
    Users: 'Users',
    Registered: 'Registered',
    IPNUsers: 'IPNUsers',
    Heads: 'Heads',
    Interface: 'interface',
    Plugins: 'Plugins',
    BlocksAccess: 'Blocks Settings',
    Tokens: 'Tokens',
    RequiredField: 'Required field',
    BasedOn: 'Base Unit',
    AddUsers: 'Add users',
    AddHeads: 'Add Heads',
    NoUsers: 'No users found',
    FilterUsers: 'Find a user by name, ITN or ID',
    FilterHeads: 'Find among department heads',
    AddHeadsDialogTitle: 'Add Heads',
    UnitName: 'Unit Name',
    UnitDescription: 'Unit Description',
    RemoveFromFavorites: 'Remove from favorites',
    AddToFavorites: 'Add to Favorites',
    EditName: 'Edit title or description'
  },
  WorkflowCategoryListPage: {
    CreateCategory: 'Create',
    WorkflowCategoryList: 'Categories',
    ErrorWhileDeletingCategory: 'Error deleting category',
    CategoryDeletingErrorMessage:
      'This category cannot be deleted because it is in use by other processes',
    CloseErrorDialog: 'Close',
    Name: 'Name',
    ChangeCategoryNameTitle: 'Change Category',
    Save: 'Save',
    EditCategory: 'Edit',
    DeleteCategory: 'Delete',
    RequiredField: 'Name cannot be empty'
  },
  RegistryListAdminPage: {
    RegisterList: 'Registers',
    RegisterId: 'Number',
    RegisterName: 'Name',
    RegisterDescription: 'Description',
    CreatedBy: 'Created By',
    UpdatedBy: 'Updated by',
    CreatedAt: 'Created Date',
    UpdatedAt: 'Update Date',
    ExportRegister: 'Export',
    ExportRegisterTitle: 'Export register "{{name}}"',
    ExportRegisterWithData: 'Export with data',
    EditRegister: 'Edit',
    ImportRegisters: 'Import',
    OverwriteRegisterConfirmation: 'Registries with this ID already exist',
    OverwriteRegisterConfirmationText: 'Do you really want to overwrite the existing registers?',
    HowToExport: 'How to export?',
    HowToImport: 'How to import?',
    Continue: 'Continue',
    WithData: 'With data',
    WithoutData: 'Without data',
    RewriteSchema: 'Update Key Schema',
    ClearRecords: 'Delete old records',
    AddData: 'Add new records',
    FailExportingRegisters: 'An error occurred while exporting registers',
    ErrorWhileExportingRegister: 'Error exporting register',
    RegisterExportErrorMessage: 'This register is too large to export with data.',
    CloseErrorDialog: 'Close',
    CloseKeysDialog: 'Close',
    RegistryKeys: 'Registry Keys',
    ImportRegistersKeysXLS: 'Import from XLSX',
    RegisterID: 'Register ID',
    KeyId: 'Registry Key ID',
    Unique: 'Unique column in XLS table (optional)',
    ErrorImportingKeys: 'Error importing keys from XLS',
    RequiredField: 'Required field',
    NewRegister: 'New Register',
    Name: 'Name',
    Description: 'Description',
    ParentId: 'Parent Registry',
    IsTest: 'Test Registry',
    Cancel: 'Cancel',
    Save: 'Save',
    CreateNew: 'Create',
    DeleteRegister: 'Delete',
    DeletePrompt: 'Delete confirmation',
    DeletePropmtDescription:
      'Are you sure you want to delete the registry "{{register}}"? This action is irreversible. If you delete the registry, it will not be possible to restore it.',
    DeleteError: 'Error deleting registry',
    'Can not delete.': 'The registry contains filled data.',
    'Can not create.': 'Unable to create registry, the name may already be in use.',
    'Can not update.': 'Unable to change the registry, the name may already be in use.',
    UseStream: 'Use Stream',
    RegistryId: 'Registry ID',
    RegistryKeyId: 'Registry Key ID',
    Reindex: 'Reindex',
    AfterhandlersReindex: 'Reindex to elastic',
    Actions: 'Actions',
    RegisterChoose: 'Register',
    RegistryKeyChoose: 'Registry Key'
  },
  KeyListAdminPage: {
    KeyList: 'Registry Keys',
    KeyId: 'Number',
    KeyName: 'Name',
    KeyDescription: 'Description',
    CreatedBy: 'Created by',
    CreatedAt: 'Created When',
    UpdatedBy: 'Updated by',
    UpdatedAt: 'Updated When',
    NewKey: 'New Key',
    EditKey: 'Edit Key',
    PreviewKey: 'Preview Key',
    Name: 'Name',
    Description: 'Description',
    ParentId: 'Parent Key',
    IsTest: 'Test Key',
    IsLocked: 'Locked key',
    Cancel: 'Cancel',
    Save: 'Save',
    JsonSchema: 'Schema',
    ToString: 'ToString Function',
    ToSearchString: 'ToSearchString Function',
    DeleteKey: 'Delete',
    DeletePrompt: 'Are you sure you want to delete the registry key?',
    DeletePropmtDescription:
      'This action is irreversible. If you delete the key, it cannot be recovered.',
    DeleteError: 'Error deleting key',
    'Can not delete.': 'The key contains filled data.',
    Access: 'Access',
    EditKeyAccess: 'Edit key access rights',
    ReguiredFiled: 'Required Field',
    KeyNameExists: 'A key with this name already exists in this registry',
    FieldDescriptionText: 'Field Description',
    SignatureDetails: 'Signature Details'
  },
  WorkflowLogs: {
    CreatedAt: 'Date',
    WorkflowLogType: 'Type',
    WorkflowLogDetails: 'Details',
    WorkflowLogWarnings: 'Errors',
    Task: 'Task',
    WorkflowIncomingMessage: 'Incoming Message',
    WorkflowOutgoingMessage: 'Outgoing Message',
    Gateway: 'Gateway'
  },
  ProcessesListPage: {
    SearchLogs: 'Search Logs',
    Preview: 'Preview',
    DownloadP7S: 'Download P7S signature',
    JournalListPageTitle: 'Process Logs',
    ProcessesListPageTitle: 'Process Logs',
    WorkflowNumber: 'Process Number',
    WorkflowTemplateCategory: 'Category',
    WorkflowTemplate: 'Process Name',
    WorkflowTemplateId: 'Process Template Number',
    UserName: 'User',
    Loading: 'Loading...',
    ProcessListPageTitle: 'Logs for {{number}} {{name}}',
    ProcessTitle: 'Process Logs',
    CreatedAt: 'Created Date',
    UpdatedAt: 'Update Date',
    WorkflowLogType: 'Type',
    WorkflowLogDetails: 'Details',
    WorkflowLogWarnings: 'Errors',
    WorkflowLogDebug: 'Debug mode',
    Task: 'Task',
    WorkflowIncomingMessage: 'Incoming Message',
    WorkflowOutgoingMessage: 'Outgoing Message',
    Gateway: 'Gateway',
    Event: 'Event',
    BackButton: 'To list',
    Error: 'Error',
    RestartProcessButton: 'Restart process after error',
    Save: 'Save',
    Cancel: 'Cancel',
    FinishedTask: 'Processed task',
    IsFinalDocument: 'Final Document',
    DoneTask: 'Done task',
    DocumentData: 'Document Data',
    DocumentSettings: 'Settings',
    WorkflowStatusLabel: 'Status',
    RestartProcessFromPoint: 'Restart process from this point',
    RestartProcessConfirmation:
      'Are you sure you want to restart the process from the current location {{point}}?',
    RestartProcess: 'Restart',
    Restart: 'Restarted',
    FailRestartingWorkflowProcessFromPoint:
      'An error occurred while restarting the process from this point',
    ShowingProcesses: 'Errors',
    HasError: 'Unresolved error',
    Doing: 'In process',
    Done: 'Done',
    Rejected: 'Rejected',
    NoStatus: 'Status not set',
    IsFinal: 'Finished',
    DraftStatus: 'Draft',
    DraftStatusLabel: 'Draft Filtering',
    Actions: 'Actions',
    Signature: 'Signature information',
    AttachmentList: 'Attached files',
    PDF: 'Generated document',
    ShowMessages: 'Show intermediate steps',
    ShowMessagesTooltip: 'select to restart the process from a specific location',
    SearchLabel: 'Search',
    ActionsWithProcess: 'Actions with process',
    CheckAsNotError: 'Mark as "No Error"',
    CheckAsNotErrorTitle: 'Are you sure you want to mark the process as "No Error"?',
    CheckAsNotErrorDescription:
      'This action is irreversible. If you mark the process as "No Error", it will not be able to be recovered.',
    isFinal: 'Process complete',
    isNotFinal: 'Process not completed',
    Warning: 'Error',
    WorkflowOldVersionErrorDetailed: 'The last changes {{time}} were made by {{person}}',
    Execute: 'Execute',
    CheckSchema: 'Check Schema',
    SignatureList: 'Signature List',
    Close: 'Close',
    Yes: 'Yes',
    No: 'No',
    Download: 'Download',
    RestartProcessTooltip: 'Restart selected processes',
    RestartPrompt: 'Restart processes',
    RestartPropmtDescription: 'Selected processes will be restarted',
    RestartSuccessSuccess: 'Restart complete',
    ErrorRestart: 'An error occurred while restarting',
    WorkflowTemplatePlaceholder: 'Process',
    WorkflowNumberPlaceholder: 'Number',
    ShowingProcessesPlaceholder: 'Errors',
    UserNamePlaceholder: 'User',
    WorkflowStatusPlaceholder: 'Search by status',
    CreatedDate: 'Created Date',
    UpdatedDate: 'Update Date',
    ProcessNamePlaceholder: 'Search by process name',
    userID: 'user id'
  },
  UserAccessJournalPage: {
    UserAccessJournal: 'Role Changes',
    OperationType: 'Operation',
    CreatedAt: 'Effective date and time',
    InitUserName: 'Initiator',
    InitUserId: 'Initiator ID',
    InitIpn: 'Initiator PIN',
    InitWorkflowId: 'Add Method',
    Workflow: 'Process',
    UserName: 'User',
    UserId: 'User ID',
    Ipn: 'User ID',
    UnitName: 'Unit',
    UnitId: 'Unit ID',
    FromShort: 'From',
    ToShort: 'To',
    AddedTo: 'Added to',
    DeletedFrom: 'Deleted from',
    Heads: 'leaders',
    Members: 'subdivision',
    System: 'System',
    'added-to-member-unit': 'Added to member-unit',
    'deleted-from-member-unit': 'Deleted from member-unit',
    'added-to-head-unit': 'Added the role of Head of Unit',
    'deleted-from-head-unit': 'Head of unit role removed',
    'added-to-admin': 'Granted access to admin panel',
    'deleted-from-admin': 'Access to the admin panel has been removed',
    ByAdmin: 'By admin'
  },
  UserLoginJournal: {
    UserLoginJournal: 'Authorizations',
    Id: 'Identifier',
    CreatedAt: 'Date and Time',
    UserId: 'User ID',
    UserName: 'User Name',
    Ip: 'IP address',
    UserAgent: 'UserAgent',
    FromShort: 'From',
    ToShort: 'To',
    ClientName: 'Service',
    ClientId: 'Service ID',
    ActionType: 'Action type',
    SessionTime: 'Session time',
    UserOperations: 'Operations with users',
    createdBy: 'Initiator'
  },
  UserProcessesListPage: {
    UserProcessesListPageTitle: 'System Actions',
    From: 'From date',
    To: 'By date',
    CreatedAt: 'Effective date and time',
    UserName: 'User Name',
    UserId: 'User ID',
    DocumentId: 'Identifier',
    RequestId: 'Process Number',
    Type: 'Action',
    Ip: 'IP address',
    Party: 'Party',
    PartyID: 'Party ID',
    RegionParty: 'Reg. office',
    RegionPartyID: 'ID Reg. office',
    Loading: 'Loading...',
    ExportReportToPdf: 'Export',
    Cancel: 'Reject',
    EmptyFiltersTitle: 'Attention',
    EmptyFilters: 'To generate the report, please select filters',
    Ok: 'OK',
    FromShort: 'From',
    ToShort: 'By'
  },
  SelectFilterHandler: {
    doing: 'In progress',
    done: 'Done',
    rejected: 'Rejected',
    withErrors: 'Only with errors',
    withoutErrors: 'Without Errors',
    isDraft: 'Draft',
    EmptyList: 'The list is empty'
  },
  SelectUserDialog: {
    Search: 'Search',
    AddUsers: 'Add users',
    FinishEditing: 'Finish Editing',
    EmptySelectedUsers: 'The list of users is empty',
    EmptySearchResults: '',
    SearchResults: 'Search results:',
    SearchByName: 'by name',
    SearchByIPN: 'by ITN',
    SearchByID: 'by ID'
  },
  AdminTools: {
    SelectDebugUser: 'Login as...'
  },
  NumberTemplateListPage: {
    TemplateId: 'Template Number',
    CreateNew: 'Create',
    DeleteTemplate: 'Delete',
    EditTemplate: 'Edit',
    EditTemplateData: 'Edit Template',
    NewTemplate: 'New Template',
    NumberTemplateList: 'Number Templates',
    NumberTemplateName: 'Template Name',
    CreatedAt: 'Created Date',
    UpdatedAt: 'Update Date',
    CreateNewTemplate: 'New Template',
    DeleteSelectedTemplates: 'Delete',
    DeleteTemplateDialogTitle: 'Delete confirmation',
    DeleteTemplateDialogDescription: 'Are you sure you want to delete the selected template?',
    ImportTemplates: 'Import',
    DeleteTemplates: 'Delete Number Templates',
    DeleteTemplatesDialogDescription: 'Are you sure you want to delete the selected templates?',
    Actions: 'Actions'
  },
  NumberTemplatePage: {
    Loading: 'Loading...',
    Back: 'Return to list',
    Save: 'Save changes'
  },
  UserListPage: {
    Blocked: 'Blocked',
    Status: 'Status',
    UserListName: 'FIRSTNAME',
    Actions: 'Actions',
    ID: 'Identifier',
    DeleteUser: 'Delete User',
    DeleteUserPrompt: 'Are you sure you want to delete the user?',
    Users: 'Users',
    UserList: 'Users',
    Name: 'Name',
    IPN: 'IPN',
    RNOKPP: 'ITN',
    Phone: 'Phone',
    Email: 'E-mail address',
    CreatedAt: 'Created Date',
    UserId: 'User ID',
    Administrator: 'Administrator',
    BlockUser: 'Block',
    Delete: 'Delete',
    AddUnitUser: 'Add User',
    UnblockUser: 'Unblock',
    SelectUserUnitsDialog: 'Select Units',
    UserUnits: 'Units',
    UnitMember: 'Belongs to units',
    UnitHead: 'Head of Units',
    Cancel: 'Cancel',
    UnActive: 'Inactive',
    WrongUserName: 'Error in first name',
    Save: 'Save',
    OK: 'OK',
    OnlyAdministrator: 'Only administrators',
    SetAdmin: 'Make Admin',
    UnsetAdmin: 'Remove administrative rights',
    UserCertInfo: 'Certificate Info',
    PemNotFound: 'User certificate not found',
    DialogTitle: 'Attention!',
    ErrorAddingUser: 'Error adding user',
    SetAdminTitle: 'Grant admin rights?',
    UnsetSetAdminTitle: 'Remove admin rights?',
    UserAccessHistory: 'History of access rights',
    UserAccessJournal: 'Edited access rights',
    UserWorkflowJournal: 'Processes',
    UserProcesses: 'Documents',
    'Requested member already in unit by ID.': 'The user is already present.',
    'Requested member with wrong name.':
      'The user with such RNOCPP has a different name specified in the electronic signature certificate, check the entered data',
    'Unit exclusive rules error!': 'The selected units are incompatible',
    DeleteUserPromtDescription:
      'This action is irreversible. If you delete a user, it will not be possible to restore it.',
    rnokpp: 'ITN',
    DismatchRnokpp: "The entered ITN does not match the user's ITN",
    EmptyRnokpp: 'Need to enter ITN',
    ...WorkflowProcesses
  },
  HealthCheckListPage: {
    HealthCheckListPage: 'Service Statuses',
    HealthCheckListTitle: 'Components',
    status: 'Status',
    name: 'Name',
    details: 'Details',
    version: 'Version',
    message: 'Message',
    processPid: 'processPid',
    authResponse: 'auth',
    notifyResponse: 'notify',
    edsServiceResponse: 'eds',
    registerServiceResponse: 'registerService',
    fileStorageServiceResponse: 'fileStorage',
    correctNotifyConnection: 'correctNotify',
    correctEdsConnection: 'correctEds',
    smsNotifierResponse: 'smsNotifier',
    emailNotifierResponse: 'emailNotifier',
    registerResponse: 'register',
    persistLinkResponse: 'persistLink',
    taskServiceResponse: 'taskService',
    previewServiceResponse: 'previewService',
    oauthResponse: 'oauth',
    correctAuthServiceConnection: 'correctAuthService',
    correctPeristLinkConnection: 'correctPeristLink',
    authMeta: 'authMeta',
    notifyMeta: 'notifyMeta',
    edsMeta: 'edsMeta',
    managerServiceResponse: 'managerServiceResponse',
    customer: 'customer',
    environment: 'environment',
    smsNotifierMeta: 'smsNotifierMeta',
    emailNotifierMeta: 'emailNotifierMeta',
    registerMeta: 'registerMeta',
    pLinkResponse: 'pLinkResponse',
    pLinkMeta: 'pLinkMeta',
    filestorageResponse: 'filestorageResponse',
    filestorageMeta: 'filestorageMeta',
    trembitaResponse: 'trembitaResponse',
    digestNotifierResponse: 'digestNotifierResponse',
    authServiceResponse: 'authServiceResponse',
    registerServiceMeta: 'registerServiceMeta',
    taskServiceMeta: 'taskServiceMeta',
    previewServiceMeta: 'previewServiceMeta',
    oauthServiceMeta: 'oauthServiceMeta'
  },
  JsonSchemaEditor: {
    Search: 'Search...',
    CollapseSnippets: 'Helper Functions',
    CollapseElements: 'Controls',
    VisualEditor: 'Scheme',
    CodeEditor: 'Code',
    FormPreview: 'Preview',
    Step: 'Step',
    ObjectElement: 'Container',
    StringElement: 'TextBox',
    Components: 'Components',
    NewElementId: 'Enter the key of the new element',
    Cancel: 'Cancel',
    Save: 'Save',
    Properties: 'Properties',
    Containers: 'Containers',
    TextFields: 'Text fields',
    Email: 'E-mail address',
    ElementBlocks: 'Blocks',
    Name: 'My name',
    LastName: 'Last Name',
    BirthDate: 'BirthDate',
    Pib: 'Name',
    Ipn: 'ITN',
    Phone: 'Phone',
    Passport: 'Passport',
    Edrpoy: 'EDRPOU',
    Select: 'Dropdown List',
    SelectFiles: 'Select Files',
    Toggle: 'Switch',
    Textarea: 'Text Editor',
    Date: 'Date',
    CheckboxGroup: 'Checkboxes',
    DynamicCheckboxGroup: 'Dynamic check boxes',
    RadioGroup: 'Radio Buttons',
    DynamicRadioGroup: 'Dynamic Radio Buttons',
    Register: 'List from the register',
    RegisterRelated: 'Register related list',
    TextBlock: 'Arbitrary text',
    TreeSelect: 'Tree List',
    RelatedSelects: 'Related Lists',
    PreviewDocument: 'Preview Files',
    FormGroup: 'Element Group',
    Popup: 'Popup',
    Edrpou: 'EDRPOU',
    Unzr: 'UNZR',
    Iban: 'IBAN',
    Atu: 'Address',
    Citizen: 'Citizen',
    PassportIssurer: 'Issued by',
    PassportIssueDate: 'Issue Date',
    Basic: 'Main',
    ExistedElementId: 'Attention',
    ExistedElementWarning: 'An element with this key already exists, replace it?',
    Change: 'Replace',
    Payment: 'Payment',
    RegisterSelect: 'Tree Register',
    CalculateButton: 'Calculate Button',
    RegistrySearch: 'Registry with search',
    Table: 'Table',
    RegisterTable: 'Tables of data from the register',
    Spreadsheet: 'Electronic Spreadsheet',
    Modal: 'Modal Window',
    ArrayElement: 'Array',
    CustomDataSelect: 'Dropdown list (custom)',
    UnitSelect: 'Unit Dropdown List',
    SignerList: 'List of signers',
    Collapse: 'Collapse'
  },
  UserIdsFilterHandler: {
    Search: 'Search',
    SearchResults: 'Search results:',
    EmptySelection: 'No users selected',
    EmptySearchResults: 'No users found',
    Apply: 'Apply',
    Others: 'more {{length}}...'
  },
  WorkflowProcesses,
  StringFilterHandlerSearchable: {
    loadingText: 'Loading...',
    noOptionsText: 'Not found',
    openText: 'Open',
    placeholder: 'Full name, ITN or user ID'
  },
  UIFilterList: {
    UIFilters: 'Cabinet Sections',
    NewUiFilter: 'Create',
    EditUiFilter: 'Edit',
    FilterSettings: 'Section Settings',
    Filter: 'Filter',
    Name: 'Name',
    Delete: 'Delete',
    Save: 'Save',
    DeletePrompt: 'Delete confirmation',
    DeletePropmtDescription: 'Are you sure you want to delete the partition settings?',
    Error: 'Error',
    Cancel: 'Cancel',
    'Invalid value': 'Invalid value'
  },
  InterfacesList: {
    CustomInterfaces: 'Interfaces',
    NewInterface: 'Create',
    EditInterface: 'Edit',
    InterfaceSettings: 'Interface Settings',
    Name: 'Name',
    Route: 'Route',
    InterfaceScheme: 'Interface Scheme',
    Delete: 'Delete',
    Save: 'Save',
    DeletePrompt: 'Delete confirmation',
    DeletePromtDescription: 'Are you sure you want to delete the {{name}} interface?',
    Error: 'Error',
    Cancel: 'Cancel',
    'Invalid value': 'Invalid value',
    Actions: 'Actions'
  },
  ReportListPage: {
    Parameters: 'Parameters',
    ErrorSavingReport: 'Error saving report',
    SqlQuery: 'SQL Query',
    PdfTemplate: 'PDF Template',
    Filters: 'Filters:',
    Name: 'Name',
    Render: 'Render',
    RenderReport: 'Render Report',
    TemplateName: 'Template Name',
    Edit: 'Edit',
    Rename: 'Rename',
    DraftName: 'Data Source',
    CreatedAt: 'Created Date',
    CreatedBy: 'Created By',
    UpdatedAt: 'Update Date',
    Status: 'Status',
    UpdatedBy: 'Updated by',
    Reports: 'Reports',
    CreateNew: 'Create',
    Delete: 'Delete',
    CreateNewReport: 'Create a new report',
    EditReportDialog: 'Edit Report',
    ReportName: 'Name',
    Close: 'Close',
    Save: 'Save',
    DeletePrompt: 'Deleting Report',
    DeletePropmtDescription: 'Are you sure you want to delete the report?',
    EditReport: 'Edit: {{name}}',
    Preview: 'Preview',
    ReportDraft: 'Based on',
    Rendering: 'Report generation, please wait...',
    RenderProcessSuccessed: 'Report based on template created.',
    ResultDialog: 'Message',
    OK: 'Good',
    ReportTemplates: 'Report Templates',
    SuccessStatus: 'Created',
    FailedStatus: 'Failed',
    InProgressStatus: 'In Progress',
    UnitMember: 'Units with access',
    Access: 'Access'
  },
  DiiaSignForm: {
    DiiaSignFormTitle: 'Diia App Signature',
    DiiaSignFormDescription: 'Scan the QR code with the Diia app and follow the instructions',
    DiiaSignFormDescriptionMobile: 'Click the button and follow the instructions',
    DiiaSignFormLoading: 'Loading signature data...',
    PreparingDeeplink: 'Generating QR code for signature...',
    CloseDialog: 'Close',
    CloseDialog2: 'Understood',
    DiiaSignFormError: 'An error occurred while forming the signature',
    Retry: 'Retry',
    RenewCode: 'Renew Code',
    DiiaSignFormSuccess: 'Signature applied successfully',
    SignDataError: 'Error',
    Processing: 'Processing signature, please wait...',
    RenewQR: 'Renew QR Code',
    SigningDataError: 'Error',
    OpenQrButton: 'Open application Action for signature',
    "Ipn from sign certificate not match user's ipn.":
      "IPn from sign certificate not match user's ipn.",
    QrTimer: 'This code will run for another {{timerValue}}',
    QrTimerRiched:
      'This code is no longer active, please click the "Refresh QR Code" button and scan it again'
  },
  EnabledMockPage: {
    EnabledMockListTitle: 'Mocks',
    TurnOffMock: 'Turn Off Mock',
    TurnOnMock: 'Turn On Mock',
    TurnOffUserMock: 'Turn off user mock',
    TurnOnUserMock: 'Turn on User Mock',
    Edit: 'Edit mock',
    EditUser: 'Edit Mock User',
    NoMocksFound: 'No mocks found',
    AddProviderTitle: 'Add new provider',
    Cancel: 'Cancel',
    Save: 'Add',
    ProviderName: 'Name',
    ProviderDescription: 'Description',
    RequiredField: 'Required field',
    AddProvider: 'Add Provider',
    Delete: 'Delete',
    AddProviderMethod: 'Add Mock',
    AddProviderMethodTitle: 'Create New Mock',
    DeleteProvider: 'Delete Provider',
    SuccessCreate: 'Successfully created',
    ErrorCreate: 'Error creating:',
    DeleteProviderDescription:
      'Really delete {{provider}}? The provider will be permanently deleted, it will no longer be possible to restore it.',
    DeleteMock: 'Delete mock',
    DeleteMockDescription:
      'Really delete {{mock}}? Mok will be permanently deleted, it will no longer be possible to restore it.',
    actions: 'Actions',
    name: 'Name',
    description: 'Description',
    state: 'State'
  },
  MessageTemplatesList: {
    MessageTemplatesList: 'Message Templates',
    EditTemplate: 'Edit',
    Create: 'Add New',
    template_id: 'Identifier',
    type: 'Type',
    text: 'Text',
    title: 'Title',
    DeleteTemplate: 'Delete',
    DeletePrompt: 'Delete confirmation',
    DeletePromtDescription:
      'Are you sure you want to delete the template "{{title}}"? This action is irreversible. If you delete a template, it will not be possible to restore it.',
    Actions: 'Actions'
  },
  MetricsPage: {
    MetricsPageTitle: 'Metrics',
    MetricsIntegrationTitle: 'Integration metrics',
    MetricsProcessesTitle: 'Process Metrics',
    name: 'Name',
    lintTo: 'view',
    TotalTasks: 'Submitted',
    Errors: 'Errors',
    ForOneHour: 'For 1 hour',
    ForOneDay: 'For 1 day',
    ForAllTime: 'For the entire period',
    ErrorsLeft: 'Errors left',
    dateFrom: 'From',
    dateTo: 'By',
    Cancel: 'Close',
    clearLabel: 'Clear',
    Search: 'Search',
    nameProcesses: 'Process Name',
    NoData: 'No records found for "{{search}}"'
  },
  FavoritesPage: {
    FavoritesListTitle: 'Favorites',
    entity_id: 'Identifier',
    entity_type: 'Type',
    entity_name: 'Name',
    createdAt: 'Added',
    Delete: 'Delete',
    actions: 'Actions',
    RemoveFromFavorites: 'Remove from favorites',
    AddToFavorites: 'Add to Favorites',
    DeletePrompt: 'Delete confirmation',
    DeletePromtDescription:
      'Are you sure you want to delete "{{title}}" from your favorites? It can be returned later.'
  }
};
