import React from 'react';
import { translate } from 'react-translate';

import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';

import { retryOperation } from 'helpers/retryOperation';
import base64toUint8Array from 'helpers/base64toUint8Array';

import SigningDialog from 'components/EDSForm/SigningDialog';
import formElement from 'components/JsonSchema/components/formElement';

import edsService from 'services/eds';

const CONST_SIGN = 'UA1_SIGN\0';
const CONST_CRYPT = 'UA1_CRYPT\0';
const CONST_CERTCRYPT = 'CERTCRYPT\0';

const CONST_SIGN_B = Buffer.from(CONST_SIGN, 'ascii');
const CONST_CRYPT_B = Buffer.from(CONST_CRYPT, 'ascii');
const CONST_CERTCRYPT_B = Buffer.from(CONST_CERTCRYPT, 'ascii');

const steps = {
  ALL: 0,
  FIRST: 1,
  SECOND: 2
};

const SignEncryptSignDialog = ({
  t,
  value = {},
  onChange,
  errors,
  schema: {
    description = 'Дані для шифрування',
    retryConfig = {
      check: '(value) => { return true; }',
      delay: 250,
      attempts: 0
    },
    diiaSign = true,
    cryptCerts,
    buttonText,
    dialogTitle,
    dialogButtonText,
    successMessage
  }
}) => {
  const signer = edsService.getSigner();

  const [open, setOpen] = React.useState(false);
  const [firstStepResult, setFirstStepResult] = React.useState();
  const { dataToEncrypt, encryptedData } = value;

  const encryptFunc = React.useCallback(
    async (signature, step = steps.ALL) => {
      let data, sign1, cryptCert, crypt;
      if (!step || step === steps.FIRST) {
        sign1 = Buffer.from(signature, 'base64');
        let sign1Length = Buffer.alloc(4);
        sign1Length.writeInt32LE(sign1.length);
        data = Buffer.concat(
          [CONST_SIGN_B, sign1Length, sign1],
          CONST_SIGN.length + 4 + sign1.length
        );
        const cryptedData = await signer.execute(
          'EnvelopDataToRecipientsWithDynamicKey',
          cryptCerts.map((cert) => Buffer.from(cert, 'base64')),
          false,
          false,
          new Uint8Array(data.buffer),
          true
        );

        cryptCert = Buffer.from(cryptCerts[0], 'base64');

        crypt = Buffer.from(cryptedData, 'base64');
        let cerCryptLength = Buffer.alloc(4);
        cerCryptLength.writeInt32LE(cryptCert.length);
        let cryptLength = Buffer.alloc(4);
        cryptLength.writeInt32LE(crypt.length);

        data = Buffer.concat(
          [CONST_CERTCRYPT_B, cerCryptLength, cryptCert, CONST_CRYPT_B, cryptLength, crypt],
          CONST_CERTCRYPT.length + 4 + cryptCert.length + CONST_CRYPT.length + 4 + crypt.length
        );

        if (step === steps.FIRST) {
          return { data, sign1, cryptCert, crypt };
        }
      }

      const sign2test =
        step === steps.SECOND ? signature : await signer.execute('SignData', data, true);

      let sign2 = Buffer.from(sign2test, 'base64');
      let sign2Length = Buffer.alloc(4);
      sign2Length.writeInt32LE(sign2.length);
      data = Buffer.concat(
        [CONST_SIGN_B, sign2Length, sign2],
        CONST_SIGN.length + 4 + sign2.length
      );

      return {
        encryptedData: data.toString('base64'),
        meta: {
          signLength: (firstStepResult?.sign1 || sign1).length,
          cryptCertLength: (firstStepResult?.cryptCert || cryptCert).length,
          cryptLength: (firstStepResult?.crypt || crypt).length
        }
      };
    },
    [cryptCerts, signer, firstStepResult]
  );

  const onSelectKey = React.useCallback(
    async (encryptedKey, signer, resetPrivateKey) => {
      await signer.execute('SetRuntimeParameter', 'SignType', 1);
      const signature = await signer.execute(
        'SignData',
        Buffer.from(dataToEncrypt, 'base64'),
        true
      );

      const result = await retryOperation(
        encryptFunc,
        [signature],
        retryConfig.check,
        retryConfig.delay,
        retryConfig.attempts
      );

      await signer.execute('SetRuntimeParameter', 'SignType', 16 | 128); // EU_SIGN_TYPE_CADES_X_LONG | EU_SIGN_TYPE_CADES_X_LONG_TRUSTED

      onChange({ ...result, dataToEncrypt });
      setOpen(false);
      resetPrivateKey();
    },
    [
      dataToEncrypt,
      encryptFunc,
      onChange,
      retryConfig.attempts,
      retryConfig.check,
      retryConfig.delay
    ]
  );

  const onSignHash1 = React.useCallback(
    async ([{ signature }]) => {
      await signer.execute('SetRuntimeParameter', 'SignType', 1);

      const result = await retryOperation(
        encryptFunc,
        [signature, steps.FIRST],
        retryConfig.check,
        retryConfig.delay,
        retryConfig.attempts
      );

      await signer.execute('SetRuntimeParameter', 'SignType', 16 | 128); // EU_SIGN_TYPE_CADES_X_LONG | EU_SIGN_TYPE_CADES_X_LONG_TRUSTED

      setOpen(false);
      setFirstStepResult(result);
    },
    [signer, encryptFunc, retryConfig.attempts, retryConfig.check, retryConfig.delay]
  );

  const onSignHash2 = React.useCallback(
    async ([{ signature }]) => {
      await signer.execute('SetRuntimeParameter', 'SignType', 1);

      const result = await retryOperation(
        encryptFunc,
        [signature, steps.SECOND],
        retryConfig.check,
        retryConfig.delay,
        retryConfig.attempts
      );

      await signer.execute('SetRuntimeParameter', 'SignType', 16 | 128); // EU_SIGN_TYPE_CADES_X_LONG | EU_SIGN_TYPE_CADES_X_LONG_TRUSTED
      onChange({ ...result, dataToEncrypt });
      setFirstStepResult();
    },
    [
      signer,
      dataToEncrypt,
      encryptFunc,
      onChange,
      retryConfig.attempts,
      retryConfig.check,
      retryConfig.delay
    ]
  );

  if (!Array.isArray(cryptCerts) || !cryptCerts.filter(Boolean).length) {
    return <Alert severity="error">{t('NoEncryptCertsPassedError')}</Alert>;
  }

  if (encryptedData && !errors.length) {
    return <Alert severity="success">{successMessage || t('SuccessMessage')}</Alert>;
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        disabled={!dataToEncrypt}
        onClick={() => setOpen(true)}
      >
        {buttonText || t('ButtonText')}
      </Button>
      <SigningDialog
        open={open}
        diiaSign={diiaSign}
        onSelectKey={onSelectKey}
        onClose={() => setOpen(false)}
        title={dialogTitle || t('DialogTitle')}
        onSignHash={onSignHash1}
        getDataToSign={() => [
          {
            data: base64toUint8Array(dataToEncrypt),
            name: description,
            internal: true
          }
        ]}
        readPrivateKeyText={dialogButtonText || t('DialogButtonText')}
      />
      <SigningDialog
        open={!!firstStepResult}
        diiaSign={true}
        onClose={() => setFirstStepResult()}
        title={dialogTitle || t('DialogTitle')}
        onSignHash={onSignHash2}
        getDataToSign={() => [
          {
            data: base64toUint8Array(dataToEncrypt),
            name: description,
            internal: true
          }
        ]}
        readPrivateKeyText={dialogButtonText || t('DialogButtonText')}
      />
    </>
  );
};

const translated = translate('SignEncryptSignDialog')(SignEncryptSignDialog);
export default formElement(translated);
