/* eslint-disable react/prop-types */
import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Typography, Grid, Toolbar, Hidden } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import LogoWhite from 'assets/img/logo_white.png';
import SocialLinks from '../components/SocialLinks';

const styles = (theme) => ({
  item: {
    color: '#ECE9E5',
    opacity: 0.7,
    marginBottom: 10,
    '&:hover': {
      opacity: 1
    },
    [theme.breakpoints.down('sm')]: {
      color: '#fff',
      opacity: 1,
      fontSize: 13,
      lineHeight: '18px',
      maxWidth: 100
    }
  },
  link: {
    textDecoration: 'none',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  title: {
    color: '#ECE9E5'
  },
  toolbar: {
    padding: 0
  },
  socialTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      maxWidth: 100,
      lineHeight: '18px',
      float: 'left',
      marginTop: 5
    }
  },
  logoRaw: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 50,
      height: 50,
      marginRight: 10
    },
    '& span': {
      color: '#ECE9E5',
      fontSize: 26,
      lineHeight: '20px',
      letterSpacing: '-0.02em',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      }
    }
  },
  contacts: {
    '& div:first-child': {
      marginTop: 20
    },
    '& div': {
      marginBottom: 10,
      '& a': {
        color: '#fff'
      }
    }
  },
  addressBlock: {
    marginTop: 20,
  }
});

const SecondaryBottomMenu = ({ t, classes, copyringBlock }) => {
  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} sm={12} md={6}>
        <Grid container={true} spacing={1} style={{ height: '100%' }}>
          <div className={classes.logoRaw}>
            <img src={LogoWhite} alt="Міністерство у справах ветеранів України" />
            <span>Міністерство у справах ветеранів України</span>
          </div>
          <div className={classes.addressBlock}>01001, м. Київ, пров. Музейний, 12</div>
          {copyringBlock}
        </Grid>
      </Grid>
      <Grid item={true} sm={12} md={6}>
        <Grid container={true} spacing={1}>
          <Hidden lgDown={true}>
            <Grid item={true} sm={12} md={6} />
          </Hidden>
          <Grid item={true} sm={12} md={6}>
            <Typography variant="body1" className={classNames(classes.title, classes.socialTitle)}>
              {t('Subscribes')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              <SocialLinks />
            </Toolbar>

            <div className={classes.contacts}>
              <div>Електронна пошта:</div>
              <div><a href='mailto:control@mva.gov.ua'>control@mva.gov.ua</a></div>
              <div>Телефон для довідок:</div>
              <div><a href='tel:0630354092'>(063) 035-40-92</a></div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ octoberFooter: { items } }) => ({ items });

const styled = withStyles(styles)(SecondaryBottomMenu);
const translated = translate('Layout')(styled);
export default connect(mapStateToProps)(translated);
