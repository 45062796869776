import React from 'react';
import ConfirmDialog from 'components/ConfirmDialog';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { history } from 'store';

const handleConfirmDialog = () => history.push('/profile/email');

const ForbiddenMail = ({ t, userInfo }) => {
  const pathname = history?.location?.pathname;
  const email = userInfo?.email;
  const domain = email?.substr(email.length - 2, 2)?.toLowerCase();
  const openModal = (domain === 'ru' || domain === 'su') && pathname !== '/profile/email';

  return (
    <ConfirmDialog
      fullScreen={false}
      open={openModal}
      title={t('ForbiddenTitle')}
      description={t('ForbiddenText')}
      acceptButtonText={t('ForbiddenButton')}
      handleConfirm={handleConfirmDialog}
    />
  );
};

ForbiddenMail.propTypes = {
  t: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth: { info } }) => ({ userInfo: info });
const translated = translate('Forbidden')(ForbiddenMail);
const connected = connect(mapStateToProps)(translated);
export default connected;
