import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import diiaLogo from 'assets/img/diia-logo.svg';
import uaSign from 'assets/img/uasign.svg';
import storage from 'helpers/storage';
import qs from 'qs';

const styles = (theme) => ({
  header: {
    padding: 20,
    background: '#fff',
    color: '#000',
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 8
    }
  },
  button: {
    lineHeight: '3em',
    color: '#000',
    cursor: 'pointer'
  },
  link: {
    color: '#000000',
    textDecoration: 'none',
    '&:hover span': {
      borderBottom: '1px solid #000'
    }
  },
  linkLogo: {
    color: '#000000',
    textDecoration: 'none',
    display: 'flex'
  },
  linkText: {
    verticalAlign: 'super',
    marginLeft: 8,
    transition: '.2s ease-in-out',
    borderBottom: '1px solid transparent',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  imageSrc: {
    width: 48,
    height: 48,
    display: 'block',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 32,
      height: 32
    },
    '&:nth-child(2)': {
      marginLeft: 8
    }
  },
  toolbar: {
    padding: '0 4px',
    alignItems: 'start',
    minHeight: 'auto',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
    '& > *': {
      marginRight: 8
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  whiteSm: {
    flexGrow: 1
  },
  divider: {
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 7
    }
  },
  iconContainer: {
    width: 48,
    height: 48,
    borderBottom: '1px solid transparent!important',
    [theme.breakpoints.down('sm')]: {
      width: 32,
      height: 32,
      alignItems: 'center',
      display: 'flex'
    }
  }
});

const Header = ({ classes, t, location, backLink, backLinkText }) => {
  React.useEffect(() => {
    if (!location?.search) return;
    storage.setItem('backLink', location?.search);
  }, [location]);

  const getBackLink = () => {
    try {
      const querryLink = (
        qs.parse(storage.getItem('backLink') || location?.search, {
          ignoreQueryPrefix: true
        }) || {}
      )?.backLink;

      return querryLink ? '/' + querryLink : backLink;
    } catch {
      return backLink;
    }
  };

  return (
    <AppBar className={classes.header} position="relative" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Link
          to={getBackLink()}
          onClick={() => storage.removeItem('backLink')}
          className={classes.link}
        >
          <Typography className={classes.button}>
            <span className={classes.iconContainer}>
              <ArrowBackIcon />
            </span>
            <span className={classes.linkText}>{backLinkText || t('BackToMain')}</span>
          </Typography>
        </Link>
        <span className={classes.whiteSm} />
        <a
          href="https://diia.gov.ua/"
          className={classes.linkLogo}
          rel="noopener noreferrer"
          aria-label={t('LabelLogoDiia')}
        >
          <span
            className={classNames(classes.button, classes.imageSrc)}
            style={{ backgroundImage: `url(${uaSign})` }}
          />
          <span
            className={classNames(classes.button, classes.imageSrc)}
            style={{ backgroundImage: `url(${diiaLogo})` }}
          />
        </a>
      </Toolbar>
      <Divider className={classes.divider} />
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  backLink: PropTypes.string,
  backLinkText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Header.defaultProps = {
  backLink: '/',
  backLinkText: false
};

const mapStateToProps = ({ auth: { userUnits, info } }) => ({ userUnits, userInfo: info });

const translated = translate('Navigator')(Header);

const connected = connect(mapStateToProps)(translated);

export default withStyles(styles)(connected);
