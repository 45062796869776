import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Paper, Typography, Divider, ClickAwayListener, Fade } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TopSubMenu from './TopSubMenu';

const styles = (theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    margin: '0 16px',
    fontSize: 13,
    lineHeight: '20px',
    cursor: 'pointer',
    alignItems: 'flex-end',
    top: 1,
    position: 'relative',
    '&:hover': {
      textDecoration: 'underline'
    },
    [theme.breakpoints.down('md')]: {
      top: 0,
      margin: 0,
      marginBottom: 0,
      alignItems: 'self-start',
      justifyContent: 'space-between',
      minHeight: 36
    }
  },
  menuContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 80,
    background: '#e7eef3',
    padding: '0 32px 32px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      padding: 0,
      boxShadow: 'none'
    },
    zIndex: 10
  },
  divider: {
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  iconWrapper: {
    '& > svg': {
      fontSize: 13,
      top: 2,
      position: 'relative',
      width: 13,
      [theme.breakpoints.down('md')]: {
        width: 24
      }
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      right: -6
    }
  },
  iconRotate: {
    '& > svg': {
      transform: 'rotate(180deg)'
    }
  }
});

const ExpandedMenuItem = ({ classes, title, closeButton, children }) => {
  const [openModile, setOpenMobile] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const isMobile = window.innerWidth < 960;
  const toggleMenu = () => (isMobile ? setOpenMobile(!openModile) : setOpen(!open));
  const isOpen = isMobile ? openModile : open;

  return (
    <>
      <Typography variant="body1" className={classes.link} onClick={toggleMenu}>
        {title}
        <span className={classNames(classes.iconWrapper, isOpen && classes.iconRotate)}>
          <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
            <path d="M7 10l5 5 5-5z" />
          </svg>
        </span>
      </Typography>
      {isOpen ? (
        <ClickAwayListener onClickAway={toggleMenu}>
          <Fade in={isOpen}>
            <Paper elevation={0} className={classes.menuContainer}>
              <Divider className={classes.divider} />
              <TopSubMenu children={children} closeButton={closeButton} />
            </Paper>
          </Fade>
        </ClickAwayListener>
      ) : null}
    </>
  );
};

ExpandedMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  closeButton: PropTypes.node,
  title: PropTypes.string
};

ExpandedMenuItem.defaultProps = {
  title: '',
  closeButton: null
};

export default withStyles(styles)(ExpandedMenuItem);
