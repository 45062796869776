import React from 'react';
import { translate } from 'react-translate';
// import { Link } from 'react-router-dom';

import {
  Grid,
  Typography,
  Hidden,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// import serviceMenu from './variables/serviceMenu';

const styles = (theme) => ({
  category: {
    fontSize: 16,
    lineHeight: '24px',
    margin: '10px 0',
    color: 'inherit'
  },
  item: {
    fontSize: 12,
    lineHeight: '32px',
    opacity: 0.7,
    color: 'inherit',
    '&:hover': {
      opacity: 1
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
      lineHeight: '16px',
      opacity: 1,
      marginBottom: 20
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  panel: {
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingLeft: 16,
      '& > div': {
        transitionDuration: '0ms!important',
        minHeight: 'unset!important',
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          maxHeight: 16
        }
      }
    }
  },
  panelSummary: {
    background: 'transparent',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
      minHeight: 'unset',
      marginBottom: 20
    }
  },
  panelDetails: {
    background: 'transparent',
    padding: 0
  },
  heading: {
    color: '#ffffff',
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
      color: 'inherit',
      lineHeight: '16px',
      marginRight: 11
    }
  },
  expandButton: {
    color: '#ffffff',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  expandButtonArrow: {
    color: '#000',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  expansionPanelSummary: {
    margin: 0
  },
  subMenuMobile: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      background: '#E7EEF3',
      left: 0,
      zIndex: 10,
      paddingLeft: 16,
      paddingRight: 16,
      overflow: 'auto'
    }
  },
  categoryModalTitle: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 48
    }
  },
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  divider: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 35
    }
  },
  closeSubmenuButtom: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  categoryNameWrapp: {
    display: 'flex',
    alignItems: 'center'
  },
  expandButtonArrowHeading: {
    marginRight: 8,
    transform: 'rotate(-180deg)',
    left: -4,
    position: 'relative'
  },
  noCategoryItems: {
    paddingLeft: 18
  }
});

const renderItem =
  (classes) =>
  ({ title, url }, index) => (
    <Grid item={true} xs={12} sm={12}>
      <a href={url} key={index} className={classes.link} rel="noopener noreferrer">
        <Typography variant="body1" className={classes.item}>
          {title}
        </Typography>
      </a>
    </Grid>
  );

const renderCategory =
  (classes, closeButton) =>
  ({ title, items, url, isHidden }, index) => {
    const [expanded, setOpen] = React.useState(false);
    const finalItems = Array.isArray(items) && items.filter(({ isHidden }) => !isHidden);

    const categoryItems = (
      <Grid container={true} className={classes.root} spacing={1}>
        {finalItems && finalItems.map(renderItem(classes))}
      </Grid>
    );

    return (
      <Grid item={true} sm={12} md={6} key={index}>
        <Hidden mdDown={true} implementation="css">
          {url && !isHidden ? (
            <a href={url} className={classes.link} rel="noopener noreferrer">
              <Typography variant="h6" className={classes.category}>
                {title}
              </Typography>
            </a>
          ) : (
            <>
              {!isHidden ? (
                <Typography variant="h6" className={classes.category}>
                  {title}
                </Typography>
              ) : null}
              {categoryItems}
            </>
          )}
        </Hidden>
        <Hidden mdUp={true} implementation="css">
          {title ? (
            <>
              {url ? (
                <a href={url} className={classes.link} rel="noopener noreferrer">
                  <Typography className={classes.heading}>{title}</Typography>
                </a>
              ) : (
                <Accordion className={classes.panel} expanded={expanded}>
                  <AccordionSummary
                    className={classes.panelSummary}
                    classes={{ content: classes.expansionPanelSummary }}
                    onClick={() => setOpen(true)}
                    expandIcon={
                      <>
                        <ExpandMoreIcon className={classes.expandButton} />
                        <ArrowForwardIcon className={classes.expandButtonArrow} />
                      </>
                    }
                  >
                    <Typography className={classes.heading}>{title}</Typography>
                  </AccordionSummary>

                  <AccordionDetails className={classes.panelDetails}>
                    <div className={expanded && classes.subMenuMobile}>
                      <div className={classes.categoryModalTitle} onClick={() => setOpen(false)}>
                        <div className={classes.categoryNameWrapp}>
                          <ArrowForwardIcon className={classes.expandButtonArrowHeading} />
                          <Typography className={classes.heading}>{title}</Typography>
                        </div>
                        <div className={classes.closeSubmenuButtom}>{closeButton}</div>
                      </div>
                      <Divider className={classes.divider} />
                      {categoryItems}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          ) : (
            <div className={classes.noCategoryItems}>{categoryItems}</div>
          )}
        </Hidden>
      </Grid>
    );
  };

const TopSubMenu = ({ t, classes, closeButton, children }) => (
  <Grid container={true} className={classes.root} spacing={1}>
    {children.map(renderCategory(classes, closeButton))}
  </Grid>
);

const styled = withStyles(styles)(TopSubMenu);
export default translate('Layout')(styled);
