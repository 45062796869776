/* eslint-disable react/prop-types */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { translate } from 'react-translate';
import diiaLogo from 'assets/img/diia-logo.svg';
import uaSign from 'assets/img/uasign.svg';

const styles = (theme) => ({
  logo: {
    width: 48,
    height: 48,
    display: 'block',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      width: 32,
      height: 32
    },
    '&:nth-child(2)': {
      marginLeft: 8
    }
  },
  link: {
    display: 'flex'
  }
});

const LogoLinks = ({ t, classes }) => (
  <>
    <a
      href="https://diia.gov.ua"
      className={classes.link}
      rel="noopener noreferrer"
      aria-label={t('LabelLogoDiia')}
    >
      <span className={classes.logo} style={{ backgroundImage: `url(${uaSign})` }} />
      <span className={classes.logo} style={{ backgroundImage: `url(${diiaLogo})` }} />
    </a>
  </>
);

const styled = withStyles(styles)(LogoLinks);
export default translate('Layout')(styled);
