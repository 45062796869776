/* eslint-disable react/prop-types */
import React from 'react';
import { translate } from 'react-translate';
import { DialogTitle, DialogContent, DialogContentText, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import renderHTML from 'helpers/renderHTML';
import fb from 'assets/img/fb.svg';
import telegram from 'assets/img/telegram.svg';
import viber from 'assets/img/viber.svg';

const styles = {
  icon: {
    fontSize: 82,
    color: 'red'
  },
  title: {
    textAlign: 'center'
  },
  socialIcon: {
    width: 65,
    height: 65,
    marginRight: 15
  },
  name: {
    fontSize: 22,
    lineHeight: '28px',
    paddingTop: 15,
    paddingBottom: 20
  },
  wrapper: {
    padding: 80
  },
  button: {
    marginRight: 20
  },
  actionsWrapper: {
    display: 'flex'
  }
};

const ErrorScreen = ({ t, classes, error, children }) => {
  if (!error) return null;

  if (error.message === 'Error: Only one draft allowed.') {
    const toDraftsAction = () => {
      window.location.href = `${window.location.origin}/workflow/drafts`;
    };

    const continueEditingAction = () => {
      window.location.href = `${window.location.origin}/tasks/${error.details.task.id}`;
    };

    return (
      <div className={classes.wrapper}>
        {renderHTML(error?.details?.message)}
        <div className={classes.actionsWrapper}>
          <Button
            size="large"
            variant="outlined"
            className={classes.button}
            onClick={toDraftsAction}
            classes={{ disabled: classes.disabledBorder }}
          >
            {t('ToDrafts')}
          </Button>
          <Button size="large" color="primary" variant="contained" onClick={continueEditingAction}>
            {t('ContinueEditing')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <DialogTitle className={classes.title}>
        <ErrorIcon className={classes.icon} />
      </DialogTitle>
      <DialogTitle className={classes.title}>{t('ErrorMessageHeader')}</DialogTitle>
      <DialogContent className={classes.title}>
        <DialogContentText>{error.message}</DialogContentText>
        {error?.details
          ? []
              .concat(error.details)
              .map((detail, key) => <DialogContentText key={key}>{detail}</DialogContentText>)
          : null}
        {children}
        <Typography variant="h5" className={classes.name}>
          {t('WriteUs')}
        </Typography>
        <a
          href="https://m.me/105597857511240?ref=X3VybD0lMkZsaW5rJmQ9Mg=="
          target="_blank"
          rel="noopener noreferrer"
          className={classes.menuLink}
        >
          <img className={classes.socialIcon} src={fb} alt="Fb" />
        </a>
        <a
          href="https://t.me/Diia_help_bot?start=X3VybD0lMkZsaW5rJmQ9Mg"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.menuLink}
        >
          <img className={classes.socialIcon} src={telegram} alt="Telegram" />
        </a>
        <a
          href="viber://pa?chatURI=diia_help_bot&context=X3VybD0lMkZsaW5rJmQ9Mg=="
          target="_blank"
          rel="noopener noreferrer"
          className={classes.menuLink}
        >
          <img className={classes.socialIcon} src={viber} alt="Viber" />
        </a>
      </DialogContent>
    </>
  );
};

const translated = translate('TaskPage')(ErrorScreen);
export default withStyles(styles)(translated);
