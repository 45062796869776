const REQUEST_EXTERNAL_DATA_SUCCESS = 'REQUEST_EXTERNAL_DATA_SUCCESS';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_EXTERNAL_DATA_SUCCESS: {
      const { requestData } = action.request;

      return {
        ...state,
        [JSON.stringify(requestData)]: action.payload
      };
    }
    default:
      return state;
  }
};
