import React from 'react';
import { connect } from 'react-redux';
import { Badge, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  badge: {
    position: 'relative',
    transform: 'none',
    marginLeft: 4,
    zIndex: 0
  },
  title: {
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      lineHeight: '18px'
    }
  }
});

const MessagesTab = ({ classes, title, unreadCount }) => {
  const unreadMessagesExists = Number(unreadCount) > 0;

  if (!unreadMessagesExists) {
    return <Typography className={classes.title}>{title}</Typography>;
  }

  return (
    <Badge classes={classes} badgeContent={unreadCount} color="primary">
      <Typography className={classes.title}>{title}</Typography>
    </Badge>
  );
};

const mapStateToProps = ({ messages: { unreadCount } }) => ({ unreadCount });

const styled = withStyles(styles)(MessagesTab);
export default connect(mapStateToProps)(styled);
