import React from 'react';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = (theme) => ({
  mainContent: {
    padding: 24,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '50px 80px 64px 80px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 32
    }
  },
  minHeight: {
    [theme.breakpoints.up('md')]: {
      minHeight: 600
    }
  },
  smallPadding: {
    padding: '0 16px 16px'
  }
});

export default withStyles(styles)(({ children, classes, small }) => (
  <main
    className={classNames(classes.mainContent, {
      [classes.smallPadding]: small,
      [classes.minHeight]: children && children.length
    })}
  >
    {children}
  </main>
));
