import React from 'react';
import PropTypes from 'prop-types';
import {
  Hidden,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  content: {
    flex: 1
  },
  dawer: {
    height: '100%',
    padding: '72px 72px 0',
    marginBottom: 30,
    width: 400,
    flexShrink: 0
  },
  panel: {
    margin: '24px 24px 0!important',
    [theme.breakpoints.up('sm')]: {
      margin: '80px 80px 0!important'
    },
    [theme.breakpoints.down('md')]: {
      margin: '80px 24px 0!important'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '32px 16px 0!important'
    }
  },
  heading: {
    fontSize: 20,
    lineHeight: '24px',
    marginBottom: 24,
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      marginBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      paddingRight: '0!important'
    }
  },
  panelTitle: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  dropIcon: {
    color: '#000'
  },
  mobileRoot: {
    display: 'block',
    paddingTop: 0
  },
  dividerRoot: {
    marginBottom: 10
  }
});

const DawerContent = ({ classes, children, dawer, dawerTitle }) => (
  <div className={classes.root}>
    <div className={classes.content}>
      {dawerTitle || dawer ? (
        <Hidden lgUp={true} implementation="css">
          <Accordion className={classes.panel} classes={{ expanded: classes.panel }}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon classes={{ root: classes.dropIcon }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{ content: classes.panelTitle }}
            >
              <Typography className={classes.heading}>{dawerTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classNames(classes.mobileRoot, classes.panelTitle)}>
              {dawer}
            </AccordionDetails>
          </Accordion>
        </Hidden>
      ) : null}
      {children}
    </div>
    {dawerTitle || dawer ? (
      <Hidden lgDown={true} implementation="css">
        <div className={classes.dawer}>
          <Typography className={classes.heading}>{dawerTitle}</Typography>
          <Divider className={classes.dividerRoot} />
          {dawer}
        </div>
      </Hidden>
    ) : null}
  </div>
);

DawerContent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  dawer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  dawerTitle: PropTypes.string
};

DawerContent.defaultProps = {
  dawerTitle: null,
  dawer: null
};

export default withStyles(styles)(DawerContent);
