import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { Typography, Tooltip, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';

const styles = (theme) => ({
  container: {
    marginLeft: 98,
    width: 575,
    marginBottom: 48,
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      width: '100%',
      marginLeft: 0,
      display: 'grid',
      '& > span': {
        gridColumn: '2/2',
        marginLeft: '10px'
      }
    }
  },
  containerWithoutBlock: {
    marginLeft: 98,
    width: 575,
    marginBottom: 48,
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      width: '100%',
      marginLeft: 0,
      display: 'flex'
    }
  },
  wrapper: {
    padding: 0,
    marginTop: 8
  },
  box: {
    margin: 'auto',
    gridRow: '1/3',
    gridColumn: '1/2',
    position: 'relative',
    marginTop: 8
  },
  typography: {
    display: 'flex',
    marginTop: 10,
    width: '90%'
  },
  circularStatic: {
    color: 'rgba(21, 207, 116, 0.1)',
    position: 'absolute'
  },
  circularText: {
    top: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 72,
    height: 72
  },
  fingerSpan: {
    margin: 'auto',
    gridRow: '1/3',
    fontSize: 25,
    [theme.breakpoints.down('sm')]: {
      gridRow: '1/3',
      gridColumn: '1/2',
      fontSize: 20
    }
  },
  percentage: {
    fontSize: 12
  },
  back: {
    color: 'rgba(21, 207, 116, 0.1)'
  },
  circle: {
    strokeLinecap: 'round'
  },
  colorPrimaryCircular: {
    color: '#15CF74'
  },
  barColorPrimary: {
    background: 'rgba(21, 207, 116, 0.1)',
    borderRadius: 4
  },
  colorPrimary: {
    background: '#15CF74',
    borderRadius: 4
  },
  progressText: {
    fontSize: 10,
    opacity: 0.5,
    marginRight: 8,
    marginTop: 2
  },
  htmlBlock: {
    display: 'grid',
    gridGap: 0,
    padding: '8, 16, 8, 8',
    gap: 4,
    marginRight: 'auto',
    gridTemplateColumns: 'minmax(36px,36px) minmax(50%, auto)',
    width: 383,
    background: '#FFF4D7',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'minmax(36px,36px) minmax(50%, auto)',
      width: '100%',
      marginLeft: '10px'
    }
  },
  titleDescription: {
    textAlign: 'left',
    fontSize: 12,
    marginTop: 4,
    gridColumn: '2/2',
    letterSpacing: '-0.02em',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  },
  textDescription: {
    textAlign: 'left',
    fontSize: 12,
    gridColumn: '2/2',
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      marginBottom: 4
    }
  }
});

const ProgressBar = ({
  t,
  steps,
  classes,
  activeStep,
  isMobile,
  activeStepName,
  startStepIndex,
  progress
}) => {
  const percentage = ((activeStep - startStepIndex) * 100) / (steps.length - startStepIndex);
  const activeStepData = progress[activeStepName];

  if (!activeStepData) {
    return null;
  }

  const renderHtmlBlock = () => (
    <div className={classes.htmlBlock}>
      <span role="img" aria-label="finger" className={classes.fingerSpan}>
        ☝
      </span>
      <span className={classes.titleDescription}>{t('ApplicationSubmission')}</span>
      <span className={classes.textDescription}>{progress[activeStepName]?.subtitle}</span>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <div
          className={classNames({
            [classes.container]: progress[activeStepName]?.subtitle,
            [classes.containerWithoutBlock]: !progress[activeStepName]?.subtitle
          })}
        >
          <span>{progress[activeStepName]?.title}</span>
          <Box className={classes.box}>
            <CircularProgress
              variant="determinate"
              classes={{ root: classes.circularStatic }}
              size={72}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              classes={{
                circle: classes.circle,
                svg: classes.colorPrimaryCircular
              }}
              thickness={4}
              size={72}
              value={percentage}
            />
            <Box className={classes.circularText}>
              <Typography variant="caption" component="div" color="textSecondary">
                {`${Math.round(percentage)}%`}
              </Typography>
            </Box>
          </Box>
          {progress[activeStepName]?.subtitle ? (
            <Typography component="div" className={classes.typography}>
              {renderHtmlBlock()}
            </Typography>
          ) : null}
        </div>
      ) : (
        <div className={classes.container}>
          <span>{activeStepData?.title}</span>
          <Tooltip className={classes.wrapper}>
            <LinearProgress
              classes={{
                colorPrimary: classes.barColorPrimary,
                barColorPrimary: classes.colorPrimary
              }}
              variant="determinate"
              value={percentage}
            />
          </Tooltip>
          <Typography component="div" className={classes.typography}>
            {activeStepData?.subtitle ? renderHtmlBlock() : null}
            <span className={classes.progressText}>{t('YourProgress')}</span>
            <span className={classes.percentage}>{`${Math.round(percentage)}%`}</span>
          </Typography>
        </div>
      )}
    </>
  );
};

ProgressBar.propTypes = {
  t: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  activeStepName: PropTypes.string.isRequired,
  startStepIndex: PropTypes.number.isRequired,
  progress: PropTypes.object.isRequired
};

const styled = withStyles(styles)(ProgressBar);

export default translate('TaskPage')(styled);
