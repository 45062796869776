import React from 'react';
import withStyles from '@mui/styles/withStyles';

import twitter from 'assets/img/twiter.png';
import facebook from 'assets/img/facebook.png';
import youtube from 'assets/img/youtube.png';
import instagram from 'assets/img/instagram.png';

const styles = {
  socialIcon: {
    width: 36,
    height: 36,
    display: 'block',
    marginRight: 10
  }
};

const SocialLinks = ({ classes }) => (
  <>
    <a href="https://www.facebook.com/mva.gov.ua/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
      <img loading="lazy" src={facebook} className={classes.socialIcon} alt="Facebook" />
    </a>
    <a href="https://www.instagram.com/minveteraniv" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
      <img loading="lazy" src={instagram} className={classes.socialIcon} alt="Instagram" />
    </a>
    <a href="https://www.facebook.com/mva.gov.ua/" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
      <img loading="lazy" src={twitter} className={classes.socialIcon} alt="Twitter" />
    </a>
    <a href="https://www.youtube.com/channel/UCjX3ljo1aAhVNUdDPuSDtXQ" target="_blank" rel="noopener noreferrer" aria-label="Yutube">
      <img loading="lazy" src={youtube} className={classes.socialIcon} alt="YouTube" />
    </a>
  </>
);

export default withStyles(styles)(SocialLinks);
