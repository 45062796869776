/* eslint-disable no-nested-ternary */
import moment from 'moment';

const removeEmptyFields = (object = {}) => {
  const recursiveObj = (obj) => {
    (Object.keys(obj) || []).forEach((key) => {
      if (obj[key] === null) delete obj[key];
      if (typeof obj[key] === 'object') recursiveObj(obj[key]);
    });
    return obj;
  };
  return recursiveObj(object);
};

const parseDate = (date, format) => {
  if (!date || date === 'Invalid date') return null;

  return {
    day: moment(date, format).format('DD'),
    month: moment(date, format).format('MM'),
    year: moment(date, format).format('YYYY')
  };
};

export default (authInfo) =>
  removeEmptyFields({
    addressStruct: {
      region: authInfo?.addressStruct?.region,
      district: authInfo?.addressStruct?.district,
      city: authInfo?.addressStruct?.city
    },
    apt: {
      apt: authInfo?.addressStruct?.apt,
      index: authInfo?.addressStruct?.index
    },
    building: {
      building: authInfo?.addressStruct?.building,
      korp: authInfo?.addressStruct?.korp
    },
    street: {
      type: authInfo?.addressStruct?.street?.type,
      name: authInfo?.addressStruct?.street?.name
    },
    isPrivateHouse: authInfo?.isPrivateHouse ? [].concat('приватний будинок') : null,
    birthday: parseDate(authInfo?.birthday, 'DD-MM-YYYY'),
    tabs: {
      active: authInfo?.idCardNumber
        ? 'idCard'
        : authInfo?.foreignersDocumentNumber
        ? 'foreignersDocument'
        : 'passport',
      passport: {
        pasNumber: {
          passportSeries: authInfo?.passportSeries,
          passportNumber: authInfo?.passportNumber
        },
        passportIssueDate: parseDate(authInfo?.passportIssueDate),
        passportIssuedBy: authInfo?.passportIssuedBy
      },
      idCard: {
        idCardNumber: authInfo?.idCardNumber,
        idCardIssueDate: parseDate(authInfo?.idCardIssueDate),
        idCardExpiryDate: parseDate(authInfo?.idCardExpiryDate),
        idCardIssuedBy: authInfo?.idCardIssuedBy
      },
      foreignersDocument: {
        foreignersDocumentExpireDate: Object.values(
          authInfo?.foreignersDocumentExpireDate || {}
        ).filter(Boolean).length
          ? parseDate(authInfo?.foreignersDocumentExpireDate)
          : '',
        foreignersDocumentIssueDate: Object.values(
          authInfo?.foreignersDocumentIssueDate || {}
        ).filter(Boolean).length
          ? parseDate(authInfo?.foreignersDocumentIssueDate)
          : '',
        foreignersDocumentIssuedBy: authInfo?.foreignersDocumentIssuedBy,
        foreignersDocumentNumber: authInfo?.foreignersDocumentNumber,
        foreignersDocumentSeries: authInfo?.foreignersDocumentSeries,
        foreignersDocumentType: authInfo?.foreignersDocumentType
      }
    },
    gender: authInfo?.gender,
    birthdayPopup: {
      birthday: parseDate(authInfo?.birthday, 'DD-MM-YYYY')
    },
    passportPopup: {
      tabs: {
        active: authInfo?.idCardNumber
          ? 'idCard'
          : authInfo?.foreignersDocumentNumber
          ? 'foreignersDocument'
          : 'passport',
        passport: {
          pasNumber: {
            passportSeries: authInfo?.passportSeries,
            passportNumber: authInfo?.passportNumber
          },
          passportIssueDate: parseDate(authInfo?.passportIssueDate),
          passportIssuedBy: authInfo?.passportIssuedBy
        },
        idCard: {
          idCardNumber: authInfo?.idCardNumber,
          idCardIssueDate: parseDate(authInfo?.idCardIssueDate),
          idCardExpiryDate: parseDate(authInfo?.idCardExpiryDate),
          idCardIssuedBy: authInfo?.idCardIssuedBy
        },
        foreignersDocument: {
          foreignersDocumentExpireDate: Object.values(
            authInfo?.foreignersDocumentExpireDate || {}
          ).filter(Boolean).length
            ? parseDate(authInfo?.foreignersDocumentExpireDate)
            : '',
          foreignersDocumentIssueDate: Object.values(
            authInfo?.foreignersDocumentIssueDate || {}
          ).filter(Boolean).length
            ? parseDate(authInfo?.foreignersDocumentIssueDate)
            : '',
          foreignersDocumentIssuedBy: authInfo?.foreignersDocumentIssuedBy,
          foreignersDocumentNumber: authInfo?.foreignersDocumentNumber,
          foreignersDocumentSeries: authInfo?.foreignersDocumentSeries,
          foreignersDocumentType: authInfo?.foreignersDocumentType
        }
      }
    },
    registrationPopup: {
      addressStruct: {
        region: authInfo?.addressStruct?.region,
        district: authInfo?.addressStruct?.district,
        city: authInfo?.addressStruct?.city
      },
      apt: {
        apt: authInfo?.addressStruct?.apt,
        index: authInfo?.addressStruct?.index
      },
      building: {
        building: authInfo?.addressStruct?.building,
        korp: authInfo?.addressStruct?.korp
      },
      street: {
        type: authInfo?.addressStruct?.street?.type,
        name: authInfo?.addressStruct?.street?.name
      }
    }
  });
