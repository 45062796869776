import * as api from 'services/api';
import storage from 'helpers/storage';
import getHeaders, { getHeadersResponse } from 'helpers/getReaderMocks';

const REQUEST_EXTERNAL_DATA = 'REQUEST_EXTERNAL_DATA';

const PROP_NAME = 'residentData';

const requestData = {
  service: 'e-resident',
  method: 'get-person-info',
  filters: {}
};

export const getResidentData = () => (dispatch) => {
  try {
    const savedData = storage.getItem(PROP_NAME);

    if (savedData) {
      return Promise.resolve(JSON.parse(savedData));
    }

    return api
      .post(
        'external_reader',
        requestData,
        REQUEST_EXTERNAL_DATA,
        dispatch,
        { requestData },
        { headers: getHeaders(dispatch) }
      )
      .then((response) => {
        const { data } = response;

        if (response) {
          storage.setItem(PROP_NAME, JSON.stringify(response));
          const returnedMocks = response.headers.get('Returned-Mocks');
          getHeadersResponse(dispatch, returnedMocks);
          return response;
        }

        return Promise.reject(data);
      })
      .catch((error) => error);
  } catch {
    storage.removeItem(PROP_NAME);
    return false;
  }
};

export default { getResidentData };
