import config from 'config';

import { getUIFilters } from 'actions/app';

import getUserModules from 'actions/information';

import { getUnreadMessageCount } from 'application/actions/messages';

export const access = null;

export const initActions = {
  ...(config.useUIFilters ? { getUIFilters } : {}),
  getUnreadMessageCount,
  getUserModules
};

export { default as modules } from 'modules';
export { default as reducers } from 'reducers';
