import font from './assets/fonts/e-Ukraine-Light.woff';

const fontFamily = {
  fontFamily: 'e-Ukraine',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('e-Ukraine'),
    local('Re-Ukraine-Light'),
    url(${font}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

export default {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 48
    }
  },
  editScreenProgressStyle: {
    maxWidth: 640
  },
  overrides: {
    MuiPaginationItem: {
      outlined: {
        border: 'none'
      },
      page: {
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          border: '2px solid rgba(0, 0, 0, 1)'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: 11,
        '@media (max-width:415px)': {
          fontSize: 8
        }
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 16
      },
      input: {
        color: '#000000'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [fontFamily]
      }
    },
    MuiInputLabel: {
      root: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        fontSize: 16
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#122945'
      }
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: '#1aaa55'
      },
      colorSecondary: {
        color: '#fc9403'
      }
    },
    MuiTabs: {
      root: {
        marginLeft: 8
      },
      indicator: {
        height: 3
      }
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        '@media (min-width:960px)': {
          minWidth: 0
        }
      },
      textColorInherit: {
        color: 'inherit',
        opacity: 0.7
      },
      labelContainer: {
        padding: 0,
        '@media (min-width:960px)': {
          padding: 0
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: 8
      }
    },
    MuiButton: {
      label: {
        left: '39.1%',
        right: '38.74%',
        top: '28.57%',
        bottom: '28.57%',
        fontSize: '16px',
        lineHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '-0.02em'
      },
      containedPrimary: {
        marginRight: 4,
        boxShadow: 'none',
        color: '#FFFFFF',
        backgroundColor: '#000000',
        borderRadius: 40,
        padding: '16px 62px',
        '@media (max-width:375px)': {
          padding: '12px 52px'
        },
        '&:hover': {
          color: '#000000',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          opacity: 1,
          visibility: 'visible',
          backgroundImage:
            'linear-gradient(217deg,rgba(255,0,0,.8),rgba(255,0,0,0) 70.71%),linear-gradient(127deg,rgba(0,0,255,.8),rgba(0,0,255,0) 70.71%),linear-gradient(336deg,rgba(0,255,0,.8),rgba(0,255,0,0) 70.71%)',
          backgroundSize: '200% 300%',
          animation: '10s infinite granimate',
          '@media (min-width:320px)': {
            backgroundColor: 'transparent'
          }
        },
        '&:disabled': {
          backgroundColor: '#fafafa',
          border: '2px solid #000000',
          color: '#232f3d',
          opacity: 0.5
        }
      },
      outlined: {
        borderRadius: 40,
        color: '#000000',
        border: '2px solid #000',
        padding: '16px 62px',
        '@media (max-width:375px)': {
          padding: '12px 52px'
        },
        '&:hover': {
          color: '#000000',
          backgroundColor: 'transparent',
          opacity: 1,
          border: '2px solid #000',
          visibility: 'visible',
          backgroundImage:
            'linear-gradient(217deg,rgba(255,0,0,.8),rgba(255,0,0,0) 70.71%),linear-gradient(127deg,rgba(0,0,255,.8),rgba(0,0,255,0) 70.71%),linear-gradient(336deg,rgba(0,255,0,.8),rgba(0,255,0,0) 70.71%)',
          backgroundSize: '200% 300%',
          animation: '10s infinite granimate'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        borderTop: 'thin solid #000',
        borderBottom: 'thin solid #000',
        backgroundColor: undefined,
        height: undefined
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: 400
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20
        }
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#E7EEF3'
        },
        '&:focus': {
          backgroundColor: '#E7EEF3'
        }
      },
      root: {
        '&$selected': {
          '&:hover': {
            backgroundColor: '#E7EEF3'
          },
          '&:focus': {
            backgroundColor: '#E7EEF3!important'
          }
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none'
      }
    },
    MUIDataTableToolbar: {
      root: {
        height: 64,
        boxShadow: 'none',
        backgroundColor: '#fff',
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      }
    },
    MUIDataTableToolbarSelect: {
      root: {
        height: 64,
        boxShadow: 'none',
        backgroundColor: '#fff',
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      iconButton: {
        height: 40
      }
    },
    MuiInput: {
      root: {
        '&$disabled': {
          color: '#000000',
          opacity: 0.5,
          '&:before': {
            borderBottomStyle: 'solid!important'
          }
        }
      },
      underline: {
        '&:before': {
          borderBottom: '2px solid #000000'
        },
        '&:after': {
          borderBottom: '2px solid transparent',
          opacity: 1,
          visibility: 'visible',
          backgroundSize: '100% 20px',
          backgroundImage:
            'linear-gradient(5.09deg, #C3AAB2 0.66%, #99EECC 57.2%, #80C0C8 75.87%, #4B8BFA 98.72%)'
        }
      }
    },
    MuiTablePagination: {
      selectRoot: {
        marginLeft: 8,
        marginRight: 8
      },
      select: {
        fontSize: 12
      },
      actions: {
        marginLeft: 0
      }
    },
    MuiPaper: {
      root: {
        fontFamily: '"e-Ukraine"'
      }
    },
    MuiRadio: {
      root: {
        color: '#000000'
      }
    },
    MuiLinearProgress: {
      barColorPrimary: {
        background:
          'linear-gradient(90deg, #C3AAB2 0%, #99EECC 57.66%, #80C0C8 76.7%, #4B8BFA 100%)'
      },
      colorPrimary: {
        backgroundColor: '#fff'
      }
    },
    MuiAccordion: {
      root: {
        padding: 2,
        boxShadow: 'none',
        backgroundImage:
          'linear-gradient(217deg,rgba(255,0,0,.8),rgba(255,0,0,0) 70.71%),linear-gradient(127deg,rgba(0,0,255,.8),rgba(0,0,255,0) 70.71%),linear-gradient(336deg,rgba(0,255,0,.8),rgba(0,255,0,0) 70.71%)',
        backgroundSize: '200% 300%',
        animation: '10s infinite granimate'
      }
    },
    MuiAccordionSummary: {
      root: {
        background: '#fff'
      }
    },
    MuiAccordionDetails: {
      root: {
        background: '#fff'
      }
    },
    MuiFormControl: {
      root: {
        display: 'flex!important'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#000000',
        opacity: 0.5,
        fontFamily: '"e-Ukraine"',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-0.02em'
      }
    },
    MuiChip: {
      root: {
        'background-color': '#F1F1F1',
        color: '#000000'
      }
    },
    MuiPopover: {
      paper: {
        boxShadow: 'none'
      }
    },
    MuiSelect: {
      icon: {
        color: '#000000'
      },
      select: {
        '&:focus': {
          backgroundColor: '#fff'
        }
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        TableCell: {
          cell: {
            paddingLeft: 0
          }
        }
      },
      cell: {
        paddingLeft: 0
      }
    },
    MuiGrid: {
      item: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important'
      },
      container: {
        marginTop: '0 !important',
        marginBottom: '0 !important'
      }
    }
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    type: 'light',
    primary: {
      light: '#63ccff',
      main: '#000000',
      dark: '#7c7c7c',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff4081',
      main: '#000000',
      dark: '#c51162',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: '#000000',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: '#7f7f7f',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#ffffff'
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.1)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)'
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
    '0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)',
    '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
  ],
  typography: {
    fontFamily: '"e-Ukraine"',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    display4: {
      fontSize: '7rem',
      fontWeight: 300,
      fontFamily: '"e-Ukraine"',
      letterSpacing: '-0.02em',
      lineHeight: '1.14286em',
      marginLeft: '-.04em',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    display3: {
      fontSize: '3.5rem',
      fontWeight: 400,
      fontFamily: '"e-Ukraine"',
      letterSpacing: '-.02em',
      lineHeight: '1.30357em',
      marginLeft: '-.02em',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    display2: {
      fontSize: '2.8125rem',
      fontWeight: 400,
      fontFamily: '"e-Ukraine"',
      lineHeight: '1.13333em',
      marginLeft: '-.02em',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    display1: {
      fontSize: '2.125rem',
      fontWeight: 400,
      fontFamily: '"e-Ukraine"',
      lineHeight: '1.20588em',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    headline: {
      fontSize: '1.5rem',
      fontWeight: 400,
      fontFamily: '"e-Ukraine"',
      lineHeight: '1.35417em',
      color: '#000000'
    },
    title: {
      fontSize: '1.3125rem',
      fontWeight: 400,
      fontFamily: '"e-Ukraine"',
      lineHeight: '1.16667em',
      color: '#000000'
    },
    subheading: {
      fontSize: '1rem',
      fontWeight: 400,
      fontFamily: '"e-Ukraine"',
      lineHeight: '1.5em',
      color: '#000000'
    },
    body2: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.02em'
    },
    body1: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.02em'
    },
    caption: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em'
    },
    button: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '-0.02em',
      textTransform: 'inherit'
    },
    h1: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1,
      letterSpacing: '-0.02em'
    },
    h2: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1,
      letterSpacing: '-0.02em'
    },
    h3: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.04,
      letterSpacing: '-0.02em'
    },
    h4: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.17,
      letterSpacing: '-0.02em'
    },
    h5: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: 24,
      lineHeight: 1.33,
      letterSpacing: '-0.02em'
    },
    h6: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '-0.02em'
    },
    subtitle1: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: '-0.02em'
    },
    subtitle2: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '-0.02em'
    },
    body1Next: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '-0.02em'
    },
    body2Next: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '-0.02em'
    },
    buttonNext: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '-0.02em',
      textTransform: 'uppercase'
    },
    captionNext: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '-0.02em'
    },
    overline: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '-0.02em',
      textTransform: 'uppercase'
    },
    stepsTitle: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontWeight: 400,
      fontSize: 28,
      lineHeight: '32px',
      letterSpacing: 0.5
    },
    finalStepTitle: {
      color: '#000000',
      fontFamily: '"e-Ukraine"',
      fontSize: 38,
      lineHeight: '40px',
      letterSpacing: '-0.02em'
    },
    useNextVariants: true
  },
  shape: {
    borderRadius: 4
  },
  spacing: 8,
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  },
  leftSidebarBg: '#122945',
  buttonBg: '#ffd400',
  buttonHoverBg: '#ffe565',
  textColorDark: '#122945',
  headerBg: '#eeeeee',
  checkboxBg: '#000000',
  borderColor: '#000000',
  dataTableHoverColor: '#A30101',
  dataTableHoverBg: '#E7EEF3',
  navLinkActive: 'rgba(255, 255, 255, .1)',
  categoryWrapperActive: 'rgba(255, 255, 255, .1)',
  logo: {
    width: '100%',
    minHeight: 74,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginTop: 10
  },
  outlinedTabs: {
    borderRadius: 0,
    background: '#fff',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    border: 'none',
    opacity: 0.5,
    padding: '4px 0',
    borderBottom: '2px solid transparent',
    marginRight: 16
  },
  outlinedActiveTabs: {
    borderLeft: 'none',
    borderBottom: '2px solid #000'
  },
  containedActiveTabs: {
    border: '2px solid #000',
    borderLeft: '2px solid #000'
  },
  blockWrapper: {
    border: 'none!important',
    padding: '0!important'
  },
  blockHead: {
    justifyContent: 'flex-start!important'
  }
};
