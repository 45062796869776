import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = (theme) => ({
  wrap: {
    width: '100%',
    marginTop: 120,
    paddingLeft: 60,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginTop: 50,
      paddingLeft: 0
    }
  },
  title: {
    padding: '0 12px',
    marginTop: 15,
    marginBottom: 22,
    fontSize: 30,
    fontWeight: 'bold',
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: 20,
      fontWeight: 'normal'
    }
  },
  subtitle: {
    padding: 0,
    textTransform: 'inherit',
    fontSize: 16,
    lineHeight: 'normal',
    marginBottom: 50,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      paddingLeft: 0
    }
  },
  childrenWrap: {
    maxWidth: 300
  },
  emptyTitle: {
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  emptyTitleLg: {
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '26px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  emptyEmoji: {
    fontSize: 48,
    width: 48,
    height: 48,
    display: 'inline-block',
    marginBottom: 12,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      fontSize: 32
    }
  },
  emptyEmojiWrapp: {
    marginTop: 100,
    maxWidth: 600,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40
    }
  },
  largeTitle: {
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '28px',
    lineHeight: '32px',
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '18px'
    }
  }
});

const EmptyPage = ({
  title,
  description,
  classes,
  children,
  externalReader,
  withIcon,
  icon,
  subtitle,
  largeTitle,
  maxWidth
}) => (
  <>
    {externalReader ? (
      <>
        <div className={classes.emptyEmojiWrapp} style={{ maxWidth }}>
          {withIcon ? (
            <span role="img" aria-label="man-shrugging" className={classes.emptyEmoji}>
              {icon || '🤷‍♂️'}
            </span>
          ) : null}
          {largeTitle ? <Typography className={classes.largeTitle}>{largeTitle}</Typography> : null}
          <Typography className={withIcon ? classes.emptyTitle : classes.emptyTitleLg}>
            {title}
          </Typography>
          {subtitle ? (
            <Typography className={classes.subtitle} variant="body1" gutterBottom={true}>
              {subtitle}
            </Typography>
          ) : null}
        </div>
        {description ? (
          <Typography className={classes.subtitle} variant="body1" gutterBottom={true}>
            {description}
          </Typography>
        ) : null}
      </>
    ) : (
      <div className={classes.wrap} style={{ maxWidth }}>
        <Typography className={classes.title} variant="h5" gutterBottom={true}>
          {title}
        </Typography>
        <Typography className={classes.subtitle} variant="body1" gutterBottom={true}>
          {description}
        </Typography>
      </div>
    )}
    <div
      className={classNames({
        [classes.childrenWrap]: true
      })}
      style={{ maxWidth }}
    >
      {children}
    </div>
  </>
);

EmptyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  externalReader: PropTypes.bool,
  withIcon: PropTypes.bool,
  icon: PropTypes.string,
  subtitle: PropTypes.string,
  maxWidth: PropTypes.string,
  largeTitle: PropTypes.string
};

EmptyPage.defaultProps = {
  title: '',
  description: '',
  externalReader: false,
  children: <div />,
  withIcon: true,
  icon: null,
  subtitle: null,
  largeTitle: null,
  maxWidth: 'unset'
};

export default withStyles(styles)(EmptyPage);
