import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import evaluate from 'helpers/evaluate';
import MobileDetect from 'mobile-detect';
import classNames from 'classnames';
import ProgressBar from './ProgressBar';

const styles = (theme) => ({
  stepperToolbar: {
    padding: '0 4px'
  },
  steeper: {
    background: 'linear-gradient(90deg, #C3AAB2 0%, #99EECC 57.66%, #80C0C8 76.7%, #4B8BFA 100%)',
    margin: '10px 0px 28px',
    overflow: 'hidden',
    height: 2,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0px 15px'
    }
  },
  typographyStyle: {
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      lineHeight: '18px'
    }
  },
  step: {
    wordWrap: 'break-word',
    boxSizing: 'border-box'
  },
  stepBtn: {
    '& span': {
      maxWidth: '100%'
    }
  },
  usualStepper: {
    background: '#fafafa'
  },
  mobileStepper: {
    backgroundColor: '#fafafa',
    width: '100%',
    '& > div': {
      margin: 'auto'
    }
  },
  descriptionWord: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: 4
  },
  span: {
    display: 'inline-block'
  },
  wrapper: {
    display: 'flex'
  },
  wrapperMob: {
    marginBottom: 16
  }
});

const SchemaStepper = ({ t, steps, classes, activeStep, jsonSchema, task }) => {
  const md = new MobileDetect(window.navigator.userAgent);

  const isMobile = !!md.mobile();

  let progress;

  if (jsonSchema?.labelsOrder) {
    progress = evaluate(jsonSchema?.labelsOrder, task.document.data);

    if (progress instanceof Error) {
      return '';
    }
  }

  const activeStepName = steps[activeStep];
  const startStepIndex = steps.indexOf(progress?.startStep);

  return steps.length > 1 ? (
    <>
      <Typography
        variant="body2"
        component="div"
        className={classNames({
          [classes.wrapperMob]: isMobile,
          [classes.wrapper]: !isMobile
        })}
      >
        <span className={classes.span}>
          <span className={classes.typographyStyle}>
            {t('Steps', { activeStep: activeStep + 1, steps: steps.length })}
          </span>
          <div className={classes.steeper} />
        </span>
        {activeStep >= startStepIndex && progress ? (
          <ProgressBar
            t={t}
            isMobile={isMobile}
            activeStepName={activeStepName}
            startStepIndex={startStepIndex}
            progress={progress}
            steps={steps}
            classes={classes}
            activeStep={activeStep}
          />
        ) : null}
      </Typography>
    </>
  ) : null;
};

SchemaStepper.propTypes = {
  t: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  jsonSchema: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired
};

const styled = withStyles(styles)(SchemaStepper);

export default translate('TaskPage')(styled);
