import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import moment from 'moment';
import qs from 'qs';
import { updateUserInfo, requestUserInfo, getQueryLangParam } from 'actions/auth';
import deepObjectFind from 'helpers/deepObjectFind';
import { SchemaForm, ChangeEvent, validateData } from 'components/JsonSchema';
import { Typography, FormHelperText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Preloader from 'components/Preloader';
import RightSidebarLayout from 'layouts/RightSidebarLayout';
import { Content } from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import ErrorScreen from 'components/ErrorScreen';
import ConfirmDialog from 'components/ConfirmDialog';
import EJVError from 'components/JsonSchema/components/EJVError';
import userIsResident from 'helpers/userIsResident';
import { getResidentData } from 'actions/eResident';
import schemaToEval from '../variables/schema.js';
import profileSchemaData from '../variables/dataSchema';

const styles = (theme) => ({
  title: {
    fontSize: 38,
    lineHeight: '40px',
    letterSpacing: '-0.02em',
    paddingTop: 32,
    paddingBottom: 64,
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      paddingBottom: 32,
      fontSize: 32,
      lineHeight: 1
    }
  },
  toolbar: {
    padding: '0 4px'
  },
  flexGrow: {
    flexGrow: 1
  },
  popupWrapper: {
    ...theme.blockWrapper,
    '& > div > div:last-child': {
      paddingTop: 11,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0
      },
      '& > span': {
        [theme.breakpoints.down('md')]: {
          paddingTop: 1
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: 10
        }
      }
    },
    '& > div:last-child': {
      '& div > div': {
        marginBottom: 0
      }
    },
    '& > div > h6': {
      [theme.breakpoints.between('sm', 'lg')]: {
        marginBottom: 7
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0
      }
    }
  },
  header: {
    ...theme.blockHead,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  wrap: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
});

const parseDate = (date, format) => {
  if (!date || date === 'Invalid date') {
    return {
      day: '',
      month: '',
      year: '',
      full: ''
    };
  }

  if (typeof date === 'object') {
    const { day, month, year } = date;
    const momentDate = moment(`${day}.${month}.${year}`, 'DD.MM.YYYY');
    if (!momentDate.isValid()) {
      return '';
    }

    return momentDate.format(format);
  }

  const momentLocalization = getQueryLangParam() === 'eng' ? 'en' : 'ua';

  return {
    day: moment(date, format).format('DD'),
    month: moment(date, format).format('MM'),
    year: moment(date, format).format('YYYY'),
    full: moment(date, format).locale(momentLocalization).format('LL')
  };
};

const removeEmptyFields = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
  return obj;
};

const createOption = (opt, display, foreignersDocument) => {
  if (!opt) return null;

  if (typeof opt === 'object' && Object.keys(opt).length === 0) return null;

  return removeEmptyFields({
    id: (display && foreignersDocument ? opt.id : display ? opt.registerRecordId : opt.atuId) || '',
    registerRecordId: (display ? opt.registerRecordId : opt.registerRecordId || opt.id) || '',
    name: opt.name || '',
    type: opt.type || '',
    registerRecord: opt.registerRecord || opt,
    ...(display ? opt.registerRecord : {}),
    ...(display ? opt : {})
  });
};

const privateHouse = 'приватний будинок';
class Profile extends ModulePage {
  state = {
    error: null,
    errors: [],
    busy: false,
    loading: false,
    requiredModal: false,
    residentUserData: null
  };

  formatDatedToDisplay = ({
    passportIssueDate,
    birthday,
    idCardExpiryDate,
    idCardIssueDate,
    foreignersDocumentIssueDate,
    foreignersDocumentExpireDate
  }) => {
    const { userInfo } = this.props;
    userInfo.formattedBirthday = parseDate(
      birthday,
      birthday?.indexOf('/') === -1 ? 'YYYY-MM-DD' : 'DD-MM-YYYY'
    );
    userInfo.formattedPassportIssueDate = parseDate(passportIssueDate);
    userInfo.formattedIdCardExpiryDate = parseDate(idCardExpiryDate);
    userInfo.formattedIdCardIssueDate = parseDate(idCardIssueDate);
    userInfo.formattedForeignersDocumentExpireDate = parseDate(foreignersDocumentExpireDate);
    userInfo.formattedForeignersDocumentIssueDate = parseDate(foreignersDocumentIssueDate);
  };

  componentGetTitle = () => {
    const { t } = this.props;
    return t('MyProfileTitle');
  };

  handleStoreCustom = async ({
    birthday,
    tabs: { active, passport, idCard, foreignersDocument },
    building: { building, korp },
    apt: { apt, index },
    addressStruct: { region, district, city },
    street,
    isPrivateHouse,
    gender
  }) => {
    const { actions } = this.props;

    this.formatDatedToDisplay({
      birthday: parseDate(birthday, 'YYYY-MM-DD'),
      passportIssueDate: parseDate(passport && passport.passportIssueDate, 'YYYY-MM-DD'),
      idCardExpiryDate: parseDate(idCard && idCard.idCardExpiryDate, 'YYYY-MM-DD'),
      idCardIssueDate: parseDate(idCard && idCard.idCardIssueDate, 'YYYY-MM-DD'),
      foreignersDocumentExpireDate: parseDate(
        foreignersDocument && foreignersDocument.foreignersDocumentExpireDate,
        'YYYY-MM-DD'
      ),
      foreignersDocumentIssueDate: parseDate(
        foreignersDocument && foreignersDocument.foreignersDocumentIssueDate,
        'YYYY-MM-DD'
      )
    });

    const userInfo = {
      birthday: parseDate(birthday, 'DD/MM/YYYY'),
      addressStruct: {
        region: createOption(region),
        district: createOption(district),
        city: createOption(city),
        street: createOption(street),
        building,
        korp,
        apt,
        index
      },
      isPrivateHouse:
        Array.isArray(isPrivateHouse) &&
        isPrivateHouse.length &&
        isPrivateHouse[0] === privateHouse,
      gender
    };

    if (active === 'idCard') {
      userInfo.idCardNumber = (idCard && idCard.idCardNumber) || null;
      userInfo.idCardIssuedBy = (idCard && idCard.idCardIssuedBy) || null;
      userInfo.idCardIssueDate = parseDate(idCard && idCard.idCardIssueDate, 'YYYY-MM-DD');
      userInfo.idCardExpiryDate = parseDate(idCard && idCard.idCardExpiryDate, 'YYYY-MM-DD');
      userInfo.passportSeries = '';
      userInfo.passportNumber = '';
      userInfo.passportIssuedBy = '';
      userInfo.passportIssueDate = '';
      userInfo.foreignersDocumentType = {};
      userInfo.foreignersDocumentNumber = '';
      userInfo.foreignersDocumentIssuedBy = '';
      userInfo.foreignersDocumentIssueDate = '';
      userInfo.foreignersDocumentExpireDate = '';
      userInfo.foreignersDocumentSeries = '';
    }

    if (active === 'passport') {
      userInfo.passportSeries = passport && passport.pasNumber && passport.pasNumber.passportSeries;
      userInfo.passportNumber = passport && passport.pasNumber && passport.pasNumber.passportNumber;
      userInfo.passportIssueDate = parseDate(passport && passport.passportIssueDate, 'YYYY-MM-DD');
      userInfo.passportIssuedBy = passport && passport.passportIssuedBy;
      userInfo.idCardNumber = '';
      userInfo.idCardIssuedBy = '';
      userInfo.idCardIssueDate = '';
      userInfo.idCardExpiryDate = '';
      userInfo.foreignersDocumentType = {};
      userInfo.foreignersDocumentNumber = '';
      userInfo.foreignersDocumentIssuedBy = '';
      userInfo.foreignersDocumentIssueDate = '';
      userInfo.foreignersDocumentExpireDate = '';
      userInfo.foreignersDocumentSeries = '';
    }

    if (active === 'foreignersDocument') {
      userInfo.foreignersDocumentType = createOption(
        foreignersDocument?.foreignersDocumentType,
        true,
        true
      );
      userInfo.foreignersDocumentSeries = foreignersDocument?.foreignersDocumentSeries || '';
      userInfo.foreignersDocumentNumber =
        (foreignersDocument && foreignersDocument.foreignersDocumentNumber) || null;
      userInfo.foreignersDocumentIssuedBy =
        (foreignersDocument && foreignersDocument.foreignersDocumentIssuedBy) || null;
      userInfo.foreignersDocumentIssueDate = parseDate(
        foreignersDocument && foreignersDocument.foreignersDocumentIssueDate,
        'YYYY-MM-DD'
      );
      userInfo.foreignersDocumentExpireDate = parseDate(
        foreignersDocument && foreignersDocument.foreignersDocumentExpireDate,
        'YYYY-MM-DD'
      );
      userInfo.passportSeries = '';
      userInfo.passportNumber = '';
      userInfo.passportIssuedBy = '';
      userInfo.passportIssueDate = '';
      userInfo.idCardNumber = '';
      userInfo.idCardIssuedBy = '';
      userInfo.idCardIssueDate = '';
      userInfo.idCardExpiryDate = '';
    }

    await actions.updateUserInfo(userInfo);

    this.setState({ errors: [] });
  };

  createValueObject = ({
    birthday,
    passportSeries,
    passportNumber,
    passportIssueDate,
    passportIssuedBy,
    idCardNumber,
    idCardIssueDate,
    idCardIssuedBy,
    idCardExpiryDate,
    foreignersDocumentType,
    foreignersDocumentSeries,
    foreignersDocumentNumber,
    foreignersDocumentIssuedBy,
    foreignersDocumentIssueDate,
    foreignersDocumentExpireDate,
    addressStruct: { region, district, city, building, korp, apt, index, street },
    isPrivateHouse,
    gender
  }) => {
    const { type, name } = street || '';

    return {
      birthday: parseDate(birthday, 'DD-MM-YYYY'),
      tabs: {
        active: idCardNumber
          ? 'idCard'
          : foreignersDocumentNumber
          ? 'foreignersDocument'
          : 'passport',
        passport: {
          pasNumber: {
            passportSeries,
            passportNumber
          },
          passportIssueDate: parseDate(passportIssueDate),
          passportIssuedBy
        },
        idCard: {
          idCardExpiryDate: parseDate(idCardExpiryDate),
          idCardIssueDate: parseDate(idCardIssueDate),
          idCardNumber: idCardNumber || '',
          idCardIssuedBy: idCardIssuedBy || ''
        },
        foreignersDocument: {
          foreignersDocumentType: createOption(foreignersDocumentType, true, true),
          foreignersDocumentSeries: foreignersDocumentSeries || '',
          foreignersDocumentExpireDate: parseDate(foreignersDocumentExpireDate),
          foreignersDocumentIssueDate: parseDate(foreignersDocumentIssueDate),
          foreignersDocumentNumber: foreignersDocumentNumber || '',
          foreignersDocumentIssuedBy: foreignersDocumentIssuedBy || ''
        }
      },
      street: {
        type,
        name
      },
      building: {
        building,
        korp
      },
      apt: {
        apt,
        index
      },
      addressStruct: {
        region: createOption(region, true),
        district: createOption(district, true),
        city: createOption(city, true)
      },
      isPrivateHouse: isPrivateHouse ? [].concat(privateHouse) : null,
      gender
    };
  };

  scrollToInvalidField = (errors) => {
    if (!errors) return;

    try {
      const firstError = deepObjectFind(errors, ({ path }) => !!path);

      if (!firstError) return;

      const replacepath = firstError.path.replace(/\./g, '-');

      const firstInvalidField =
        document.getElementById(firstError.path) ||
        document.getElementById(replacepath) ||
        document.querySelector(`input[name=${replacepath}]`);

      if (!firstInvalidField) return;

      const type = firstInvalidField.getAttribute('type');
      const isHidden = type === 'hidden' || firstInvalidField.style.display === 'none';

      if (isHidden) {
        const parent = firstInvalidField.parentNode;
        parent && parent.scrollIntoView({ block: 'center' });
      } else {
        firstInvalidField.scrollIntoView({ block: 'center' });
      }
    } catch {
      console.log('scrollToInvalidField errors', errors);
    }
  };

  handleConfirmDialog = () => {
    const { history } = this.props;

    this.setState({
      requiredModal: false
    });

    history.replace({
      search: ''
    });
  };

  renderContent = () => {
    const { actions, classes, userInfo, t } = this.props;

    const { error, errors, residentUserData, loading } = this.state;

    if (error) return <ErrorScreen error={error} />;

    if (!userInfo || loading) return <Preloader />;

    const rootDocument = {
      data: {
        ...userInfo,
        userName: userInfo.name,
        ipn: userInfo.ipn
      }
    };

    const schema = schemaToEval(t, residentUserData);

    const rootValue = this.createValueObject(userInfo || {});

    const getPropProps = (name) => schema.properties[name];

    const resident = userIsResident();

    return Object.keys(schema.properties).map((propSchema) => {
      const isStringField = getPropProps(propSchema).type === 'string';

      const itemError = errors.find((err) => err.path.indexOf(propSchema) !== -1);

      return (
        <>
          <SchemaForm
            readOnly={resident}
            key={propSchema}
            rootDocument={rootDocument}
            schema={getPropProps(propSchema)}
            value={isStringField ? rootValue[propSchema] : rootValue}
            rootValue={isStringField ? rootValue[propSchema] : rootValue}
            onChange={(changes) => {
              if (!isStringField) return;

              this.handleStoreCustom({
                ...rootValue,
                [propSchema]: changes instanceof ChangeEvent ? changes.data : changes
              });
            }}
            actions={{
              ...actions,
              setValues: this.handleStoreCustom,
              scrollToInvalidField: this.scrollToInvalidField
            }}
            style={{
              wrapper: classes.popupWrapper,
              header: classes.header
            }}
          />

          <FormHelperText error={itemError}>
            <EJVError
              error={
                itemError
                  ? {
                      keyword: 'pattern',
                      dataPath: `.${propSchema}`,
                      path: propSchema
                    }
                  : null
              }
            />
          </FormHelperText>
        </>
      );
    });
  };

  hightLightRedirectErrors = () => {
    const {
      location: { search },
      userInfo,
      t
    } = this.props;

    const cleared = decodeURIComponent(search).replace(/<\/?[^>]+>/gi, '');

    const prefixed = qs.parse(cleared, { ignoreQueryPrefix: true });

    if (!prefixed?.required_modal) return;

    const profileData = profileSchemaData(userInfo);

    const schema = schemaToEval(t);

    const errors = validateData(profileData, schema, profileData);

    this.setState({
      requiredModal: true,
      errors
    });
  };

  getResidentInfo = async () => {
    const { actions } = this.props;
    const resident = userIsResident();

    if (!resident) return;

    this.setState({ loading: true });

    const record = await actions.getResidentData();

    this.setState({ loading: false });

    if (!record) return;

    this.setState({
      residentUserData: record
    });
  };

  componentWillMount = () => {
    const {
      userInfo: {
        passportIssueDate,
        birthday,
        idCardExpiryDate,
        idCardIssueDate,
        foreignersDocumentExpireDate,
        foreignersDocumentIssueDate
      }
    } = this.props;

    this.formatDatedToDisplay({
      passportIssueDate,
      birthday,
      idCardExpiryDate,
      idCardIssueDate,
      foreignersDocumentExpireDate,
      foreignersDocumentIssueDate
    });
  };

  componentDidMount = () => {
    this.hightLightRedirectErrors();
    this.getResidentInfo();
  };

  render = () => {
    const { t, classes, loading, location } = this.props;
    const { requiredModal } = this.state;

    return (
      <RightSidebarLayout
        backLink="/"
        title={this.componentGetTitle()}
        location={location}
        loading={loading}
      >
        <Content>
          <Typography variant="h4" className={classes.title}>
            {t('Settings')}
          </Typography>

          <div className={classes.wrap}>{this.renderContent()}</div>

          <ConfirmDialog
            fullScreen={false}
            open={requiredModal}
            title={t('AgreeConfirmation')}
            description={t('AgreeConfirmationText')}
            acceptButtonText={t('acceptButtonText')}
            handleConfirm={this.handleConfirmDialog}
          />
        </Content>
      </RightSidebarLayout>
    );
  };
}

const mapStateToProps = ({ auth: { info } }) => ({
  userInfo: info
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    updateUserInfo: bindActionCreators(updateUserInfo, dispatch),
    requestUserInfo: bindActionCreators(requestUserInfo, dispatch),
    getResidentData: bindActionCreators(getResidentData, dispatch)
  }
});

const styled = withStyles(styles)(Profile);
const translated = translate('ProfilePage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
