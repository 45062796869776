import MessagesTab from '../components/MessagesTab';

const userHasUnit = [1000000399];

export default (t) => [
  {
    title: t('Attention'),
    link: '/messages',
    Component: MessagesTab
  },
  {
    title: t('Tasks'),
    link: '/tasks',
    childPathArray: [
      '/tasks/my-tasks',
      '/tasks/unit-tasks',
      '/tasks/closed-unit-tasks',
      '/tasks/closed-tasks'
    ],
    access: {
      unitHasAccessTo: [
        'navigation.tasks.InboxTasks',
        'navigation.tasks.UnitInboxTasks',
        'navigation.tasks.ClosedTasks',
        'navigation.tasks.UnitClosedTasks'
      ]
    }
  },
  {
    title: t('Services'),
    link: '/workflow',
    childPathArray: ['/workflow/drafts', '/workflow/inbox']
  },
  {
    title: t('Registers'),
    link: '/registry',
    access: { unitHasAccessTo: 'navigation.registry.RegistryPage' }
  },
  {
    title: t('OVA'),
    link: '/ova',
    access: { userHasUnit }
  }
];
