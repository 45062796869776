import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Preloader from 'components/Preloader';
import TopMenuLayout from 'layouts/TopMenuLayout';
import { Content } from 'layouts/LeftSidebar';
import { DawerContent } from 'layouts/RightSidebarLayout';
import { setViewedMessagesList } from 'application/actions/messages';
import MessageTable from 'modules/messages/pages/MessageList/components/MessageTable';

const MessageListLayout = ({ location, actions, title, loading, data, handleItemClick }) => {
  const viewedList = (data || []).filter(({ isRead }) => isRead).map(({ id }) => id);

  if (viewedList && viewedList.length) {
    actions.setViewedMessagesList(viewedList);
  }

  return (
    <TopMenuLayout location={location} title={title} loading={loading}>
      {data === null ? (
        <Preloader />
      ) : (
        <DawerContent>
          <Content>
            {data !== null ? (
              <div style={{ maxWidth: 736 }}>
                <MessageTable handleItemClick={handleItemClick} />
              </div>
            ) : null}
          </Content>
        </DawerContent>
      )}
    </TopMenuLayout>
  );
};

MessageListLayout.propTypes = {
  data: PropTypes.array,
  actions: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  handleItemClick: PropTypes.func
};

MessageListLayout.defaultProps = {
  data: null,
  loading: false,
  handleItemClick: () => null
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  actions: {
    setViewedMessagesList: bindActionCreators(setViewedMessagesList, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageListLayout);
