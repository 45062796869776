const GET_OCTOBER_CATEGORIES_SUCCESS = 'GET_OCTOBER_CATEGORIES_SUCCESS';

const initialState = {
  octoberCategories: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OCTOBER_CATEGORIES_SUCCESS:
      return { ...state, octoberCategories: action.payload };
    default:
      return state;
  }
};
