import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import Preloader from 'components/Preloader';
import DataList from 'components/DataList';
import EmptyPage from 'components/EmptyPage';

import endPoint from 'application/endPoints/inboxFiles';
import WorkflowNavigation from 'modules/workflow/pages/WorkflowList/components/WorkflowNavigation';

import TopMenuLayout from 'layouts/TopMenuLayout';
import Content from 'layouts/components/Content';

import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import ListTemplate from './ListTemplate';

const tableDataMap = (item) => ({
  ...item,
  downloadToken: item.documentId,
  name: item.name + '.pdf',
  fileName: item.name + '.pdf'
});

const InboxFileListLayout = (props) => {
  const { t, location, title, loading, data, count } = props;

  const tableData = Array.isArray(data) ? data.map(tableDataMap) : null;

  return (
    <TopMenuLayout location={location} title={title} loading={loading}>
      <Content>
        <WorkflowNavigation location={location} />
        {tableData === null ? <Preloader /> : null}
        {tableData && count ? (
          <DataList
            {...dataTableAdapter(props)}
            ItemTemplate={ListTemplate}
            controls={{
              pagination: true
            }}
          />
        ) : null}
        {count === 0 ? (
          <EmptyPage
            title={t('EmptyListTitle')}
            description={t('EmptyListDescription')}
            id="inbox-empty-page"
            externalReader={true}
          />
        ) : null}
      </Content>
    </TopMenuLayout>
  );
};

InboxFileListLayout.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.array
};

InboxFileListLayout.defaultProps = {
  loading: false,
  data: null
};

const translated = translate('InboxFilesListPage')(InboxFileListLayout);
export default dataTableConnect(endPoint)(translated);
