import tasksModule from 'modules/tasks';
import workflowModule from 'modules/workflow';
import registryModule from 'modules/registry';
import profileModule from 'modules/profile';
import messagesModule from 'modules/messages';
import inboxModule from 'modules/inbox';
import usersModule from 'modules/users';
import reportsModule from 'modules/reports';
import homeModule from 'modules/home';
import adminModule from 'modules/admin';

const modules = [
  messagesModule,
  adminModule,
  tasksModule,
  inboxModule,
  workflowModule,
  registryModule,
  profileModule,
  usersModule,
  reportsModule,
  homeModule
];

export default modules;
