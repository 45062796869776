import * as React from 'react';
import { GridColumnMenu } from '@mui/x-data-grid-premium';

const CustomColumnMenu = (props) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuFilterItem: null,
        columnMenuAggregationItem: null,
        columnMenuGroupingItem: null
      }}
    />
  );
};

export default CustomColumnMenu;
