/* eslint-disable react/prop-types */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { Toolbar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

const styles = (theme) => ({
  root: {
    padding: 24,
    [theme.breakpoints.up('sm')]: {
      padding: '160px 80px 80px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 80,
      paddingBottom: 20
    }
  },
  name: {
    fontSize: 56,
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '26px'
    }
  }
});

const CongratMessage = ({ t, classes, firstName, companyName, userData }) => {
  const location = useLocation();
  const isSbuApplicant = location.pathname.startsWith('/sbu-applicants');
  const name = userData?.givenName || capitalizeFirstLetter(firstName) || companyName;

  return (
    <Toolbar className={classes.root}>
      <Typography variant="h1" className={classes.name}>
        {isSbuApplicant ? t('ContratuationSBU', { name }) : t('Contratuation', { name })}
      </Typography>
    </Toolbar>
  );
};

const mapStateToProps = ({
  auth: {
    info: { firstName, companyName, ipn }
  }
}) => ({
  firstName,
  companyName,
  ipn
});

const mapDispatchToProps = () => ({ actions: {} });

const styled = withStyles(styles)(CongratMessage);

const translated = translate('Layout')(styled);

export default connect(mapStateToProps, mapDispatchToProps)(translated);
