import translation from 'core/translation/en-GB';

const {
  App,
  Navigator,
  TaskPage,
  WorkflowPage,
  WorkflowListPage,
  Errors,
  InboxFilesListPage,
  MessageListPage,
  Elements,
  TaskListPage,
  DatePicker,
  PageTitles
} = translation;

export default {
  ...translation,
  App: {
    ...App,
    SwitchUser: 'Use another account',
    'Failed to fetch subtitle': ' '
  },

  Navigator: {
    ...Navigator,
    BackToMain: 'Return to the site',
    UserInformation: 'Information about me',
    Telegram: 'Telegram',
    Messenger: 'Messenger',
    Viber: 'Viber',
    LabelLogoDiia: 'Diia- Government Services Online'
  },

  TaskPage: {
    ...TaskPage,
    CreatePDFBtn: 'Next',
    NextStepBtn: 'Next',
    Steps: 'Step {{activeStep}} of {{steps}}',
    PersonalDataAgree:
      'Read the <a href="https://diia.gov.ua/policy" target="_blank">notification on personal data processing</a>',
    Agree: 'Understood',
    SaveBtnDocument: 'Save the application',
    PrintBtnDocument: 'Print the application',
    PrintBtnDocumentAttach: 'Print attachment {{index}}',
    SavetnDocumentAttach: 'Save attachment {{index}}',
    CheckAndSign: 'Check and sign the application',
    Check: 'Check and confirm the data',
    WriteUs: 'Write to us in the messenger',
    SigningDialogTitle: 'Please sign the document',
    OpenPdf: 'View pdf',
    AddressRegistration: 'Registration address',
    InformationAboutOwners: 'Information about co-owners',
    SignersBirthdayTitle: 'Date of birth',
    SignersIpnTitle: 'Taxpayer Identification Number',
    SignersEmailTitle: 'Email',
    SignersPassportTitle: 'Passport series and number',
    SignersSignedTitle: 'Confirmed',
    SignersWaitingTitle: 'Waiting for confirmation',
    SignersRejectedTitle: 'Rejected',
    SignersPIB: 'Name',
    Collapse: 'Collapse',
    UnCollapse: 'Expand',
    ShortYearText: 'year',
    ChangeAddress:
      'Your address information is out of date. Please update your address in your profile.',
    GoToProfile: 'Go to profile',
    HandleTask: 'Handle the task',
    CancelHandling: 'Cancel',
    HandlingDescription: 'Handled by {{time}}, {{user}}',
    StepIndex: 'Step {{index}}. {{title}}'
  },

  WorkflowPage: {
    ...WorkflowPage,
    Progress: 'Progress',
    Documents: 'Documents',
    StepNameLabel: 'Step {{index}}. {{label}}',
    ProcessStatuses: 'Service Statuses'
  },

  WorkflowListPage: {
    ...WorkflowListPage,
    EmptyListTitle: 'You have not ordered services on the Diia portal yet',
    DueDate: 'Expected date of registration',
    OrderMore: 'Order more',
    Delete: 'Delete',
    ShowAll: 'All services',
    EmptyDraftsListTitle: 'You have not created drafts in the Diia portal yet',
    ServiceSearch: 'Search for services',
    NotFound: 'Not found',
    TestProcesses: 'Test services'
  },

  Errors: {
    ...Errors,
    FailGettingOctoberHeader: 'An error occurred while loading the header',
    FailGettingOctoberFooter: 'An error occurred while loading the footer',
    FailGettingOctoberCategories: 'An error occurred while loading categories',
    FailLoadingQR: 'An error occurred while loading',
    FailClearExternalReaderCache: 'An error occurred while clearing the cache',
    HandlingUserExists: 'The task is already in progress, refresh the page to view the performer'
  },

  InboxFilesListPage: {
    ...InboxFilesListPage,
    Download: 'Download',
    Preview: 'Preview',
    EmptyListTitle: 'You have not received any documents on the Diia portal yet'
  },

  Layout: {
    Services: 'Services',
    HowDoesItWorks: 'How it works',
    News: 'News',
    Support: 'Support',
    About: 'What is Diia',
    Else: 'Other',
    Contratuation: '👋 Congratulations, {{name}}',
    Attention: 'Pay attention',
    Inbox: 'My documents',
    MyProfile: 'Information about me',
    Tasks: 'Tasks',
    CitizenServices: 'Citizens',
    DocumentsAndCitizenship: 'Documents and citizenship',
    Education: 'Education',
    PensionsBenefitsAndAssistance: 'Pensions, benefits and assistance',
    Transport: 'Transport',
    Family: 'Family',
    Work: 'Work',
    Health: 'Health',
    ConstructionAndRealEstate: 'Land, construction, real estate',
    DiiaCity: 'Diia.City',
    Entrepreneurship: 'Entrepreneurship',
    LicensesAndPermissions: 'Licenses and permissions',
    SecurityAndLawOrder: 'Security and law and order',
    Environment: 'Environment',
    HelpAndExtracts: 'Help and extracts',
    BusinessServices: 'Business',
    Trade: 'Trade',
    BusinessAbroad: 'Business abroad',
    MedicineAndPharmaceuticals: 'Medicine and pharmaceuticals',
    Financing: 'Financing',
    CreatingABusiness: 'Creating a business',
    LegalRelations: 'Legal relations',
    Taxes: 'Taxes',
    ExcerptsAndHelp: 'Extracts and help',
    EnvironmentalProtection: 'Environmental protection',
    Employment: 'Employment',
    QualityControl: 'Quality control of products and goods',
    OccupationalHealth: 'Occupational health and safety',
    NonProfitOrganizations: 'Non-profit organizations',
    QuestionsAndAnswers: 'Questions and answers',
    Logout: 'Logout',
    MyProfileTitle: 'My Profile',
    Subscribes: 'Follow us here:',
    PersonalData: 'Processing of personal data',
    PersonalDataAgreement: 'Consent to the processing of personal data',
    ListOfServices: 'List of services',
    More: 'More',
    DiiaBusiness: 'Diia. Business',
    DiiaOsvita: 'Diia. Digital education',
    DiiaMobileApp: 'Diia mobile application',
    HackCorona: '#HackCorona',
    Registers: 'Registers',
    Guide: 'Guide to public services',
    Prozorro: 'Prozorro',
    LogoDiiaUsage: 'Use of the Diia logo',
    IDGOVUA: 'id.gov.ua',
    AgreementForPartners: 'Accession Agreement for partners',
    KMU: 'Government portal',
    DiiaOpenData: 'Diia. Open Data',
    OpenDataPortal: 'Open Data Portal',
    Vzaemodiia: 'VzaemoDiia',
    LabelLogoDiia: 'Diia - Public services online',
    /* tab, menu links */
    EResidentWhatIsIt: 'What is it',
    EResidentWhoIsItFor: 'Who is it for',
    EResidentWhatDoYouGet: 'What do you get',
    EResidentHowToBecome: 'How to become',
    EResidentNews: 'News',
    EResidentHelp: 'Help',
    EResidentUser: 'Users',
    EResidentCorrespondence: 'Correspondence',
    EResidentDocuments: 'Documents',
    EResidentStatistics: 'Statistics',
    Applicants: 'Applicants',
    eResidents: 'e-Residents',
    Correspondence: 'Correspondence',
    OVA: 'OVA'
  },
  MyBusinessPage: {
    MyBusinessPageTitle: 'My business',
    MyBusiness: 'My business',
    Name: 'Name',
    MyBusinessDetailsCollapse: 'Related Services',
    ShortName: 'Short name',
    CreatingDate: 'Date of creation',
    Owners: 'Owners',
    Header: 'Head, or other person authorized to act',
    Status: 'Status of the company',
    StatusRegistred: 'Registered',
    StatusClosed: 'Closed',
    RegistrationAddress: 'Place of registration',
    RegistrationAddressStatic: '24, Dilova Street, Kyiv, 03150, Ukraine',
    BankName: 'Bank name',
    BankStatus: 'Status of the bank account',
    Opened: 'Opened',
    Closed: 'Closed',
    Specialization: 'KVED',
    AuthorizedCapitalSize: 'Authorized capital size',
    EmptyListTitle: 'We did not find your business in the register',
    EmptyListDescription: 'Opening a private entrepreneur',
    '500 - {"error":{"code":500103, "message": "External service timeout error."}}':
      'There was an error retrieving data',
    ShowAll: 'Show all KVEDs',
    HideAll: 'Hide',
    ErrorMessage: 'Error while accessing the registry. Please try again in a few minutes',
    ReloadPage: 'Refresh page',
    Phone: 'Phone number',
    Email: 'Email address',
    FoundingDocument: 'Founding document type',
    MyStaticQR: 'Diia.QR',
    OfficialAuthorization: 'Official authorization',
    StaticQR: 'My static QR codes',
    MyQRAuthPageLink: 'Service authorization',
    ComplexBtnRedirect: 'Order services for business',
    ComplexBtnRedirectStart: 'Start your own business'
  },

  Information: {
    LinkedServices: 'Related services',
    UserInformation: 'Information about me',
    MyBusiness: 'Related services',
    MyStaticQR: 'Diia.QR',
    OfficialAuthorization: 'Service authorization',
    MyQRAuthPage: 'Service Account in Action',
    Bussines: 'Business',
    BussinesDescription:
      'Information about your individual entrepreneur and сlassification of types of economic activity, legal entities and public organizations related to you.'
  },
  ProfilePage: {
    Settings: 'Settings',
    MyProfileTitle: 'My Profile',
    Personal: 'Personal data',
    Birthday: 'Date of birth',
    Phone: 'Phone number',
    Email: 'Email',
    Ipn: 'Taxpayer Identification Number',
    PassportData: 'Passport data',
    IssueDate: 'Issued',
    Passport: 'Passport series and number',
    IssuedBy: 'Issued by',
    Registration: 'Place of registration',
    Address: 'Registration address',
    IdCardNumber: 'Number',
    ChangedData: 'Has the data changed?',
    ChangedDescription: 'Find out what to do if your data has changed',
    FindOut: 'Find out',
    Edit: 'Edit',
    Save: 'Save',
    StreetName: 'Street name',
    PassportCard: 'Passport',
    IdCard: 'ID card',
    Serie: 'Series',
    Number: 'Number',
    AgreeConfirmation: 'Your profile is missing data',
    AgreeConfirmationText:
      'To continue working in your personal account, please update the data in your profile.',
    acceptButtonText: 'Understood',
    indexSample: 'You can find out your index',
    index: 'Index',
    apt: 'Apartment number',
    isPrivateHouse: 'private house',
    korp: 'Corps',
    building: 'House',
    streetErrorText: 'It can contain only Ukrainian letters, a hyphen and a space',
    street: 'Type of street',
    region: 'Region or the city of Kyiv',
    district: 'Settlement or district',
    city: 'Village, settlement, district of the city',
    RegistrationAddress: 'Registered place of residence',
    IssuedDate: 'Date of issue',
    foreignersDocumentSeries: 'Residence permit number',
    ForeignPassportIssueDate: 'Issued',
    ForeignPassportIssuedBy: 'Issued by',
    ForeignPassportExpired: 'Valid until',
    PassportExpired: 'Valid Until',
    PassportNumber: 'ID card number',
    birthDayDialogTitle: 'Enter your date of birth',
    birthDateErrorText: 'Error in date of birth, it cannot be greater than the current one',
    birthDateErrorText1: 'The month has fewer days than you specified',
    birthDateErrorText2: 'Not a leap year. There are only 28 days in February',
    birthDateErrorText3: 'You must be over 14 years old',
    birthDateErrorText4: 'View date of issue of passport or ID card',
    PassportCardErrorText: 'Must contain 2 letters',
    PassportCardErrorText1: 'The number entered is incorrect. The number must contain 6 digits',
    day: 'day',
    month: 'month',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    year: 'year',
    yearErrorText: 'Year of birth must be greater than 1900',
    Gender: 'Gender',
    male: 'male',
    female: 'female',
    PassportIssueDateErrorText: 'Correct the issue date, it cannot be older than the current one',
    PassportIssueDateErrorText1: 'Passport issue date cannot be earlier than 1994',
    PassportIssueDateErrorText2: 'Passport cannot be obtained under 14',
    PassportIssueDateErrorText3: 'Not a leap year. There are only 28 days in February',
    PassportIssueDateErrorText4: 'There are fewer days in the month than you specified',
    PassportIssuedByErrorText: 'Cannot be less than ten characters',
    PassportIssuedByErrorText1: 'Cannot contain Latin letters and symbols',
    IdCardErrorText: 'Number must contain 9 digits',
    idCardIssueDateErrorText: 'Correct the issue date, it cannot be greater than the current one',
    idCardIssueDateErrorText1: 'ID card issue date cannot be earlier than 2016',
    idCardIssueDateErrorText2: 'You cannot get an ID card under the age of 14',
    idCardIssueDateErrorText3: 'Not a leap year. There are only 28 days in February',
    idCardIssueDateErrorText4: 'There are fewer days in the month than you specified',
    RCardExpired: 'Valid until',
    idCardExpiryDateErrorText: 'Your ID card has expired',
    idCardExpiryDateErrorText1: 'There are fewer days in the month than you specified',
    idCardExpiryDateErrorText2: 'Not a leap year. There are only 28 days in February',
    idCardIssuedBy: 'Issuing Authority',
    idCardIssuedByErrorText: 'Must contain 4 digits',
    foreignersDocument: 'Residence permit',
    foreignersDocumentType: 'Choose a document type:',
    foreignersDocumentSeriesErrorText: 'Must contain 2 letters',
    foreignersDocumentSeriesErrorText1: 'Can only contain letters',
    foreignersDocumentNumberErrorText: 'Number must contain 9 digits',
    foreignersDocumentNumberErrorText1: 'The number must contain 6 digits',
    foreignersDocumentNumberErrorText2: 'The number must start with the digit 8',
    foreignersDocumentNumberErrorText3: 'Number must start with 9',
    foreignersDocumentIssueDateErrorText:
      'The issue date cannot be greater than the current one. Please specify the exact date ',
    foreignersDocumentIssueDateErrorText1: 'Issue date cannot be earlier than 2018',
    foreignersDocumentIssueDateErrorText2: 'The selected month has fewer days than you specified',
    foreignersDocumentIssueDateErrorText3: 'Not a leap year. There are only 28 days in February.',
    foreignersDocumentExpireDateErrorText: 'Your residence permit has expired',
    foreignersDocumentExpireDateErrorText1: 'The selected month has fewer days than you specified',
    foreignersDocumentExpireDateErrorText2: 'Not a leap year. There are only 28 days in February',
    streetOption: 'street',
    alley: 'alley',
    square: 'square',
    prospect: 'prospect',
    boulevard: 'boulevard',
    deadend: 'deadend',
    lift: 'lift',
    embankment: 'embankment',
    highway: 'highway',
    microdistrict: 'micro district',
    residentialComplex: 'residential сomplex',
    residentialArray: 'residential array',
    travel: 'travel',
    maidan: 'maidan',
    quarter: 'quarter',
    entrance: 'entrance',
    other: 'other'
  },
  DocumentListPage: {
    PageTitle: 'My Documents',
    Documents: 'Submit electronically',
    DocumentsWithOriginals: 'Valid only with originals',
    PensionCard: 'Pension certificate',
    EmptyListTitle: "Don't see your documents?",
    EmptyListText:
      "A driver's license and a vehicle registration certificate are now available in the office.",
    EmptyListText2:
      'If you have these documents, but they are not available in Diia - go <a href="https://e-driver.hsc.gov.ua/" target="_blank">to the driver\'s electronic account</ a> for verification.',
    DocumentListPageDisclaimer:
      'If you see an error in the vehicle registration certificate and driver\'s license, verify <a href="https://e-driver.hsc.gov.ua/" target="_blank">in the driver\'s electronic account< /a> to fix it.'
  },

  Footer: {
    Subscribes: 'Follow us here:',
    Copyring: '{{year}}. All rights reserved.',
    LabelLogoDiia: 'Diia - Government Services Online'
  },

  ContactFormPage: {
    ProfileEmail: 'Edit personal data',
    ProfilePhone: 'Edit personal data',
    PrevStepBtn: 'Back',
    NextStepBtn: 'Next',
    ProfileEmailSuccessTitle: 'You have successfully changed your email address',
    ProfileEmailSuccessSubTitle:
      'All copies of your documents will be sent to this email address. No spam',
    ProfilePhoneSuccessTitle: 'You have successfully changed your phone number',
    ProfilePhoneSuccessSubTitle: 'If necessary, you will be contacted on this number. No spam',
    Done: 'Done',
    EmailTypingStep: 'Enter a new email address',
    EmailConfirmationStep: 'Please confirm your email address',
    PhoneTypingStep: 'Enter a new phone number',
    PhoneConfirmationStep: 'Confirm your phone number',
    EmailAttention: 'Please pay attention',
    EmailAttentionText:
      'We will send copies of all your documents and applications to this address. No spam',
    PhoneAttention: 'Please note',
    PhoneAttentionText:
      'This number will be used to contact you if necessary. Please make sure you enter a valid number'
  },
  MessageListPage: {
    ...MessageListPage,
    ShowMore: 'Show full message',
    ShowLess: 'Hide'
  },
  Elements: {
    ...Elements,
    DropFiles: 'Drag and drop files here or {{link}}',
    SelectFiles: 'upload them from your media',
    MaxFileSizeLimit: 'up to {{size}}',
    FileTypeLimit: '{{types}}',
    Back: 'Back',
    EmailConfirnmation:
      'We have sent a code to your email address. Please enter it to confirm your details.'
  },

  ExternalReaderHelp: {
    ContactUs: 'Report a problem'
  },
  WebChat: {
    telegram: 'Telegram',
    messenger: 'Messenger',
    viber: 'Viber'
  },
  TasksListSearch: {
    TasksListSearch: 'Search by tasks',
    Number: 'Application number',
    Applicant: 'Applicant',
    Workflow: 'Service',
    Performer: 'Performer',
    WithoutPerformer: 'Without performer',
    Search: 'Search',
    Clear: 'Clear',
    NoPerformer: 'No performer',
    NewItems: 'Newly created',
    ByNumber: 'By claim number',
    YetActive: 'Yet to expire',
    LastFinished: 'Last completed',
    DateCreation: 'Date of creation',
    SelectLabel: 'Task status',
    SelectClosedTask: 'Closed tasks',
    SelectWaiting: 'Waiting for resolution',
    SelectStatusAll: 'All',
    AllTasks: 'All',
    ReadTasks: 'In processing',
    NotReadTasks: 'New'
  },

  TaskListPage: {
    ...TaskListPage,
    NoResultList: 'No records were found for the entered data',
    NoResultListDescr: 'Try to change the search parameters',
    NewTask: 'New',
    InProgress: 'In progress',
    HandleUser: 'Handling {{user}}',
    RejectSign: 'Signature revoked',
    DoingSign: 'Waiting for your signature',
    DoneSign: 'Waiting for other signatures',
    Done: 'Done',
    HandledUser: 'Handled by {{user}}',
    citizenshipCountry: 'Citizenship',
    citizenshipCountryUkrNameShort: 'Country of citizenship',
    residenceCountryUkrNameShort: 'Country of residence',
    residenceCityUkr: 'City of residence'
  },
  Proceedings: {
    ErrorMessage: 'Error accessing the registry. Please try again in a few minutes.',
    ReloadPage: 'Refresh page',
    ProceedingsTitle: 'Enforcement proceedings',
    ProceedingsDetailsTitle: 'Enforcement proceedings',
    ProcessListTitle: 'All proceedings',
    ProceedingNumber: 'Enforcement proceeding number',
    EmptyListTitle: 'Congratulations!',
    EmptyListDescription: 'No enforcement proceedings in which you are identified as a debtor',
    OpeningDate: 'Date of opening of enforcement',
    Author: 'Who issued the document',
    Opened: 'Opened',
    Closed: 'Closed',
    Pending: 'Payment is waiting for crediting',
    NeddsClarification: 'Needs clarification',
    NeddsClarificationToolTip:
      'The status "Needs clarification" appears when the amount to be paid is zero or with a minus sign. In this case, please contact the executor to clarify the details using the contacts specified in the detailed information about the enforcement proceedings',
    OpenPdf: 'View pdf',
    MakePayment: 'Make payment',
    Payed: 'Paid',
    PayedDescr: 'You have already paid the debt under enforcement proceedings № {{num}}',
    Detailed: 'Detailed',
    ToPayment: 'To payment',
    DownloadCheck: 'Download receipt',
    Back: 'Back',
    PendingTooltip:
      'Your payment will be processed by the Treasury Service within a few days. When the payment is credited by the executor, the status of the proceedings will change. You can always find the receipt of payment in the detailed information about the enforcement proceedings',
    ASVPnum: 'Number in the ASVP',
    VpSecurityCode: 'Access identifier',
    MoneyToPay: 'Amount to be recovered',
    TotalMoneyToPay: 'Total amount to be paid',
    PayedSum: 'Paid',
    SumExecutiveFee: 'Executive fee',
    SumFines: 'The amount of the fine',
    SumAsvpSpending: 'The amount of the executive expenses',
    UAH: 'UAH',
    Debtor: 'Debtor',
    Collector: 'Collector',
    Decree: 'Documents',
    AllDocs: 'All documents',
    OpeningDateShort: 'Opening date',
    Organization: 'SES body / Organization',
    FinishingDate: 'Date of completion/return without execution',
    ExecutiveDocument: 'Executive document',
    ProceedingAuthor: 'By whom the executive document was issued',
    Performer: 'Performer',
    Phone: 'Phone',
    Email: 'Email',
    Collapse: 'Collapse',
    Payment: 'Payment',
    WhoPay: 'Payer',
    PaymentDest: 'Recipient',
    PaymentDestInfo: 'Information about the recipient',
    DVS: 'DVS authority',
    EDRPOU: 'UNIFIED STATE REGISTER OF LEGAL ENTITIES',
    Bank: 'Bank',
    MFO: 'MFO of the State Treasury Service',
    Check: 'Current account',
    IBAN: 'IBAN',
    Destination: 'Payment destination',
    PIB: 'PIB',
    RNOKPP: 'RNOKPP',
    SumDebt: 'Debt amount',
    SumToPay: 'Amount to pay',
    SuccessTitle: 'Thank you!',
    Understand: 'Understand',
    SuccessSubTitle: 'Payment was successful',
    Print: 'Print',
    DownloadPdf: 'Download pdf',
    PendingPayment: 'Payment is pending',
    departmentPhone: 'Phone',
    SuccessText:
      'Your payment will be processed in about 3 days. When the payment is credited by the bailiff, the enforcement status will change to "Completed".',
    AboutProceedings: 'What is an enforcement proceeding?',
    ProceedingsDescription:
      'This is the execution of decisions of courts and other bodies (officials), which are subject to enforcement in case of failure to comply with them voluntarily',
    MyProceedings: 'My proceedings',
    OpenProceedings: 'Open proceedings: ',
    StoppedProceedings: 'Stopped proceedings: ',
    ClosedProceedings: 'Closed proceedings: ',
    AllProceedings: 'All proceedings: '
  },

  SignEncryptSignDialog: {
    NoEncryptCertsPassedError: 'At least one certificate must be specified to use encryption',
    SuccessMessage: 'The data was successfully encrypted',
    ButtonText: 'Encrypt data',
    DialogTitle: 'Encrypt data using private key',
    DialogButtonText: 'Read private key'
  },
  MyQRSharingPage: {
    MyQRSharingPageTitle: 'My Diia.QR',
    EmptyListTitle: 'You don’t have any QR codes yet',
    EmptyListDescription:
      'To create one, you need to become an Diia partner. To become a partner of Diia, click the button below.',
    ActiveQr: 'Active',
    InActiveQr: 'Disabled',
    MyBusiness: 'My business',
    Requests: 'Services',
    DocumentsToCopy: 'Documents you will receive copies of',
    Email: 'Email',
    IssuedDate: 'Date of issue',
    DueDate: 'Due date',
    addQR: 'Add QR code',
    settings: 'Settings',
    DownloadQR: 'Download SVG',
    DownloadPDF: 'Download PDF',
    Download: 'Download',
    Edit: 'Edit',
    ContinuewDueDate: 'Extend the expiration date',
    DeleteCode: 'Delete the code',
    Update: 'Update the code',
    Delete: 'Delete',
    Cancel: 'Do not delete',
    Accept: 'Delete',
    Attention: 'Attention!',
    ConfirmDeleteTitle: 'Do you really want to delete the QR code of the service?',
    ConfirmDeleteDescr:
      'If you delete the QR code, you will no longer be able to request copies of digital documents',
    ConfirmDeleteDescrAuthPage:
      'In this case, officials will not be able to log in to Diia using the QR codes of this branch',
    AddQrPopupTitle: 'Add service',
    Add: 'Add',
    AddQRText: 'Specify the list of services for which you need copies of digital documents',
    AddQRText2: 'Select the package of documents you want to request',
    EditService: 'Edit the service',
    DepartmentsSettings: 'Department settings',
    AddDepartment: 'Add department',
    RegisterOrganization: 'Become a partner',
    MyQRDisclaimer:
      'You can register as an acquirer, for this you need to fill in the form, provided that you are authorized in the system using the EDS of the IGS',
    ErrorMessage: 'Error while accessing the registry. Please try again in a few minutes',
    ReloadPage: 'Refresh page',
    EmptyListTitleBranches:
      'You have not created any Action.QR yet. To create one, click on "Add Branch" and follow the prompts.',
    EmptyListTitleBranchesAuthPage:
      'You have not created any branches yet. To do so, click "Add branch".',
    'internal-passport': 'Passport of a citizen of Ukraine',
    'foreign-passport': 'Biometric foreign passport',
    'id-card': 'Passport of the citizen of Ukraine in the form of ID-card',
    'taxpayer-card': 'Taxpayer card (RNOKPP)',
    'birth-certificate': 'Birth certificate',
    askDocuments: 'Requests copies of documents:',
    passport: 'Passport of a citizen of Ukraine in the form of an ID-card or biometric passport',
    'reference-internally-displaced-person': 'Reference of an internally displaced person (IDP)',
    yearShort: 'year',
    dueDateImage: 'Code valid until ',
    MadeFor: 'Issued for:',
    PDFTitle: 'QR code for service authorization in Diia',
    PDFTitleStatic: 'QR code for document sharing in Diia',
    PDFdescription:
      'To perform service authorization in Diia, scan the QR code with your mobile phone camera. This will automatically authorize you in Diia. In this mode you can only check the validity of electronic documents.',
    PDFdescriptionStatic:
      'To send copies of digital documents from Diia, scan the QR code with the scanner in Diia and confirm the request to transfer documents. The QR code scanner is located in the upper right corner in the Documents section of the Diia app',
    NoRNOKPPError: 'You do not have a RNOCPP. Sorry, you cannot receive the service',
    MyQrAdminPageTitle: 'Partners',
    InputSearchTitle: 'Search by name or full name',
    partnersCount: '{{count}} partners',
    TableName: 'Name',
    TableIpn: 'RNOCPP or EDRPOU',
    TableCity: 'Settlement',
    TableEmail: 'Email',
    TableDate: 'Date of creation',
    RegionRegister: 'Region, Kyiv or Sevastopol',
    DistrictRegister: 'City or district',
    CityRegister: 'Village, town, city district',
    CreationDate: 'Date of creation',
    Status: 'Status',
    'driver-lecense': 'Drivers license',
    From: 'From',
    Till: 'Until',
    TableRegion: 'Region',
    okLabel: 'Ok',
    cancelLabel: 'Cancel',
    Profile: 'Profile',
    QRCodes: 'QR codes',
    Ipn: 'RNOKPP',
    Edrpou: 'EDRPOU',
    Back: 'Back',
    TurnOff: 'Disconnect partner',
    TurnOn: 'Connect partner',
    Phone: 'Phone',
    StatusActive: 'Active',
    StatusInActive: 'Disconnected',
    maxDateMessage: 'The "From" date cannot be later than the "To" date',
    downloadAct: 'Application for accession',
    MyQRAdmin: 'Moderation Diia.QR',
    MyQrAdminError: 'Page is unavailable',
    DeactivateReason: 'Select the reason for deactivation',
    DeactivateReasonText: 'Specify the reason',
    clearLabel: 'Clear',
    requiredReason: 'Required to fill in the reason',
    AttentionTurnOffAcquireTitle: 'Turn off the partner',
    Filters: 'Filters',
    AttentionTurnOffAcquire:
      'Are you sure you want to deactivate affiliate {{userName}}? If so, the partner will not be able to receive copies of digital documents',
    OfficialAuthorization: 'Service account in Diia',
    StaticQR: 'Diia.QR',
    RelatedServices: 'Related services',
    Settings: 'Settings',
    DownloadAuthQr: 'Download QR code',
    serviceEntranceError: 'You need to create a service account in Diia',
    ServiceEntranceProcess: 'Create an account',
    NoItems: 'There are no services created in this department yet',
    NoItemsDepartment: 'No departments created yet',
    NoItemsEmptyScope: 'Not found',
    Worker: 'Position',
    BlockedStaticQR: 'Your static account has been blocked, please contact support',
    BlockedServQR: 'Your service account has been blocked, please contact support.'
  },
  EResidentUser: {
    User: 'User card',
    UserCardTitle: 'User card',
    PageInfo: 'Information',
    PageHistory: 'History',
    RCardDate: 'Date',
    RCardDateBirth: 'Date of birth',
    RCardRnkpp: 'RNOCPP',
    RCardEmail: 'Email address',
    RCardPhone: 'Phone number',
    RCardPassport: 'Document number',
    RCardPublished: 'Date of issue',
    RCardIssuedBy: 'Issued by',
    RCardAddressRegister: 'Registration address',
    RCardPlaceRegister: 'Place of registration',
    CancelButtonText: 'Skip',
    AcceptButtonText: 'Submit',
    CheckButtonText: 'Check data',
    BackButtonText: 'Back',
    ModalTitle: 'Specify the reason for rejection',
    ModalInputLabelDiscard: 'Write the reason for rejection',
    MfaCabinet: 'Cabinet of the MFA',
    RegPlace: 'Place of registration',
    passportData: 'Passport data',
    UserSex: 'Sex',
    RCardExpired: 'Valid until',
    RCardCountry: 'Country of issue',
    residenceCountry: 'Country',
    residenceCity: 'City',
    residenceStreetName: 'Street name',
    residenceBuildingNumber: 'Building number',
    residenceApartmentNumber: 'Apartment number',
    residencePostalCode: 'Postal code',
    MfaStatus_0: 'Status is not set',
    MfaStatus_pending: 'Status is pending',
    MfaStatus_1: 'In processing',
    MfaStatus_2: 'Reserved RNOCPP',
    MfaStatus_3: 'Verified',
    MfaStatus_4: 'Rejected by Administrator',
    MfaStatus_5: 'Rejected by SBU/MIA',
    MfaStatus_6: 'Rejected by MFA',
    MfaStatus_7: 'The verification period has expired',
    MfaStatus_8: 'Terminated'
  },
  DatePicker: {
    ...DatePicker,
    From: 'From',
    To: 'To',
    CreatedAt: 'Date of application'
  },
  EResidentUserList: {
    UserList: 'Users',
    SearchByUser: 'Search by name',
    SearchResult: 'Results: ',
    DateIdentification: 'Date of e-Resident status assignment',
    DateApplication: 'Date of application',
    Nationality: 'Nationality',
    RCardDateStatus: 'Date of status assignment',
    RCardDateApplication: 'Date of application',
    RCardNationality: 'Nationality',
    Choose: 'Choose',
    PageAll: 'All',
    PageApplicants: 'Applicants',
    PageResidents: 'e-Residents',
    EmptyListTitle: 'No records were found for the entered data',
    EmptyListDescription: 'Try changing the search parameters',
    EmptyListTitleFiltered: 'EmptyListTitleFiltered',
    EmptyListDescriptionFiltered: 'EmptyListDescriptionFiltered',
    FiltersSidebar: 'Filters',
    Country: 'Citizenship',
    CreatedDate: 'Date of application',
    citizenshipCountry: 'Citizenship',
    residencyStatusDateOutput: 'Date of status assignment',
    residenceStatusDate: 'Date of e-Residency status assignment',
    MfaStatus_0: 'Status is not set',
    MfaStatus_pending: 'Status is pending',
    MfaStatus_1: 'In processing',
    MfaStatus_2: 'Reserved RNOCPP',
    MfaStatus_3: 'Verified',
    MfaStatus_4: 'Rejected by Administrator',
    MfaStatus_5: 'Rejected by SBU/MIA',
    MfaStatus_6: 'Rejected by MFA',
    MfaStatus_7: 'The verification period has expired',
    MfaStatus_8: 'Terminated'
  },

  SbuModule: {
    SearchByUser: 'Search by name',
    SearchResult: '{{count}} results',
    Export: 'Export',
    Import: 'Import',
    previous: 'Previous page',
    next: 'Next page',
    Country: 'Citizenship',
    CreatedDate: 'Date of application',
    IdDate: 'Date of assignment of e-Resident status',
    CreatedAt: 'Date of application',
    citizenshipStatusDate: 'Date the e-Residency status was granted',
    citizenshipCountry: 'Citizenship',
    residencyStatusDateOutput: 'Date of status assignment',
    eResidentsSearch: 'Search by name or auxiliary information about e-resident',
    birthDate: 'Date of birth',
    sex: 'Sex',
    fopDate: 'Date of registration of the individual entrepreneur',
    activityStatus: 'Status of the entrepreneur',
    kved: 'KVED',
    summ: 'Contract amount',
    showMore: 'Show more',
    EmptyListTitle: 'No records were found for the entered data',
    EmptyListDescription: 'Try to change the search parameters',
    ExportSuccess: 'The export was successful',
    ExportError: 'Error: {{error}}',
    FiltersSidebar: 'Filters'
  },

  PageTitles: {
    ...PageTitles,
    SbuUserList: 'Cabinet of the SBU/MFA',
    MfaCabinet: 'MFA Cabinet'
  },
  OVA: {
    OVA: 'Points of invincibility',
    OVADescription: 'Here you can view the list of Points of invincibility in your region',
    OVATitle: 'OVA'
  }
};
