import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Preloader from 'components/Preloader';
import PdfDocument from 'components/FilePreview/components/PdfDocument';
import CodeDocument from 'components/FilePreview/components/CodeDocument';
import UnsupportedComponent from 'components/FilePreview/components/UnsupportedComponent';

const XlsxViewer = lazy(() => import('components/FilePreview/components/xslx/xslx'));
const FileViewer = lazy(() => import('react-file-viewer'));

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('xl')]: {
      left: 'unset'
    },
    '& .rpv-core__viewer > div > div': {
      overflow: 'inherit!important'
    }
  },
  toolbar: {
    position: 'sticky',
    bottom: 0,
    padding: '0 10px'
  },
  unsupportedContainer: {
    padding: 40
  },
  printIcon: {
    position: 'absolute',
    bottom: 65,
    left: 10,
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    }
  }
}));

const FilePreview = ({ file, fileType, darkTheme, customToolbar, open, withPrint }) => {
  const [error, setError] = React.useState(false);
  const classes = useStyles();

  const checkImageRenderError = React.useCallback(() => {
    const formatsToCheck = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

    if (!formatsToCheck.includes(fileType)) return null;

    return <img src={file} hidden={true} alt="" onError={setError} />;
  }, [file, fileType]);

  return (
    <div className={classNames(classes.root, classes[fileType])}>
      {fileType === 'pdf' ? (
        <PdfDocument file={file} darkTheme={darkTheme} customToolbar={customToolbar} open={open} withPrint={withPrint}/>
      ) : fileType === 'xlsx' ? (
        <XlsxViewer filePath={file} fileType={fileType} darkTheme={darkTheme} />
      ) : ['json', 'bpmn'].includes(fileType) ? (
        <CodeDocument file={file} fileType={fileType} />
      ) : (
        <Suspense fallback={<Preloader flex={true} />}>
          {error ? (
            <div className="unsupported-message">
              <UnsupportedComponent />
            </div>
          ) : (
            <FileViewer
              darkTheme={darkTheme}
              filePath={file}
              fileType={fileType}
              unsupportedComponent={UnsupportedComponent}
            />
          )}
          {checkImageRenderError()}
        </Suspense>
      )}
      <span />
    </div>
  );
};

export default FilePreview;
